import { AgGridReact } from "ag-grid-react";
import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { dateFormatDMY, onGridReady } from "../../utils/utilities";
import axios from "axios";
import { configHeader, isTokenExpired } from "../../utils/tokenHelper";
import { apiEndpoint } from "../../utils/apiEndpoint";
import { orderBy } from "lodash";
import CreateNewBill from "./CreateNewBill";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Bill.css";
import ViewAmount from "./ViewAmount";

function Bills() {
  const navigate = useNavigate();
  const [bills, setBills] = useState();
  const [openCreateModal, setOpenCreateModal] = useState(false);

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
    document.title = "List of Bills";
  }, []);

  useEffect(() => {
    reloadBills();
  }, []);

  const reloadBills = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.invoiceUrl}/All-Receipts`,
        configHeader()
      )
      .then((result) => {
        setBills(result?.data ?? []);
      });
  };

  const handleSaveData = (data) => {
    if (data?.phaseId < 1) {
      toast.error(
        "Your phase is empty, please select a phase for receipt."
      );
      return;
    }

    axios
      .post(
        `${apiEndpoint.hosting}/${apiEndpoint.invoiceUrl}/Receipt`, data,
        configHeader()
      )
      .then(() => {
        reloadBills();
        setOpenCreateModal(false);
        toast.success("Your Receipt has been created.");
      });
  }

  const colDefs = [
    {
      headerName: "Row",
      valueGetter: "node.rowIndex + 1",
      width: 65,
    },
    {
      field: "receiptNo",
      headerName: "Receipt #",
      cellStyle: {
        textAlign: "left",
      },
      filter: true
    },
    {
      field: "payer",
      headerName: "Payer",
      cellStyle: {
        textAlign: "left",
      },
      width: 125,
      filter: true
    },
    {
      field: "companyName",
      headerName: "Company Name",
      cellStyle: {
        textAlign: "left",
      },
      width: 175,
      filter: true
    },
    {
      field: "projectName",
      headerName: "Project Name",
      cellStyle: {
        textAlign: "left",
      },
      width: 135,
      filter: true
    },
    {
      field: "phaseName",
      headerName: "Phase Name",
      cellStyle: {
        textAlign: "left",
      },
      width: 135,
      filter: true
    },
    {
      field: "receiptDate",
      headerName: "Date",
      cellStyle: {
        textAlign: "left",
      },
      cellRenderer: (data) => {
        return dateFormatDMY(data?.value);
      },
      width: 125,
    },
    {
      field: "receiver",
      headerName: "Receiver",
      cellStyle: {
        textAlign: "left",
      },
      width: 135,
      filter: true
    },
    {
      field: "totalAmout",
      headerName: "Amount", 
      filter: "agNumberColumnFilter",
      cellStyle: {
        textAlign: "right",
      },
      width: 105,
      cellRenderer: (row) => <ViewAmount totalAmout={row.data?.totalAmout} />
    },
    {
      field: "description",
      headerName: "Description",
      cellStyle: {
        textAlign: "left",
      },
      width: 175,
    },
    {
      field: "including",
      headerName: "Including",
      cellStyle: {
        textAlign: "left",
      },
      width: 125,
    },
    {
      field: "documents",
      headerName: "Documents",
      cellStyle: {
        textAlign: "left",
      },
      width: 125,
    },
    {
      field: "contactName",
      headerName: "Contact Name",
      cellStyle: {
        textAlign: "left",
      },
      width: 125,
    }
  ];

  return <>
    <MDBCard>
      <MDBCardBody>
        <MDBRow>
          <MDBCol sm="3">
            <MDBBtn onClick={() => setOpenCreateModal(true)}><MDBIcon fas icon="plus" /> New a Bill</MDBBtn>
          </MDBCol>
          <MDBCol sm="9" className="displayEnd">
            <lable className="displayEnd">One thousand in Vietnamese (1000 VND)</lable>
          </MDBCol>
        </MDBRow>
        {openCreateModal && <CreateNewBill
          onCloseModal={() => setOpenCreateModal(false)}
          onSave={(data) => handleSaveData(data)}
        />}
        <div className="ag-theme-quartz" style={{ height: "65vh", marginTop: 25 }}>
          <AgGridReact
            rowData={orderBy(bills, "name")}
            columnDefs={colDefs}
            fullWidthCellRenderer
            onGridReady={onGridReady}
          />
        </div>
      </MDBCardBody>
    </MDBCard>
    <ToastContainer />
  </>;
}

export default Bills;
