import {
  MDBAccordion,
  MDBAccordionItem,
  MDBBtn,
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
  MDBTextArea,
} from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import CtrPlanCompleted from "../pages/Projects/CtrPlanCompleted";
import CurrentLogTimeStatus from "../pages/Projects/CurrentLogTimeStatus";
import { userRoles } from "../utils/utilities";
function UpdatePhase({
  isModalOpen,
  userRole,
  phaseInfo,
  onSave,
  setIsModalOpen,
}) {

  const [phaseName, setPhaseName] = useState(phaseInfo?.name ?? "");
  const [priorityNumber, setPriorityNumber] = useState(phaseInfo?.priorityNumber);
  const [description, setDescription] = useState(phaseInfo?.description ?? "");
  const [startDate, setStartDate] = useState(phaseInfo?.startDate ?? new Date());
  const [endDate, setEndDate] = useState(phaseInfo?.endDate ?? new Date());
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setPhaseName(phaseInfo?.name ?? "");
    setPriorityNumber(phaseInfo?.priorityNumber);
    setDescription(phaseInfo?.description ?? "");
    setStartDate(phaseInfo?.startDate ?? new Date());
    setEndDate(phaseInfo?.endDate ?? new Date());
  }, [phaseInfo]);

  const renderHeaderPage = () => {
    if (phaseInfo?.estimateHours > 0)
      return (
        <MDBModalTitle>
          {phaseInfo?.name} ({phaseInfo?.estimateHours})
        </MDBModalTitle>
      );

    return <MDBModalTitle>{phaseInfo?.name}</MDBModalTitle>;
  };

  const renderBody = () => {
    return (
      <>
        <MDBInput
          className="mb-3"
          type="text"
          label="Name"
          value={phaseName}
          onChange={(evt) => setPhaseName(evt.target.value)}
        />

        <MDBAccordion alwaysOpen initialActive={0} title={`${phaseName} : ${description ?? ""}`}>
          <MDBAccordionItem collapseId={1} headerTitle="Phase Information">
            <CtrPlanCompleted
              startDate={startDate}
              endDate={endDate}
              onSaveStartDate={(date) => setStartDate(date)}
              onSaveEndDate={(date) => setEndDate(date)}
              onSaveChecked={(isSelected) => setChecked(isSelected)}
            />
            <MDBRow>
              <MDBCol size="3" sm="3">
                <MDBInput
                  className="mb-3"
                  type="number"
                  label="Priority"
                  value={priorityNumber}
                  style={{ padding: 5 }}
                  onChange={(evt) => setPriorityNumber(evt.target.value)}
                />
              </MDBCol>
              <MDBCol size="3" sm="9">
                <MDBIcon fas icon={"user-alt"} style={{ marginRight: 10 }} />
                {phaseInfo?.assigned}
              </MDBCol>
            </MDBRow>
          </MDBAccordionItem>
          <MDBAccordionItem collapseId={2} headerTitle="Phase Description">
            <MDBTextArea
              className="mb-3"
              id="textAreaExample"
              rows="{4}"
              value={description ?? ""}
              onChange={(evt) => setDescription(evt.target.value)}
            />
          </MDBAccordionItem>
        </MDBAccordion>
        <MDBRow style={{ marginTop: 15 }}>
          <CurrentLogTimeStatus
            statusName={phaseInfo?.statusName}
            estimateHours={phaseInfo?.estimateHours}
            normalHours={phaseInfo?.normalHours ?? 0}
            overTime={phaseInfo?.overTimeHours ?? 0}
            hideDate
          />
        </MDBRow>

      </>
    );
  };

  const renderControlByRole = () => {
    if (userRole !== userRoles.Admin && !phaseInfo?.isPM) return <></>;
    return <MDBBtn onClick={() => saveData()}>Save Phase</MDBBtn>;
  };

  const saveData = () => {

    if (phaseName?.length < 1) return;

    let data = checked ? {
      name: phaseName,
      startDate: startDate,
      endDate: endDate,
      priorityNumber: priorityNumber,
      description: description,
    } : {
      name: phaseName,
      startDate: startDate,
      priorityNumber: priorityNumber,
      description: description,
    };
    
    onSave(phaseInfo?.id, data);
    setIsModalOpen(false);
  };

  return (
    <MDBModal
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      tabIndex="-1"
      className="projectDialog"
    >
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>
            {renderHeaderPage()}
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={() => setIsModalOpen(false)}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <div className="divScroll50Vh">
              {renderBody()}
            </div>
          </MDBModalBody>

          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={() => setIsModalOpen(false)}>
              Close
            </MDBBtn>
            {renderControlByRole()}
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}

export default UpdatePhase;
