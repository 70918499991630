import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiEndpoint } from "../../utils/apiEndpoint";
import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBInput,
  MDBRow,
} from "mdb-react-ui-kit";
import "./Login.css";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Loading from "../../components/Loading";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    setIsLoading(true);
    const { data } = await axios.post(
      `${apiEndpoint.hosting}/${apiEndpoint.loginUrl}`,
      {
        userName: userName,
        password: password,
      },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true"
        }
      }
    );

    if (data?.accessToken?.length > 0) {
      window.localStorage.setItem("accessToken", data?.accessToken);
      const myJson = JSON.stringify(data?.userInfo ?? {});
      window.localStorage.setItem("userInfo", myJson);
      navigate("/home");
      return;
    } else {
      toast.error("Invalid password or your account is inactive.");
    }

    setIsLoading(false);
  };

  const renderLoginForm = () => {
    return (
      <MDBContainer fluid className="mt-5">
        {isLoading && <Loading />}
        <div className="text-muted text-center mt-2 mb-3">
          Sign in with your credentials
        </div>
        <section>
          <MDBRow className="justify-content-center">
            <MDBCol lg="6" style={{ padding: 5, margin: 15 }}>
              <MDBInput
                className="mb-3"
                type="text"
                id="loginName"
                label="Username"                
                onChange={(evt) => setUserName(evt.target.value)}
              />

              <MDBInput
                className="mb-3"
                type="password"
                id="loginPassword"
                label="Password"
                onChange={(evt) => setPassword(evt.target.value)}
                autoComplete="new-password"
              />

              <MDBBtn
                type="submit"
                block
                className="mb-4"
                onClick={() => handleSubmit()}
              >
                Sign in
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </section>
      </MDBContainer>
    );
  };

  return (
    <div
      className="app-center-screen"
      style={{
        backgroundColor:
          "linear-gradient(255.36deg, #f1f2f6 44.7%, #e8f0fc 121.81%)",
      }}
    >
      <center>{renderLoginForm()}</center>
      <ToastContainer />
    </div>
  );
};

export default Login;
