import { useEffect, useState } from "react";
import { configHeader, isTokenExpired } from "../../utils/tokenHelper";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { apiEndpoint } from "../../utils/apiEndpoint";
import { orderBy, toNumber } from "lodash";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MDBAccordion, MDBAccordionItem, MDBBadge, MDBCol, MDBRow, MDBSwitch } from "mdb-react-ui-kit";
import { AgGridReact } from "ag-grid-react";
import { dateFormatDMY, newGuid, nextMonth, onGridReady } from "../../utils/utilities";
import DisplayTaskProcessing from "./DisplayTaskProcessing";
import ViewMyTaskByCharts from "./ViewMyTaskByCharts";
import moment from "moment";
import DisplayOverdue from "../../components/DisplayOverdue";

function MyTasks() {
  const navigate = useNavigate();
  const [todayTasks, setTodayTasks] = useState([]);
  const [myTasks, setMyTasks] = useState([]);
  const [monthTasks, setMonthTasks] = useState([]);
  const [customTasks, setCustomTasks] = useState([]);
  const [timesheets, setTimesheets] = useState([]);
  const [userId, setUserId] = useState();
  const [isUsed, setIsUsed] = useState(false);

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
    document.title = "My tasks";
    var info = window.localStorage.getItem("userInfo") ?? "";
    let infoObj = JSON.parse(info);
    setUserId(toNumber(infoObj.id ?? 0));
  }, []);

  useEffect(() => {
    loadDataThisWeek();
    loadDataThisMonth();
    loadDataToday();
  }, []);

  const loadDataToday = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.dashboardUrl}/Today`,
        configHeader()
      )
      .then((result) => {
        let data = result?.data ?? []
        setTodayTasks(data);
        let items = data.filter((it) => it.staffId === userId) ?? [];
        setTimesheets(items)
      });
  };

  const loadDataThisWeek = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.dashboardUrl}/This-Week`,
        configHeader()
      )
      .then((result) => {
        setMyTasks(result?.data ?? []);
      });
  };

  const loadDataThisMonth = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.dashboardUrl}/This-Month`,
        configHeader()
      )
      .then((result) => {
        setMonthTasks(result?.data ?? []);
      });
  };

  const renderByStatus = (items) => {
    let statusNames = (items ?? [])
      .map(item => item.statusName)
      .filter((value, index, self) =>
        self.indexOf(value) === index);

    return <>
      <MDBRow>
        {(statusNames ?? []).map((st) => {
          let stName = st?.length > 0 ? st : "N/A"
          return <MDBCol className=" truncate max-w-[75px]" title={st}>{stName}</MDBCol>
        })}
      </MDBRow>
      <MDBRow>
        {(statusNames ?? []).map((st) => {
          let data = (items ?? []).filter((it) => it.statusName === st);
          return <MDBCol title={`${st} (${data?.length} Tasks)`}><h4>{data?.length}</h4> </MDBCol>
        })}
      </MDBRow>
    </>
  }

  const calculateTaksIds = (items) => {
    let uniqueTaskIds = (items ?? []).filter((it) => (it.taskId ?? 0) > 0)
      .map(item => item.taskId)
      .filter((value, index, self) =>
        self.indexOf(value) === index);

    return uniqueTaskIds?.length ?? ""
  }

  const renderControls = (items) => {
    let myProject = (items ?? []).filter((it) => it.isPM);
    let myProjectTasks = (items ?? []).filter((it) => !it.isPM && it.staffId === userId);
    let otherTasks = (items ?? []).filter((it) => !it.isPM && it.staffId !== userId);
    let lblTitle = isUsed ? "Has task only" : "Show all";

    let data = (items ?? [])
      .filter((it) => !isUsed || it.taskId > 0)
      .map((item) => {
        let st = item?.statusName !== "Done";
        var time1 = moment(item?.endDate ?? nextMonth()).format('YYYY-MM-DD');
        var time2 = moment(new Date()).format('YYYY-MM-DD');
        let overdue = time1 < time2;
        item.overdue = st && overdue;
        return item;
      });

    let titleDetails = "Details";
    let overdues = (data ?? []).filter((item) => item.overdue) ?? [];
    if (overdues?.length > 0) {
      titleDetails = `Details has ${overdues?.length} tasks Overdue`;
    }

    let minDate = new Date();
    let maxDate = new Date();
    maxDate.setDate(minDate.getDate() + 7);

    let rangeDates = (data ?? [])
      .filter((it) => it?.taskName?.length > 0)
      .map((it) => {
        if (it?.startDate < minDate) minDate = it?.startDate;
        if ((it?.endDate ?? nextMonth()) > maxDate) maxDate = it?.endDate ?? nextMonth();
        return {
          fromDate: dateFormatDMY(it?.startDate),
          name: `[${it?.phaseName}] ${it?.taskName}`,
          toDate: dateFormatDMY(it?.endDate ?? nextMonth())
        }
      }) ?? [];

    rangeDates.push({
      fromDate: dateFormatDMY(minDate),
      name: ``,
      toDate: dateFormatDMY(maxDate)
    })

    let rangeStaffs = (data ?? [])
      .filter((it) => it?.staffName?.length > 0)
      .map((it) => {
        return {
          fromDate: dateFormatDMY(it?.startDate),
          name: `${it?.staffName}`,
          toDate: dateFormatDMY(it?.endDate ?? nextMonth())
        }
      });

    rangeStaffs.push({
      fromDate: dateFormatDMY(minDate),
      name: ``,
      toDate: dateFormatDMY(maxDate)
    });

    let rangeStaffByTasks = (data ?? [])
      .filter((it) => it?.staffName?.length > 0)
      .map((it) => {
        return {
          fromDate: dateFormatDMY(it?.startDate),
          name: `[${it?.staffName}] ${it?.taskName}`,
          toDate: dateFormatDMY(it?.endDate ?? nextMonth())
        }
      });

    rangeStaffByTasks.push({
      fromDate: dateFormatDMY(minDate),
      name: ``,
      toDate: dateFormatDMY(maxDate)
    })

    return <>
      <MDBAccordion alwaysOpen initialActive={1} key={`SM${newGuid()}`}>
        <MDBAccordionItem collapseId={1} headerTitle="Summary">
          <MDBRow>
            <MDBCol sm="4">
              <h1 className='mb-3'>My projects</h1>
            </MDBCol>
            <MDBCol sm="4">
              <h1 className='mb-3'>My tasks</h1>
            </MDBCol>
            <MDBCol sm="4">
              <h1 className='mb-3'>Others</h1>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol sm="4">
              <h1 className='mb-3'>{calculateTaksIds(myProject)}</h1>
            </MDBCol>
            <MDBCol sm="4">
              <h1 className='mb-3'>{calculateTaksIds(myProjectTasks)}</h1>
            </MDBCol>
            <MDBCol sm="4">
              <h1 className='mb-3'>{calculateTaksIds(otherTasks)}</h1>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol sm="4">
              {renderByStatus(myProject)}
            </MDBCol>
            <MDBCol sm="4">
              {renderByStatus(myProjectTasks)}
            </MDBCol>
            <MDBCol sm="4">
              {renderByStatus(otherTasks)}
            </MDBCol>
          </MDBRow>
        </MDBAccordionItem>
        <MDBAccordionItem collapseId={2} headerTitle={titleDetails}>
          <MDBRow style={{ marginBottom: 10 }}>
            <MDBCol sm="3">
              <MDBSwitch
                id={`SW${newGuid()}`}
                label={lblTitle}
                checked={isUsed}
                onChange={(e) => setIsUsed(e.target.checked)}
              />
            </MDBCol>
            <MDBCol sm="9" style={{ textAlign: "right" }}>
              <MDBBadge color='success' style={{ marginRight: 20 }}>Normal working</MDBBadge>
              <MDBBadge color='warning' style={{ marginRight: 20 }}>Overtime</MDBBadge>
              <MDBBadge color='danger'>Underestimate</MDBBadge>
            </MDBCol>
          </MDBRow>
          <div className="ag-theme-quartz" style={{ height: 350, marginTop: 15 }}>
            <AgGridReact
              rowData={orderBy(data ?? [], "taskId")}
              columnDefs={colDefs}
              rowSelection={"multiple"}
              onGridReady={onGridReady}
            />
          </div>
        </MDBAccordionItem>
        <MDBAccordionItem collapseId={3} headerTitle="By Tasks">
          <ViewMyTaskByCharts items={items} userId={userId} isUsed={isUsed} />
        </MDBAccordionItem>
      </MDBAccordion>
    </>
  }

  const colDefs = [
    {
      headerName: "Row",
      valueGetter: "node.rowIndex + 1",
      width: 65,
    },
    {
      field: "overdue",
      headerName: "Overdue",
      width: 95,
      cellStyle: {
        textAlign: "left",
      },
      cellRenderer: (row) => {
        return <DisplayOverdue
          statusName={row?.data?.statusName ?? ""}
          endDate={row?.data?.endDate}
        />
      }
    },
    {
      field: "statusName",
      headerName: "Status Name",
      filter: true,
      cellStyle: {
        textAlign: "left",
      }
    },
    {
      field: "staffName",
      headerName: "Staff Name",
      cellStyle: {
        textAlign: "left",
      },
      width: 205,
      pinned: 'left',
      lockPinned: true,
      filter: true
    },
    {
      field: "taskName",
      headerName: "Task Name",
      width: 195,
      filter: true,
      pinned: 'left',
      lockPinned: true,
      cellStyle: {
        textAlign: "left",
      }
    },
    {
      field: "projectName",
      headerName: "Project",
      filter: true,
      cellStyle: {
        textAlign: "left",
      }
    },
    {
      field: "phaseName",
      headerName: "Phase",
      filter: true,
      cellStyle: {
        textAlign: "left",
      }
    },
    {
      field: "taskId",
      headerName: "Processing",
      width: 315,
      pinned: 'right',
      lockPinned: true,
      cellRenderer: (row) => {
        return <DisplayTaskProcessing
          statusName={row?.data?.statusName}
          planHours={row?.data?.planHours}
          workingHours={row?.data?.workingHours}
          otHours={row?.data?.otHours}
        />;
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "startDate",
      cellRenderer: (data) => {
        return dateFormatDMY(data?.value);
      },
    },
    {
      field: "endDate",
      cellRenderer: (data) => {
        return dateFormatDMY(data?.value);
      },
    },
  ]

  const renderTodayTimesheets = () => {
    if ((timesheets ?? []).length < 1) return <></>
    return <MDBAccordionItem collapseId={4} headerTitle="Today Timesheet">
      <div className="ag-theme-quartz" style={{ height: 350, marginTop: 15 }}>
        <AgGridReact
          rowData={orderBy(timesheets ?? [], "taskId")}
          columnDefs={colDefs}
          onGridReady={onGridReady}
        />
      </div>
    </MDBAccordionItem>
  }

  const renderTotalProject = () => {
    return <MDBAccordion alwaysOpen initialActive={1} key={`MT${newGuid()}`}>
      <MDBAccordionItem collapseId={1} headerTitle="Today">
        {renderControls(todayTasks)}
      </MDBAccordionItem>
      {renderTodayTimesheets()}
      <MDBAccordionItem collapseId={2} headerTitle="This Week">
        {renderControls(myTasks)}
      </MDBAccordionItem>
      <MDBAccordionItem collapseId={3} headerTitle="This Month">
        {renderControls(monthTasks)}
      </MDBAccordionItem>
      {/* <MDBAccordionItem collapseId={4} headerTitle="Range Date (From date => to date)">        
        {renderControls(customTasks)}
      </MDBAccordionItem> */}
    </MDBAccordion>
  }

  return (
    <>
      {renderTotalProject()}
      <ToastContainer />
    </>
  );
}

export default MyTasks;
