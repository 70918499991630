import { AgGridReact } from "ag-grid-react";
import { MDBAccordion, MDBAccordionItem, MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBRow } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { dateFormatDMY, onGridReady } from "../../utils/utilities";
import axios from "axios";
import { configHeader, isTokenExpired } from "../../utils/tokenHelper";
import { apiEndpoint } from "../../utils/apiEndpoint";
import { max, orderBy, toNumber, uniq } from "lodash";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ViewAmount from "./ViewAmount";
import moment from "moment";

function Receivables() {
  const navigate = useNavigate();
  const [receivables, setReceivables] = useState();
  const [projectBudgets, setProjectBudgets] = useState();

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
    reloadReceivables();
    document.title = "List of Receivables";
  }, []);

  const reloadReceivables = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.invoiceUrl}/Deferred-revenue`,
        configHeader()
      )
      .then((result) => {
        let items = (result?.data ?? [])
          .filter((item) => item?.projectName)
          .map((it) => {
            let totalAmouts = toNumber(it?.totalAmout);
            let totalAdvance = toNumber(it?.advancePayment);
            it.remaining = toNumber(totalAdvance > totalAmouts ? 0 : totalAmouts - totalAdvance);
            return it;
          });

        setReceivables(items ?? []);
        renderProjects(items ?? [])
      });
  };

  const renderProjects = (results) => {
    let projects = (results ?? []).map((it) => it.projectName);
    let items = uniq(projects);
    let now = moment();
    let planReceivingDate = now;
    let budgets = (items ?? []).map((item) => {
      let totalAmouts = 0;
      let totalAdvance = 0;
      let companyName = "";

      (results ?? []).filter((it) => it.projectName === item)
        .map((it) => {
          let curDate = moment(it.planReceivingDate)
          if (curDate > now) {
            if (curDate < planReceivingDate || planReceivingDate === now) planReceivingDate = curDate
          }

          companyName = it.companyName;
          totalAmouts += toNumber(it.totalAmout);
          totalAdvance += toNumber(it.advancePayment);
        });

      return {
        projectName: item,
        totalAmout: totalAmouts,
        advancePayment: totalAdvance,
        remaining: totalAdvance > totalAmouts ? 0 : totalAmouts - totalAdvance,
        planReceivingDate: planReceivingDate,
        companyName: companyName
      }
    });
    setProjectBudgets(budgets)
  }

  const colDefProjects = [
    {
      headerName: "Row",
      valueGetter: "node.rowIndex + 1",
      width: 65,
    },
    {
      field: "projectName",
      headerName: "Project Name",
      pinned: 'left',
      lockPinned: true,
      cellStyle: {
        textAlign: "left",
      },
      width: 215,
      filter: true
    },
    {
      field: "planReceivingDate",
      headerName: "Nearly Receiving Date",
      cellStyle: {
        textAlign: "left",
      },
      cellRenderer: (row) => {
        return dateFormatDMY(row.data?.planReceivingDate);
      },
      width: 175,
    },
    {
      field: "advancePayment",
      headerName: "Advance",
      cellStyle: {
        textAlign: "right",
      },
      width: 135,
      cellRenderer: (row) => <ViewAmount totalAmout={row.data?.advancePayment} />
    },
    {
      field: "companyName",
      headerName: "Client Name",
      pinned: 'right',
      lockPinned: true,
      cellStyle: {
        textAlign: "left",
      },
      width: 215,
      filter: true
    },
    {
      field: "totalAmout",
      headerName: "Amount",
      pinned: 'right',
      lockPinned: true,
      cellStyle: {
        textAlign: "right",
      },
      width: 135,
      cellRenderer: (row) => <ViewAmount totalAmout={row.data?.totalAmout} />
    },
    {
      field: "remaining",
      headerName: "Remaining",
      pinned: 'right',
      lockPinned: true,
      cellStyle: {
        textAlign: "right",
      },
      width: 135,
      cellRenderer: (row) => <ViewAmount totalAmout={row.data?.remaining} />
    }
  ];

  const colDefs = [
    {
      headerName: "Row",
      valueGetter: "node.rowIndex + 1",
      width: 65,
    },
    {
      field: "companyName",
      headerName: "Client Name",
      pinned: 'left',
      lockPinned: true,
      cellStyle: {
        textAlign: "left",
      },
      width: 215,
      filter: true
    },
    {
      field: "projectName",
      headerName: "Project Name",
      pinned: 'left',
      lockPinned: true,
      cellStyle: {
        textAlign: "left",
      },
      width: 215,
      filter: true
    },
    {
      field: "phaseName",
      headerName: "Phase Name",
      cellStyle: {
        textAlign: "left",
      },
      width: 350,
      filter: true
    },
    {
      field: "planReceivingDate",
      headerName: "Receiving Date",
      cellStyle: {
        textAlign: "left",
      },
      cellRenderer: (row) => {
        return dateFormatDMY(row.data?.planReceivingDate);
      },
      width: 175,
    },
    {
      field: "advancePayment",
      headerName: "Advance",
      cellStyle: {
        textAlign: "right",
      },
      width: 135,
      cellRenderer: (row) => <ViewAmount totalAmout={row.data?.advancePayment} />
    },
    {
      field: "totalAmout",
      headerName: "Amount",
      pinned: 'right',
      lockPinned: true,
      cellStyle: {
        textAlign: "right",
      },
      width: 135,
      cellRenderer: (row) => <ViewAmount totalAmout={row.data?.totalAmout} />
    },
    {
      field: "remaining",
      headerName: "Remaining",
      pinned: 'right',
      lockPinned: true,
      cellStyle: {
        textAlign: "right",
      },
      width: 135,
      cellRenderer: (row) => <ViewAmount totalAmout={row.data?.remaining} />
    }
  ];

  return <>
    <MDBCard>
      <MDBCardHeader>
        <MDBRow>
          <MDBCol size="3" sm="4" className="displayTitle">Receivables</MDBCol>
          <MDBCol size="3" sm="8" className="displayEnd">
            <lable >One thousand in Vietnamese (1000 VND)</lable>
          </MDBCol>
        </MDBRow>
      </MDBCardHeader>
      <MDBCardBody>
        <MDBAccordion initialActive={1}>
          <MDBAccordionItem collapseId={1} headerTitle={"View by projects"}>
            <div className="ag-theme-quartz" style={{ height: "65vh" }}>
              <AgGridReact
                rowData={orderBy(projectBudgets, "name")}
                columnDefs={colDefProjects}
                fullWidthCellRenderer
                onGridReady={onGridReady}
              />
            </div>
          </MDBAccordionItem>
          <MDBAccordionItem collapseId={2} headerTitle={"View by phases"}>
            <div className="ag-theme-quartz" style={{ height: "65vh" }}>
              <AgGridReact
                rowData={orderBy(receivables, "name")}
                columnDefs={colDefs}
                fullWidthCellRenderer
                onGridReady={onGridReady}
              />
            </div>
          </MDBAccordionItem>
        </MDBAccordion>
      </MDBCardBody>
    </MDBCard>
    <ToastContainer />
  </>;
}

export default Receivables;
