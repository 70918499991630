import { MDBIcon } from "mdb-react-ui-kit";

function DisplayPM({ userName }) {
  return (
    <>
      <MDBIcon fas icon="user-tie" style={{ marginRight: 15 }} />
      {userName}
    </>
  );
}

export default DisplayPM;
