import moment from "moment";
import React from "react";
import Chart from "react-apexcharts";
import { getRandomColor } from "../../utils/utilities";

function PhaseByDates({ rangeDates }) {
  const dataOptions = {
    chart: {
      height: 350,
      type: "rangeBar",
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    xaxis: {
      type: "datetime",
    },
  };

  const dataSeries = () => {
    let data = (rangeDates ?? []).map((item) => {
      return {
        x: item.name,
        y: [
          new Date(moment(item.fromDate)).getTime(),
          new Date(moment(item.toDate)).getTime(),
        ],
        fillColor: getRandomColor(),
      };
    });
    return [{ data }];
  };

  return (rangeDates ?? []).length < 1 ? (
    <></>
  ) : (
    <>
      <Chart
        options={dataOptions}
        series={dataSeries()}
        type="rangeBar"
        height={350}
      />
    </>
  );
}

export default PhaseByDates;
