import React, { useEffect, useState } from "react";

import {
  MDBContainer,
  MDBIcon,
  MDBNavbar,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarNav,
} from "mdb-react-ui-kit";
import Avatar from "./Avatar";
import { useNavigate } from "react-router-dom";
import AdminMenu from "./AdminMenu";
import LeaderMenu from "./LeaderMenu";
import AccountantMenu from "./AccountantMenu";
import { minSizeWindowPC, userRoles } from "../../utils/utilities";

function Menu({ hasAvatar, allNotify }) {
  const navigate = useNavigate();
  const [userMenu, setUserMenu] = useState();
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  useEffect(() => {
    var info = window.localStorage.getItem("userInfo") ?? "";
    let infoObj = JSON.parse(info);
    let menus = defineUserMenu(infoObj.role);
    setUserMenu(menus);
  }, []);

  const defineUserMenu = (role) => {
    if (role === userRoles.Admin) {
      return <AdminMenu pcIcon={windowSize[0] >= minSizeWindowPC} />
    }

    if (role === "Accountant") {
      return <AccountantMenu />
    }

    return <LeaderMenu />
  };

  const renderAlert = () => {
    if ((allNotify ?? []).length < 1) return <></>

    return <>
      <MDBIcon fas icon="bell" color="warning" onClick={() => navigate("/users/notifications")} />
      {`(${allNotify?.length})`}
    </>
  }



  return (
    <>
      <MDBNavbar expand="lg" light bgColor="light" style={{ height: 46 }}>
        <MDBContainer fluid>
          <MDBNavbarNav right className="mb-2 mb-lg-0">
            <MDBNavbarItem active>
              <MDBNavbarLink
                aria-current="page"
                onClick={() => navigate("/home")}
              >
                <MDBIcon fas icon="house-damage" style={{ marginRight: 15 }} />
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink onClick={() => navigate("/dashboard")}>
                <MDBIcon fas icon="chart-line" style={{ marginRight: 15 }} />
                Dashboard
              </MDBNavbarLink>
            </MDBNavbarItem>
            {userMenu}
          </MDBNavbarNav>
        </MDBContainer>
        {/* {renderAlert()} */}
        {hasAvatar && <Avatar />}
      </MDBNavbar>
    </>
  );
}

export default Menu;
