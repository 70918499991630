import axios from "axios";
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
} from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { apiEndpoint } from "../../utils/apiEndpoint";
import { configHeader } from "../../utils/tokenHelper";
import Multiselect from "multiselect-react-dropdown";
import { first } from "lodash";

function SetLeaderTeam({ setIsOpenModal, teamId, saveData }) {
  const [leaderId, setLeaderId] = useState();
  const [staffs, setStaffs] = useState();

  useEffect(() => {
    teamId &&
      axios
        .get(
          `${apiEndpoint.hosting}/Teams/${teamId}/Staffs`,
          configHeader()
        )
        .then((result) => {
          let items = getOptions(result?.data);
          setStaffs(items ?? []);
        });
  }, [teamId]);

  const getOptions = (staffs) => {
    return (staffs ?? []).map((p) => {
      return { name: p.displayName, id: p.id };
    });
  };

  const onSelectedValues = (items) => {
    let ids = (items ?? []).map((it) => it.id);
    let leader = first(ids) ?? {};
    setLeaderId(leader);
  };

  const updateLeaderName = () => {
    saveData(teamId, leaderId)
  };

  return (
    <MDBModal open={true} onClose={() => setIsOpenModal(false)} tabIndex="-1">
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>Update Leader</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={() => setIsOpenModal(false)}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <Multiselect
              options={staffs}
              onSelect={(items) => onSelectedValues(items)}
              onRemove={(items) => onSelectedValues(items)}
              displayValue="name"
              singleSelect
            />
          </MDBModalBody>

          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={() => setIsOpenModal(false)}>
              Close
            </MDBBtn>
            <MDBBtn onClick={() => updateLeaderName()}>Save </MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}

export default SetLeaderTeam;
