
import { useEffect, useState } from "react";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiEndpoint } from "../../utils/apiEndpoint";

function NotificationToUser({ myUserId, myTeamId }) {

  const [allNotify, setAllNotify] = useState([]);

  const showMsg = (msg) => {
    let opt = { autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true };
    if (msg?.length > 0) {
      let hasNotify = (allNotify ?? [])
        .filter((it) => msg === it)
        ?.length > 0

      if (!hasNotify) {
        var items = (allNotify ?? []).slice(0, 10);
        setAllNotify([...msg, items ?? []]);
        toast.success(msg, opt);
      }
    }
  }

  useEffect(() => {
    const accessToken = window.localStorage.getItem("accessToken") ?? "";
    const connection = new HubConnectionBuilder()
      .withUrl(`${apiEndpoint.hosting}/notifications`, {
        accessTokenFactory: () => accessToken,
        withCredentials: false,
      })
      .withAutomaticReconnect([2000, 5000, 10000, 60000])
      .build()

    // start connection
    connection.start().then(() => {
      console.log("Connection started")
    });

    connection.on("SendNotificationToUser",
      (userId, message, data) => {
        let msgNotify = data?.msgNotify ?? "";
        let staffIds = data?.staffIds ?? []

        let hasUser = +userId === +myUserId;
        if (!hasUser) {
          hasUser = (staffIds ?? []).filter((it) => +it === +userId)?.length > 0;
        }

        if (hasUser) {
          showMsg(msgNotify);
        }
      }
    );

    connection.on("SendNotificationToUsers",
      (userIds, message, data) => {
        let msgNotify = data?.msgNotify ?? "";
        let staffIds = data?.staffIds ?? []

        let hasUser = (userIds ?? []).filter((it) => +it === +myUserId)?.length > 0;
        if (!hasUser) {
          hasUser = (staffIds ?? []).filter((it) => +it === +myUserId)?.length > 0;
        }

        if (hasUser) {
          showMsg(msgNotify);
        }
      }
    );

    connection.on("SendNotificationToTeam",
      (teamId, message, data) => {
        if (+teamId === +myTeamId) {
          let msgNotify = data?.msgNotify ?? "";
          if (msgNotify.length > 0) {
            showMsg(msgNotify)
          }
        }
      }
    );
  }, [])


  return <ToastContainer />
}

export default NotificationToUser;
