import { MDBAccordion, MDBAccordionItem, MDBCol, MDBRow } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import BarChart from "./BarChart";

function TaskByUsers({ data }) {
  const [taskInfo, setTaskInfo] = useState([]);
  const [weekHours, setWeekHours] = useState([]);
  const [weekOT, setWeekOT] = useState([]);
  const [staffs, setStaffs] = useState([]);

  const [monthPlanning, setMonthPlanning] = useState([]);
  const [monthStaffs, setMonthStaffs] = useState([]);
  const [monthHours, setMonthHours] = useState([]);
  const [monthOT, setMonthOT] = useState([]);

  useEffect(() => {
    let taskByUsers = [];
    let staffNames = [];
    let staffWorkingHours = [];
    let staffOTHours = [];

    let items = (data ?? []).filter((it) => (it.currentWeek ?? []).length > 0);
    (items ?? []).map((it) => {
      let planHours = 0;
      let workingHours = 0;
      let otHours = 0;

      (it.currentWeek ?? []).map((w) => {
        planHours += w.planHours;
        workingHours += w.workingHours;
        otHours += w.otHours;
        return planHours;
      });

      if (planHours > 0) {
        taskByUsers.push(planHours);
        staffNames.push(it.userInfo?.staffName);
        staffWorkingHours.push(workingHours);
        staffOTHours.push(otHours);
      }
      return planHours;
    });

    setStaffs(staffNames);
    setTaskInfo(taskByUsers);
    setWeekHours(staffWorkingHours);
    setWeekOT(staffOTHours);

    let monthPlans = [];
    let monthWorkings = [];
    let monthOvertimes = [];
    let monthNames = [];

    let itemPlannings = (data ?? []).filter(
      (it) => (it.currentMonth ?? []).length > 0
    );
    (itemPlannings ?? []).map((it) => {
      let planHours = 0;
      let workingHours = 0;
      let otHours = 0;

      (it.currentMonth ?? []).map((w) => {
        planHours += w.planHours;
        workingHours += w.workingHours;
        otHours += w.otHours;

        return planHours;
      });

      if (planHours > 0) {
        monthPlans.push(planHours);
        monthWorkings.push(workingHours);
        monthOvertimes.push(otHours);
        monthNames.push(it.userInfo?.staffName);
      }

      return planHours;
    });

    setMonthStaffs(monthNames);
    setMonthPlanning(monthPlans);
    setMonthHours(monthWorkings);
    setMonthOT(monthOvertimes);
  }, [data]);

  return (
    <>
      <MDBAccordion initialActive={1}>
        <MDBAccordionItem collapseId={1} headerTitle={" Plannings"}>
          <MDBRow>
            <MDBCol size="3" sm="6">
              <BarChart
                titleChart={"This week"}
                headerChart={"Planning"}
                categories={staffs}
                data={taskInfo}
              />
            </MDBCol>
            <MDBCol size="3" sm="6">
              <BarChart
                titleChart={"This month"}
                headerChart={"Planning"}
                categories={monthStaffs}
                data={monthPlanning}
              />
            </MDBCol>
          </MDBRow>
        </MDBAccordionItem>
        <MDBAccordionItem collapseId={2} headerTitle={" Working times"}>
          <MDBRow>
            <MDBCol size="3" sm="6">
              <BarChart
                titleChart={"This week"}
                headerChart={"Working time"}
                categories={staffs}
                data={weekHours}
              />
            </MDBCol>
            <MDBCol size="3" sm="6">
              <BarChart
                titleChart={"This Month"}
                headerChart={"Working time"}
                categories={monthStaffs}
                data={monthHours}
              />
            </MDBCol>
          </MDBRow>
        </MDBAccordionItem>
        <MDBAccordionItem collapseId={3} headerTitle={" Overtimes"}>
          <MDBRow>
            <MDBCol size="3" sm="6">
              <BarChart
                titleChart={"This week"}
                headerChart={"Over time"}
                categories={staffs}
                data={weekOT}
              />
            </MDBCol>
            <MDBCol size="3" sm="6">
              <BarChart
                titleChart={"This Month"}
                headerChart={"Over time"}
                categories={monthStaffs}
                data={monthOT}
              />
            </MDBCol>
          </MDBRow>
        </MDBAccordionItem>
      </MDBAccordion>
    </>
  );
}

export default TaskByUsers;
