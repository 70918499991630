import {
  MDBAccordion,
  MDBAccordionItem,
  MDBBtn,
  MDBCheckbox,
  MDBCol,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
  MDBSwitch,
  MDBTextArea,
} from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { isTokenExpired } from "../utils/tokenHelper";
import { useNavigate } from "react-router-dom";
import AssignTaskToTeam from "./AssignTaskToTeam";
import { first } from "lodash";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CtrPlanCompleted from "../pages/Projects/CtrPlanCompleted";
import { newGuid, userRoles } from "../utils/utilities";

function UpdateTaskInfo({
  teams,
  taskDetailInfo,
  taskInfo,
  userRole,
  onCloseModal,
  onSave,
}) {

  const navigate = useNavigate();
  const [taskName, setTaskName] = useState(taskInfo?.name);
  const [startDate, setStartDate] = useState(taskInfo?.startDate);
  const [endDate, setEndDate] = useState(taskInfo?.endDate);
  const [priorityNumber, setPriorityNumber] = useState(taskInfo?.priorityNumber);
  const [description, setDescription] = useState(taskInfo?.description ?? "");
  const [estimateHours, setEstimateHours] = useState(taskInfo?.estimateHours);
  const [currentTeams, setCurrentTeams] = useState(taskDetailInfo?.teamIds ?? []);
  const [showInMenu, setShowInMenu] = useState(taskDetailInfo?.showInMenu);
  const [userTeamId, setUserTeamId] = useState();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
    var info = window.localStorage.getItem("userInfo") ?? "";
    let infoObj = JSON.parse(info);
    setUserTeamId(infoObj.teamId);
  }, []);

  useEffect(() => {
    setShowInMenu(taskInfo?.showInMenu);
    setTaskName(taskInfo?.name ?? "");
    setStartDate(taskInfo?.startDate);
    setEndDate(taskInfo?.endDate);
    setPriorityNumber(taskInfo?.priorityNumber);
    setDescription(taskInfo?.description ?? "");
    setEstimateHours(taskInfo?.estimateHours);
  }, [taskInfo]);

  useEffect(() => {
    setCurrentTeams(taskDetailInfo?.teamIds ?? []);
  }, [taskDetailInfo]);

  const handleShowInMenu = (checked) => {
    setShowInMenu(checked);
  };

  const saveData = () => {
    if (taskDetailInfo?.id < 1) {
      toast.error("Please select task again.");
    } else {
      let teamIds = currentTeams;

      if (userRole !== userRoles.Admin && !taskInfo?.isPM) {
        teamIds = (teams ?? [])
          .filter((item) => +userTeamId === +item.id)
          .map((x) => x.id);
      }

      let data = checked ? {
        id: taskInfo?.id,
        name: taskName,
        estimateHours: estimateHours,
        startDate: startDate,
        endDate: endDate,
        priorityNumber: priorityNumber,
        description: description,
        showInMenu: showInMenu ?? false,
        teamIds: teamIds,
      } : {
        id: taskInfo?.id,
        name: taskName,
        estimateHours: estimateHours,
        startDate: startDate,
        priorityNumber: priorityNumber,
        description: description,
        showInMenu: showInMenu ?? false,
        teamIds: teamIds,
      }

      onSave(data);
      onCloseModal();
    }
  };

  const handleUseTeam = (teamId, checked) => {
    let current = currentTeams ?? [];
    if (checked) {
      let hasVal = (currentTeams ?? []).filter((it) => it === teamId);
      if ((hasVal ?? []).length < 1) {
        current.push(teamId);
      }
    } else {
      current = (currentTeams ?? []).filter((it) => it !== teamId);
    }
    setCurrentTeams(current ?? []);
  };

  const renderTeamByUserRole = () => {
    if (userRole !== userRoles.Admin && !taskInfo?.isPM) {
      let current = (teams ?? []).filter((item) => +userTeamId === +item.id);
      let info = first(current);
      return (
        <MDBRow title={`My team ${info?.name}`}>
          <MDBCol size="6" sm="10" color="secondary">
            <MDBBtn color="secondary">My team {info?.name}</MDBBtn>
          </MDBCol>
        </MDBRow>
      );
    }

    return (
      <>
        {(teams ?? []).map((item) => {
          let isChecked =
            (taskDetailInfo?.teamIds ?? []).filter((it) => it === item.id)
              ?.length > 0;

          return (
            <AssignTaskToTeam
              info={item}
              canModify={true}
              isChecked={isChecked}
              handleUseTeam={(teamId, checked) =>
                handleUseTeam(teamId, checked)
              }
            />
          );
        })}
      </>
    );
  };

  const renderBody = () => {
    return (
      <>
        <MDBInput
          className="mb-3"
          type="text"
          label="Name"
          title="Name of task"
          value={taskName}
          onChange={(evt) => setTaskName(evt.target.value)}
        />
        <MDBAccordion alwaysOpen initialActive={1}>
          <MDBAccordionItem collapseId={1} headerTitle="Task information">
            <MDBRow style={{ textAlign: "left", marginTop: 20 }}>
              <MDBCol size="3" sm="3">
                <MDBInput
                  className="mb-3"
                  type="number"
                  label="Estimate"
                  title="Estimate Hours"
                  value={estimateHours}
                  style={{ padding: 5 }}
                  onChange={(evt) => setEstimateHours(evt.target.value)}
                />
              </MDBCol>
              <MDBCol size="3" sm="3">
                <MDBInput
                  className="mb-3"
                  type="number"
                  label="Priority"
                  value={priorityNumber}
                  style={{ padding: 5 }}
                  onChange={(evt) => setPriorityNumber(evt.target.value)}
                />
              </MDBCol>
              <MDBCol size="3" sm="6">
                <MDBCheckbox
                  id={`chb${newGuid()}`}
                  label='Show In Menu'
                  checked={showInMenu}
                  onChange={(e) => handleShowInMenu(e.target.checked)}
                />
              </MDBCol>
            </MDBRow>

            <CtrPlanCompleted
              startDate={startDate}
              endDate={endDate}
              onSaveStartDate={(date) => setStartDate(date)}
              onSaveEndDate={(date) => setEndDate(date)}
              onSaveChecked={(isSelected) => setChecked(isSelected)}
            />

          </MDBAccordionItem>
          <MDBAccordionItem collapseId={2} headerTitle="Team information">
            {renderTeamByUserRole()}
          </MDBAccordionItem>
        </MDBAccordion>


        <MDBTextArea
          className="mb-3"
          label="Description"
          id="textAreaDescription"
          rows="{4}"
          style={{ marginTop: 20 }}
          value={description ?? ""}
          onChange={(evt) => setDescription(evt.target.value)}
        />
      </>
    );
  };

  return (
    <>
      <MDBModal open={true} onClose={onCloseModal} tabIndex="-1" className="projectDialog">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Update Task {taskDetailInfo?.name}</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={onCloseModal}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <div className="divScroll50Vh">
                {renderBody()}
              </div>
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={onCloseModal}>
                Close
              </MDBBtn>
              <MDBBtn onClick={() => saveData()}>Save</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <ToastContainer />
    </>

  );
}

export default UpdateTaskInfo;
