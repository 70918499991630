import {
  MDBAccordion,
  MDBAccordionItem,
  MDBBadge,
  MDBBtn,
  MDBCol,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
} from "mdb-react-ui-kit";
import { useEffect } from "react";
import { isTokenExpired } from "../utils/tokenHelper";
import { useNavigate } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import { first, isNumber, orderBy } from "lodash";
import DisplayTaskProcessing from "../pages/Planning/DisplayTaskProcessing";
import "./modal.css";

function ViewSubTasks({
  taskName,
  taskDetailInfo,
  onCloseModal,
}) {
  const navigate = useNavigate();

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
  }, []);

  const colDefs = [
    {
      headerName: "#",
      valueGetter: "node.rowIndex + 1",
      width: 45,
    },
    {
      field: "name", filter: true,
      cellStyle: {
        textAlign: "left",
      },
      headerName: "Subtask"
    },
    {
      field: "estimateHours",
      width: 65,
      cellStyle: {
        textAlign: "right",
      },
      headerName: "Estimate"
    },
    {
      field: "taskId",
      headerName: "Processing",
      width: 215,
      cellRenderer: (row) => {
        return <DisplayTaskProcessing
          statusName={row?.data?.statusName}
          planHours={row?.data?.estimateHours}
          workingHours={row?.data?.normalHours}
          otHours={row?.data?.overTimeHours}
        />;
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "statusName",
      cellStyle: {
        textAlign: "left",
      }, filter: true, width: 95, headerName: "Status"
    },
    {
      field: "assigned",
      cellStyle: {
        textAlign: "left",
      },
      filter: true, headerName: "Staff"
    },
    {
      field: "description", cellStyle: {
        textAlign: "left",
      }, filter: true
    },
  ];

  const renderTaskByUsers = (subTasks) => {
    let unique = [...new Set(subTasks.map((it) => it?.assignedId))];
    let itemTasks = (unique).map((id) => {
      let staffs = (subTasks ?? []).filter((f) => f.assignedId === id);
      let staff = first(staffs);
      let estimateHours = 0;
      let normalHours = 0;
      let overTimeHours = 0;

      (staffs ?? []).map((it) => {
        estimateHours += it?.estimateHours ?? 0;
        normalHours += it?.normalHours ?? 0;
        overTimeHours += it?.overTimeHours ?? 0;
      })

      return <>
        <MDBRow>
          <MDBCol size="3" sm="6">{staff?.assigned}</MDBCol>
          <MDBCol size="3" sm="1">
            <MDBBadge pill light title={"Estimate Hours"}>
              {estimateHours ?? "N/A"}
            </MDBBadge></MDBCol>
          <MDBCol size="3" sm="5">
            <DisplayTaskProcessing
              statusName={staff?.statusName}
              planHours={estimateHours}
              workingHours={normalHours ?? 0}
              otHours={overTimeHours ?? 0}
              displayOverHours
            />
          </MDBCol>
        </MDBRow>
      </>
    });

    return itemTasks
  }

  const renderSubByUsers = (subTasks) => {
    if (subTasks?.length < 1) return <>Don't have any sub tasks</>;

    let items = (subTasks ?? []).filter(
      (it) =>
        (isNumber(it?.estimateHours ?? 0) +
          isNumber(it?.normalHours ?? 0) +
          isNumber(it?.overTimeHours ?? 0)) >
        0
    );

    return (
      <>
        <MDBAccordion initialActive={1}>
          <MDBAccordionItem collapseId={1} headerTitle={"View by staff"}>
            {renderTaskByUsers(subTasks)}
          </MDBAccordionItem>
          <MDBAccordionItem collapseId={2} headerTitle={"View by grid"}>
            <div className="ag-theme-quartz" style={{ height: 235 }}>
              <AgGridReact
                rowData={orderBy(items ?? [], "priorityNumber")}
                columnDefs={colDefs}
              />
            </div>
          </MDBAccordionItem>
        </MDBAccordion>

      </>
    );
  };

  const renderBody = () => {
    let subTasks = (taskDetailInfo?.subTasks ?? [])
    return (
      <>
        {renderSubByUsers(subTasks)}
      </>
    );
  };

  return (
    <MDBModal open={true} onClose={onCloseModal} tabIndex="-1" className="projectDialog">
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle className=" truncate max-w-[300px]">
              {taskName}
            </MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={onCloseModal}
            ></MDBBtn>
          </MDBModalHeader>

          <MDBModalBody>
            <div className="divScroll">{renderBody()}</div>
          </MDBModalBody>

          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={onCloseModal}>
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}

export default ViewSubTasks;
