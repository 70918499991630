import { MDBAccordion, MDBAccordionItem, MDBBtn, MDBCol, MDBIcon, MDBInput, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBRow } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { isTokenExpired } from "../../utils/tokenHelper";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "./Bill.css";
import { first } from "lodash";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toCommas } from "../../utils/utilities";
import AssignBudgetToTeam from "./AssignBudgetToTeam";

function CreateBudget({ budget, teams, onCloseModal, onSave }) {
  const navigate = useNavigate();
  const [currentTeams, setCurrentTeams] = useState([]);
  const [budgetAmount, setBudgetAmount] = useState()

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
    document.title = "Create a budget";
  }, []);

  useEffect(() => {
    let teamBudgets = (budget?.teamBudgets || []).map((item) => {
      let bt = {
        teamId: item?.teamId,
        val: item?.budgetPercent
      }

      return bt
    })
    setBudgetAmount(budget?.budgetAmount)
    setCurrentTeams(teamBudgets)
  }, budget);

  const renderGeneralInfomation = () => {
    return <>
      <MDBRow>
        <MDBCol size="12" sm="3">Project Name</MDBCol>
        <MDBCol size="12" sm="9">
          {budget?.projectName}
        </MDBCol>
      </MDBRow>
      <MDBRow style={{ marginTop: 15 }}>
        <MDBCol size="12" sm="3">Phase Name</MDBCol>
        <MDBCol size="12" sm="9">
          {budget?.phaseName}
        </MDBCol>
      </MDBRow>
    </>
  }

  const renderDivTotalPercent = () => {
    if ((currentTeams ?? []).length < 1) return <></>

    let total = 0;
    (currentTeams ?? []).map((it) => total += (+it.val))

    return (<div style={{ textAlign: "right", background: total === 100 ? "" : "gray" }}>
      <MDBIcon fas icon="calculator" style={{ marginRight: 15 }} /> {total} %
    </div>)
  }

  const renderBudgetInfomation = () => {
    return <>
      <MDBRow style={{ margin: 15 }}>
        <MDBCol size="3" sm="4">
          <MDBInput
            className="mb-3"
            type="number"
            id="budgetAmount"
            label="Budget Amount"
            value={budgetAmount}
            style={{ padding: 5, textAlign: "right" }}
            onChange={(evt) => setBudgetAmount(evt.target.value)}
          />
        </MDBCol>
        <MDBCol size="3" sm="4">
          {toCommas(budgetAmount)}
        </MDBCol>
        <MDBCol size="3" sm="3">
          {renderDivTotalPercent()}
        </MDBCol>
      </MDBRow>
      {(teams ?? []).map((item) => {
        let itemPercent = (budget?.teamBudgets ?? []).filter((it) => it?.teamId === item?.id);
        let teamBudget = first(itemPercent);
        return <AssignBudgetToTeam
          info={item}
          budgetPercent={teamBudget?.budgetPercent}
          isChecked={teamBudget?.budgetPercent > 0}
          handleUseTeam={(teamId, checked) =>
            handleUseTeam(teamId, checked)
          }
          handleInputPercent={(teamId, val) => handleInputPercent(teamId, val)}
        />
      })}
    </>
  }

  const handleInputPercent = (teamId, val) => {
    if (val >= 100) {
      toast.error(
        "Your Percent of team must be less than 100, please input again."
      );
      return;
    }

    let items = (currentTeams ?? []).map((it) => {
      if (it.teamId === teamId) {
        it.val = val
      }
      return it;
    });
    setCurrentTeams(items ?? []);
  }

  const handleUseTeam = (teamId, checked) => {
    let current = currentTeams ?? [];
    if (checked) {
      let hasVal = (currentTeams ?? []).filter((it) => it.teamId === teamId);
      if ((hasVal ?? []).length < 1) {
        current.push({ teamId: teamId, val: 0 });
      }
    } else {
      current = (currentTeams ?? []).filter((it) => it.teamId !== teamId);
    }

    setCurrentTeams(current ?? []);
  };

  const saveData = () => {
    let items = (currentTeams ?? []).map((item) => {
      return {
        "teamId": item.teamId,
        "budgetPercent": +item.val
      };
    })

    let data = {
      "phaseId": budget?.phaseId,
      "budgetAmount": budgetAmount,
      "teamBudgets": items
    }
    onSave(data);
  }

  const renderBody = () => {
    return <div className="divBudgetScroll">
      <MDBAccordion alwaysOpen initialActive={1}>
        <MDBAccordionItem collapseId={1} headerTitle={"General information"}>
          {renderGeneralInfomation()}
        </MDBAccordionItem>
        <MDBAccordionItem collapseId={2} headerTitle={"Budget information"}>
          {renderBudgetInfomation()}
        </MDBAccordionItem>
      </MDBAccordion>
    </div>
  }

  return <>
    <MDBModal
      open={true}
      onClose={() => onCloseModal()}
      tabIndex="-1"
      className="billDialog"
    >
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>{budget?.phaseName}</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={() => onCloseModal()}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>{renderBody()}</MDBModalBody>

          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={() => onCloseModal()}>
              Close
            </MDBBtn>
            <MDBBtn onClick={() => saveData()}> <MDBIcon fas icon="save" /> Save</MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
    <ToastContainer />
  </>;
}

export default CreateBudget;
