import { first } from "lodash";
import {
  MDBBtn,
  MDBCol,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
  MDBTextArea,
} from "mdb-react-ui-kit";
import Multiselect from "multiselect-react-dropdown";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function LogWork({ taskInfo, subTasks, onCloseModal, saveData }) {

  const [overTime, setOverTime] = useState();
  const [normalTime, setNormalTime] = useState();
  const [subTaskId, setSubTaskId] = useState();
  const [tsNote, setTsNote] = useState();
  const [hideSubTask, setHideSubTask] = useState();
  const [actionDate, setActionDate] = useState(new Date());

  useEffect(() => {
    if ((subTasks ?? []).length === 1) {
      let proSubtask = first(subTasks ?? []) ?? {};
      setSubTaskId(proSubtask?.id);
      setHideSubTask(true);
    }
  }, [subTasks]);

  const options = () => {
    return (subTasks ?? []).map((p) => {
      return { name: p.name, id: p.id };
    });
  };

  const handleSaveData = () => {
    let data = {
      overTime: overTime,
      subTaskId: subTaskId,
      normalTime: normalTime,
      actionDate: actionDate,
      tsNote: tsNote,
    };

    saveData(data);
    toast.success("Your time sheet was save successful.");
    onCloseModal();
  };

  const onSelectedValues = (items) => {
    let ids = (items ?? []).map((it) => it.id);
    let rs = (subTasks ?? []).filter((p) => ids.some((id) => id === p.id));
    let proSubtask = first(rs) ?? {};
    setSubTaskId(proSubtask?.id);
  };

  const renderActionControl = () => {
    
    let ctrSubs = hideSubTask ? "" : <MDBRow>
      <MDBCol size="3" sm="12">
        <Multiselect
          options={options()}
          onSelect={(items) => onSelectedValues(items)}
          onRemove={(items) => onSelectedValues(items)}
          displayValue="name"
          placeholder="Select subtask and input time"
          singleSelect
        />
      </MDBCol>
    </MDBRow>

    return (
      <>
        {ctrSubs}
        <MDBRow style={{ marginTop: 15 }}>
          <MDBCol size="6" sm="6">
            <DatePicker
              style={{ marginTop: 5 }}
              selected={actionDate}
              onChange={(date) => setActionDate(date)}
            />
          </MDBCol>
          <MDBCol size="6" sm="3">
            <MDBInput
              className="mb-3"
              type="number"
              id="normalTime"
              min="1"
              max="100"
              label="Normal time"
              style={{ padding: 5 }}
              onChange={(evt) => setNormalTime(evt.target.value)}
            />
          </MDBCol>
          <MDBCol size="6" sm="3">
            <MDBInput
              className="mb-3"
              type="number"
              id="otTime"
              min="1"
              max="100"
              label="OT time"
              style={{ padding: 5 }}
              onChange={(evt) => setOverTime(evt.target.value)}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow style={{ marginTop: 15 }}>
          <MDBCol size="6" sm="12">
            <MDBTextArea
              className="mb-3"
              label="Timesheet Note"
              id="tsNote"
              rows="{4}"
              onChange={(evt) => setTsNote(evt.target.value)}
            />
          </MDBCol>
        </MDBRow>
      </>
    );
  };

  return (
    <>
      <MDBModal open={true} onClose={onCloseModal} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>{taskInfo?.name}</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={onCloseModal}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>{renderActionControl()}</MDBModalBody>

            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={onCloseModal}>
                Close
              </MDBBtn>
              <MDBBtn onClick={() => handleSaveData()}>Save</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <ToastContainer />
    </>
  );
}

export default LogWork;
