import { toNumber } from "lodash";
import { MDBCol, MDBInput, MDBRow } from "mdb-react-ui-kit";
import { useState } from "react";

function CtrPhaseInfo({ phaseInfo, onSave }) {
  const [phaseName, setPhaseName] = useState(phaseInfo?.name ?? "");
  const [priorityNumber, setPriorityNumber] = useState(phaseInfo?.priorityNumber ?? "");

  return <MDBRow>
    <MDBCol size="3" sm="2">
      <MDBInput
        className="mb-3"
        type="number"
        label="Priority"
        value={priorityNumber}
        style={{ padding: 5 }}
        onChange={(evt) => {
          let number = toNumber(evt.target.value)
          setPriorityNumber(number);
          onSave(phaseInfo?.id, phaseName, number);
        }}
      />
    </MDBCol>
    <MDBCol size="3" sm="10">
      <MDBInput
        className="mb-3"
        label="Phase Name"
        value={phaseName ?? ""}
        style={{ padding: 5 }}
        onChange={(evt) => {
          let name = evt.target.value;
          setPhaseName(name);
          onSave(phaseInfo?.id, name, priorityNumber)
        }}
      />
    </MDBCol>
  </MDBRow>

}

export default CtrPhaseInfo;
