import { Label } from "reactstrap";
import { toCommas, toThousands } from "../../utils/utilities";

function ViewAmount({ totalAmout }) {

  const renderAmount = () => {
    if ((totalAmout ?? 0) === 0) return <></>
    let divThousands = toThousands(totalAmout ?? 0);
    let divCommas = toCommas(totalAmout ?? 0);
    return <Label title={divCommas}>{divThousands}</Label>
  }

  return renderAmount()
}

export default ViewAmount;
