import { first } from "lodash";
import { MDBAccordion, MDBAccordionItem } from "mdb-react-ui-kit";
import Multiselect from "multiselect-react-dropdown";
import { useEffect, useState } from "react";

function CtrStatusInfo({
  onlyStatus,
  statuses,
  curStatusFinish,
  curStatusName,
  onSaveStatusName,
  onSaveStatusIdFinish,
  onSaveStatusIds,
}) {
  const [statusIds, setStatusIds] = useState([]);
  const [statusFinish, setStatusFinish] = useState(curStatusFinish);
  const [statusName, setStatusName] = useState(curStatusName ?? "");

  useEffect(() => {
    let ids = (statuses ?? []).map((p) => {
      return { name: p.statusName, id: p.id };
    });
    setStatusIds(ids);
  }, [statuses]);

  const options = () => {
    return (statuses ?? []).map((p) => {
      return { name: p.statusName, id: p.id };
    });
  };

  const currentOptions = () => {
    return (statusIds ?? []).map((p) => {
      return { name: p.statusName, id: p.id };
    });
  };

  const finishOptions = () => {
    return (statusIds ?? []).map((p) => {
      return { name: p.statusName, id: p.id };
    });
  };

  const onSelectedValues = (items) => {
    let ids = (items ?? []).map((it) => it.id);
    let rs = (statuses ?? [])
      .filter((p) => ids.some((id) => id === p.id));
    setStatusIds(rs ?? []);

    if (!onlyStatus) {
      let hasCur = (rs ?? []).filter((id) => id.name === statusName) ?? [];
      if (hasCur.length < 1) {
        let st = first(rs) ?? {}
        setStatusName(st.name ?? "To do");
        onSaveStatusName(st.name ?? "To do");
      }
    }

    let data = (rs ?? []).map((it) => it.id);
    onSaveStatusIds(data);
  };

  const onSelectedCurrentValues = (items) => {
    let curItem = first(items) ?? {};
    setStatusName(curItem?.name);
    onSaveStatusName(curItem?.name);
  };

  const onSelectedFinishValues = (items) => {
    let curItem = first(items) ?? {};
    setStatusFinish(curItem?.name);
    onSaveStatusIdFinish(curItem?.id);
  };

  const renderTitleStatus = () => {
    if ((statusIds ?? []).length > 0) {
      return `Columns display status (${(statusIds ?? []).length} cols)`
    }

    return "Columns display status"
  }

  const renderTitleCurrentStatus = () => {
    if (statusName?.length > 0) return <>{`Current Status : ${statusName}`} </>
    return "Select a Status"
  }

  const renderTitleFinishStatus = () => {
    if (statusFinish?.length > 0) return <>{`Status finished : ${statusFinish}`} </>
    return "Definition of Done"
  }

  const renderBody = () => {
    if (onlyStatus) {
      return <MDBAccordion alwaysOpen initialActive={1}>
        <MDBAccordionItem collapseId={1} headerTitle={renderTitleStatus()}>
          <Multiselect
            options={options()}
            placeholder="Select status"
            onSelect={(items) => onSelectedValues(items)}
            onRemove={(items) => onSelectedValues(items)}
            hidePlaceholder={(statusIds ?? []).length > 0}
            displayValue="name"
          />
        </MDBAccordionItem>
      </MDBAccordion>
    }

    return <MDBAccordion alwaysOpen initialActive={1}>
      <MDBAccordionItem collapseId={1} headerTitle={renderTitleStatus()}>
        <Multiselect
          options={options()}
          placeholder="Select status"
          onSelect={(items) => onSelectedValues(items)}
          onRemove={(items) => onSelectedValues(items)}
          hidePlaceholder={(statusIds ?? []).length > 0}
          displayValue="name"
        />
      </MDBAccordionItem>
      <MDBAccordionItem collapseId={2} headerTitle={renderTitleCurrentStatus()}>
        <Multiselect
          options={currentOptions()}
          singleSelect
          placeholder="Select a current status"
          onSelect={(items) => onSelectedCurrentValues(items)}
          onRemove={(items) => onSelectedCurrentValues(items)}
          hidePlaceholder={statusName?.length > 0}
          displayValue="name"
        />
      </MDBAccordionItem>
      <MDBAccordionItem collapseId={3} headerTitle={renderTitleFinishStatus()}>
        <Multiselect
          options={finishOptions()}
          singleSelect
          placeholder="Select a status is finished"
          onSelect={(items) => onSelectedFinishValues(items)}
          onRemove={(items) => onSelectedFinishValues(items)}
          hidePlaceholder={statusName?.length > 0}
          displayValue="name"
        />
      </MDBAccordionItem>
    </MDBAccordion>
  }

  return renderBody();
}

export default CtrStatusInfo;
