import { first } from "lodash";
import { MDBAccordion, MDBAccordionItem, MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import CheckListItem from "../Projects/CheckListItem";
import axios from "axios";
import { apiEndpoint } from "../../utils/apiEndpoint";
import { configHeader } from "../../utils/tokenHelper";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function CheckListWithPhase({ myTeamId, phaseId, allCheckList }) {
  const renderBody = () => {

    if (allCheckList?.length < 1) return <h1>You don't have any checklist for settings</h1>

    let teamIds = [...new Set((allCheckList ?? []).map(item => item?.teamId ?? 0))];
    let firstTeam = first(teamIds ?? []);

    return <>
      <MDBAccordion alwaysOpen initialActive={firstTeam ?? 1}>
        {(teamIds ?? []).map((teamId) => {
          let data = (allCheckList ?? []).filter((item) => item?.teamId === teamId) ?? [];
          let firstItem = first(data);
          return <MDBAccordionItem collapseId={teamId} headerTitle={<>
            <MDBIcon fas icon="list-ol" style={{ marginRight: 15 }} /> {` ${firstItem?.teamName} (${(data ?? []).length})`}
          </>}
          >
            {renderCheckList(data ?? [])}
          </MDBAccordionItem>
        })}
      </MDBAccordion >
    </>
  }

  const handleChangeParagraph = (item, targetChecked) => {
    let data = {
      teamId: myTeamId,
      parentParagraphId: 0,
      paragraphId: item?.id,
      paragraphItemId: 0,
      paragraphSubItemId: 0,
    };
    saveData(data, targetChecked);
  };

  const handleChangeSubParagraph = (item, targetChecked) => {
    let data = {
      teamId: myTeamId,
      parentParagraphId: 0,
      paragraphId: 0,
      paragraphItemId: item?.id,
      paragraphSubItemId: 0,
    };
    saveData(data, targetChecked);
  };

  const saveData = (data, checked) => {
    if (checked) {
      activeCheckList(phaseId, data);
    } else {
      disableCheckList(phaseId, data);
    }
  }

  const disableCheckList = (phaseId, data) => {
    phaseId &&
      axios
        .put(
          `${apiEndpoint.hosting}/Teams/${phaseId}/CheckList/Disable`,
          data,
          configHeader()
        )
        .then(() => { })
        .catch(() => {
          toast.error(
            "Something went wrong, Check list is not yet disable, please try again later"
          );
        });
  };

  const activeCheckList = (phaseId, data) => {
    phaseId &&
      axios
        .put(
          `${apiEndpoint.hosting}/Teams/${phaseId}/CheckList/Active`,
          data,
          configHeader()
        )
        .then(() => { })
        .catch(() => {
          toast.error(
            "Something went wrong, Check list is not active, please try again later"
          );
        });
  };

  const renderCheckList = (items) => {
    return <>
      {(items ?? []).map((item) => {
        let countItems = (item?.paragraphs ?? []).filter((p) => p?.isChecked);
        let divTitle = `${(countItems ?? []).length} / ${(item?.paragraphs ?? []).length}`;
        return (
          <MDBAccordion initialActive={1}>
            <MDBAccordionItem collapseId={item?.id} headerTitle={<>
              <MDBIcon fas icon="check-circle" style={{ marginRight: 15 }} />
              {`${item?.name} (${divTitle})`}</>
            }>
              <MDBRow>
                <MDBCol sm="10" style={{ textAlign: "left", margin: 15 }}>
                  {item?.name}
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol size="3" sm="1"></MDBCol>
                <MDBCol size="3" sm="10">
                  {(item?.paragraphs).map((p) => {
                    return (
                      <>
                        <MDBRow>
                          <MDBCol size="3" sm="10">
                            <MDBRow>
                              <CheckListItem
                                isChecked={p?.isChecked}
                                displayName={p?.name}
                                handleChange={(targetChecked) =>
                                  handleChangeParagraph(p, targetChecked)
                                }
                              />
                            </MDBRow>
                            <MDBRow>
                              <MDBCol size="3" sm="1"></MDBCol>
                              <MDBCol size="3" sm="10">
                                {(p?.headings).map((h) => {
                                  return (
                                    <CheckListItem
                                      isChecked={h?.isChecked}
                                      displayName={h?.name}
                                      handleChange={(targetChecked) =>
                                        handleChangeSubParagraph(
                                          h,
                                          targetChecked
                                        )
                                      }
                                    />
                                  );
                                })}
                              </MDBCol>
                            </MDBRow>
                          </MDBCol>
                        </MDBRow>
                      </>
                    );
                  })}
                </MDBCol>
              </MDBRow>
            </MDBAccordionItem>
          </MDBAccordion>
        );
      })}</>
  }

  return (
    <>
      {renderBody()}
      <ToastContainer />
    </>
  );
}

export default CheckListWithPhase;
