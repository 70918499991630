import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { configHeader, isTokenExpired } from "../../utils/tokenHelper";
import { apiEndpoint } from "../../utils/apiEndpoint";
import axios from "axios";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import { orderBy } from "lodash";
import { MDBBtn, MDBIcon } from "mdb-react-ui-kit";
import CreateProject from "../../modals/CreateProject";
import EditProject from "../../modals/EditProject";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { dateFormatDMY, onGridReady, userRoles } from "../../utils/utilities";
import ConfirmDeleteDialog from "../../components/ConfirmDialog";
import CloneProject from "../../modals/CloneProject";
import ViewProjectMembers from "./ViewProjectMembers";

const Grids = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState();
  const [projectId, setProjectId] = useState(0);
  const [isDelete, setIsDelete] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [popupEdit, setPopupEdit] = useState(false);
  const [popupClone, setPopupClone] = useState(false);
  const [popupMembers, setPopupMembers] = useState(false);

  const [statuses, setStatuses] = useState();
  const [userRole, setUserRole] = useState();
  const [projectInfo, setProjectInfo] = useState();

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
    document.title = "Project List";
    var info = window.localStorage.getItem("userInfo") ?? "";
    let infoObj = JSON.parse(info);
    setUserRole(infoObj.role);
  }, []);

  useEffect(() => {
    reloadAllListOfProjects();
    reloadAllListOfStatus();
  }, []);

  const reloadAllListOfProjects = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.projectUrl}`,
        configHeader()
      )
      .then((result) => {
        var items = result?.data ?? [];
        setProjects(orderBy(items, "priorityNumber"));
      });
  };

  const reloadAllListOfStatus = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.matterUrl}/All-Status`,
        configHeader()
      )
      .then((result) => {
        setStatuses(result?.data ?? []);
      });
  };

  const colUserDefs = [
    {
      headerName: "Row",
      valueGetter: "node.rowIndex + 1",
      width: 65,
    },
    {
      field: "name",
      cellStyle: {
        textAlign: "left",
      },
      pinned: 'left',
      lockPinned: true,
      filter: true
    },
    {
      field: "assigned",
      headerName: "PM",
      cellStyle: {
        textAlign: "left",
      },
      filter: true
    },
    {
      field: "assistant",
      headerName: "Assistant PM",
      filter: true,
      cellStyle: {
        textAlign: "left",
      },
      width: 135,
    },
    {
      field: "statusName",
      headerName: "Status",
      cellStyle: {
        textAlign: "left",
      },
      width: 125,
      filter: true
    },
    {
      field: "highlight",
      headerName: "",
      width: 160,
      pinned: 'right',
      lockPinned: true,
      cellRenderer: (row) => {
        return <>
          {renderIconMembers(row.data)}
          {renderIconHighlight(row.data?.highlight, row.data.id, row.data.name, true)}
          {renderIconPMFinish(row.data?.isFinished, row.data.id, row.data.name, row.data?.isPM)}
          {renderIconUserEdit(row.data)}
        </>
      },
    },
    {
      field: "startDate",
      cellRenderer: (data) => {
        return dateFormatDMY(data?.value);
      },
      width: 125,
    },
    {
      field: "endDate",
      cellRenderer: (data) => {
        return dateFormatDMY(data?.value);
      },
      width: 125,
    },
    {
      field: "estimateHours",
      width: 95, filter: "agNumberColumnFilter",
      cellStyle: {
        textAlign: "right",
      },
      cellRenderer: (data) => {
        return !data?.value ? "" : data?.value;
      },
    },    
    {
      field: "description",
      cellStyle: {
        textAlign: "left",
      },
      width: 375,
      filter: true
    },
  ]

  const renderIconUserEdit = (dt) => {
    if (!dt.isPM) return <></>
    return (
      <MDBIcon
        fas
        icon="edit"
        style={{ cursor: "pointer", marginRight: 15 }}
        onClick={() => handleEditProject(dt)}
      />
    );
  }

  const renderIconMembers = (dt) => {
    if (!dt.isPM) return <></>
    return (
      <MDBIcon
        fas
        icon="users"
        style={{ cursor: "pointer", marginRight: 15 }}
        title={`Members of project ${dt.name}`}
        onClick={() => handleMembersProject(dt)}
      />
    );
  }

  const colDefs = [
    {
      headerName: "Row",
      valueGetter: "node.rowIndex + 1",
      width: 65,
    },
    {
      field: "name",
      cellStyle: {
        textAlign: "left",
      },
      filter: true
    },
    {
      field: "assigned",
      headerName: "PM",
      cellStyle: {
        textAlign: "left",
      },
      filter: true
    },
    {
      field: "assistant",
      headerName: "Assistant PM",
      filter: true,
      cellStyle: {
        textAlign: "left",
      },
      width: 135,
    },
    { field: "priorityNumber", width: 75 },
    {
      field: "statusName",
      cellStyle: {
        textAlign: "left",
      },
      width: 75,
      filter: true
    },
    {
      field: "startDate",
      cellRenderer: (data) => {
        return dateFormatDMY(data?.value);
      },
      width: 125,
    },
    {
      field: "endDate",
      cellRenderer: (data) => {
        return dateFormatDMY(data?.value);
      },
      width: 125,
    },
    {
      field: "estimateHours",
      width: 95, filter: "agNumberColumnFilter",
      cellStyle: {
        textAlign: "right",
      },
      cellRenderer: (data) => {
        return !data?.value ? "" : data?.value;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 175,
      pinned: 'right',
      lockPinned: true,
      cellRenderer: (row) => {
        return (
          <>
            {renderIconHighlight(row.data?.highlight, row.data.id, row.data.name)}
            {renderIconFinish(row.data?.isFinished, row.data.id, row.data.name)}
            <MDBIcon
              fas
              icon="users"
              style={{ cursor: "pointer", marginRight: 10 }}
              title={`Members of project ${row.data.name}`}
              onClick={() => handleMembersProject(row.data)}
            />
            <MDBIcon
              fas
              icon="edit"
              style={{ cursor: "pointer", marginRight: 10 }}
              onClick={() => handleEditProject(row.data)}
            />
            <MDBIcon
              fas
              icon="clone"
              style={{ cursor: "pointer", marginRight: 10 }}
              title={`Clone structure project ${row.data.name}`}
              onClick={() => handleCloneProject(row.data)}
            />
            <MDBIcon
              fas
              icon="trash-alt" color="warning"
              title={`Delete Project ${row.data.name}`}
              onClick={() => handleDeleteProject(row.data)}
            />
          </>
        );
      },
    },
    {
      field: "description",
      cellStyle: {
        textAlign: "left",
      },
      width: 375,
      filter: true
    },
  ];

  const renderIconHighlight = (highlight, id, name, viewOnly) => {
    if (highlight) return <>
      <MDBIcon
        fas
        icon="pencil-ruler"
        color='warning'
        style={{ cursor: "pointer", marginRight: 15 }}
        onClick={() => !viewOnly && handleUnlightProject(id)}
        title={`Highlight Project ${name}`}
      />
    </>;

    return (
      <MDBIcon
        fas
        icon="highlighter"
        style={{ cursor: "pointer", marginRight: 15 }}
        onClick={() => !viewOnly && handleHighlightProject(id)}
        title={`Set Highlight Project ${name}`}
      />
    );
  }

  const renderIconFinish = (isFinished, id, name) => {
    if (isFinished) return <></>;
    return (
      <MDBIcon
        fas
        icon="hourglass-end"
        style={{ cursor: "pointer", marginRight: 15 }}
        onClick={() => handleFinishProject(id)}
        title={`Set Finish Project ${name}`}
      />
    );
  }

  const renderIconPMFinish = (isFinished, id, name, isPM) => {
    if (isFinished) return <></>;
    if (isFinished || !isPM) return <></>;
    return (
      <MDBIcon
        fas
        icon="hourglass-end"
        style={{ cursor: "pointer", marginRight: 15 }}
        onClick={() => handleFinishProject(id)}
        title={`Set Finish Project ${name}`}
      />
    );
  }

  const handleCloneProject = (data) => {
    setProjectInfo(data);
    setPopupClone(true);
  }

  const handleMembersProject = (data) => {
    setProjectId(data?.id);
    setProjectInfo(data);
    setPopupMembers(true);
  }

  const handleEditProject = (data) => {
    setProjectId(data?.id);
    setProjectInfo(data);
    setPopupEdit(true);
  };

  const handleDeleteProject = (data) => {
    setProjectId(data?.id);
    setProjectInfo(data);
    setIsDelete(true);
  };

  const handleDelete = () => {
    projectId && axios
      .delete(
        `${apiEndpoint.hosting}/${apiEndpoint.projectUrl}/${projectId}`,
        configHeader()
      )
      .then(() => {
        setIsDelete(false);
        reloadAllListOfProjects();
      });
  }

  const handleNewProject = () => {
    setProjectId(0);
    setModalOpen(true);
  };

  const createSiteBar = () => {
    if (userRole !== userRoles.Admin) return <></>;
    return (
      <div class="p-1 text-left bg-light">
        <MDBBtn
          onClick={() => handleNewProject()}
          style={{ cursor: "pointer" }}
        >
          Create a new project
        </MDBBtn>
      </div>
    );
  };

  const onSaveProject = (data) => {
    axios
      .post(
        `${apiEndpoint.hosting}/${apiEndpoint.projectUrl}`,
        data,
        configHeader()
      )
      .then(() => {
        setModalOpen(false);
        reloadAllListOfProjects();
        toast.success("Your project has been created successful.");
      });
  };

  const onCloneProject = (data) => {
    axios
      .post(
        `${apiEndpoint.hosting}/${apiEndpoint.projectUrl}/Clone`,
        data,
        configHeader()
      )
      .then(() => {
        setPopupClone(false);
        setProjectInfo({});
        reloadAllListOfProjects();
        toast.success("Your project has been created successful.");
      });
  }

  const onUpdateProject = (data) => {
    projectId &&
      axios
        .put(
          `${apiEndpoint.hosting}/${apiEndpoint.projectUrl}/${projectId}/BasicInfo`,
          data,
          configHeader()
        )
        .then(() => {
          setPopupEdit(false);
          setProjectInfo({});
          reloadAllListOfProjects();
          toast.success("Your project has been updated successful.");
        });
  };

  const handleFinishProject = (id) => {
    id &&
      axios
        .put(
          `${apiEndpoint.hosting}/${apiEndpoint.projectUrl}/${id}/Finished`,
          {},
          configHeader()
        )
        .then(() => {
          reloadAllListOfProjects();
          toast.success("Your project has been finished.");
        });
  };

  const handleHighlightProject = (id) => {
    id &&
      axios
        .put(
          `${apiEndpoint.hosting}/${apiEndpoint.projectUrl}/${id}/Highlight`,
          {},
          configHeader()
        )
        .then(() => {
          reloadAllListOfProjects();
          toast.success("Your project has been Highlight.");
        });
  }

  const handleUnlightProject = (id) => {
    id &&
      axios
        .put(
          `${apiEndpoint.hosting}/${apiEndpoint.projectUrl}/${id}/Remove-Highlight`,
          {},
          configHeader()
        )
        .then(() => {
          reloadAllListOfProjects();
          toast.success("Your project has been removed Highlight.");
        });
  }

  return (
    <>
      {createSiteBar()}
      <div className="ag-theme-quartz" style={{ height: "68vh", margin: 15 }}>
        <AgGridReact
          rowData={orderBy(projects, "priorityNumber")}
          columnDefs={userRole === userRoles.Admin ? colDefs : colUserDefs}
          onGridReady={onGridReady}
        />
      </div>

      {modalOpen && (
        <CreateProject
          statuses={statuses ?? []}
          onCloseModal={() => setModalOpen(false)}
          onSave={(data) => onSaveProject(data)}
        />
      )}

      {isDelete && <ConfirmDeleteDialog
        openModal={true}
        titleDelete={`Delete project ${projectInfo?.name}`}
        onCloseModal={() => setIsDelete(false)}
        onDelete={() => handleDelete()}
      />}

      {popupClone && <CloneProject statuses={statuses ?? []}
        onCloseModal={() => setPopupClone(false)}
        onSave={(data) => onCloneProject(data)}
        projectInfo={projectInfo} />}

      {popupMembers && <ViewProjectMembers
        projectInfo={projectInfo}
        onCloseModal={() => setPopupMembers(false)} />}

      {popupEdit && (
        <EditProject
          statuses={statuses ?? []}
          onCloseModal={() => setPopupEdit(false)}
          onSave={(data) => onUpdateProject(data)}
          projectInfo={projectInfo}
        />
      )}
      <ToastContainer />
    </>
  );
};

export default Grids;
