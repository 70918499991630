import { AgGridReact } from "ag-grid-react";
import { apiEndpoint } from "../../utils/apiEndpoint";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { orderBy } from "lodash";
import { configHeader, isTokenExpired } from "../../utils/tokenHelper";
import { dateFormatDMY, onGridReady } from "../../utils/utilities";

function ViewSubTask() {
  const navigate = useNavigate();
  const [projectTasks, setProjectTasks] = useState();

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
    document.title = "Time Sheets";
  }, []);

  useEffect(() => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.dashboardUrl}/Timesheets`,
        configHeader()
      )
      .then((result) => {
        setProjectTasks(result?.data);
      });
  }, []);

  const colDefs = [
    {
      headerName: "Row",
      valueGetter: "node.rowIndex + 1",
      width: 65,
    },
    {
      field: "displayName",
      headerName: "Staff",
      filter: true,
      pinned: 'left',
      lockPinned: true,
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "teamName",
      headerName: "Team",
      filter: true,
      pinned: 'left',
      lockPinned: true,
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "actionDate",
      width: 125,
      cellRenderer: (data) => {
        return dateFormatDMY(data?.value);
      },
    },
    {
      field: "normalHours",
      width: 65,
      headerName: "Working",
      cellStyle: {
        textAlign: "right",
      },
    },
    {
      field: "overTimeHours",
      width: 65,
      headerName: "OT",
      cellStyle: {
        textAlign: "right",
      },
    },
    {
      field: "note",
      headerName: "Time sheet note",
      filter: true,
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "statusName",
      width: 85,
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "taskName",
      filter: true,
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "phaseName",
      filter: true,
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "projectName",
      filter: true,
      cellStyle: {
        textAlign: "left",
      },
    },
  ];
  return (
    <div className="ag-theme-quartz" style={{ height: "75vh" }}>     
      <AgGridReact
        rowData={orderBy(projectTasks, "priorityNumber")}
        columnDefs={colDefs}
        onGridReady={onGridReady}
      />
    </div>
  );
}

export default ViewSubTask;
