import React from "react";
import {
  MDBAccordion,
  MDBAccordionItem,
  MDBBtn,
  MDBCol,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
} from "mdb-react-ui-kit";
import "./Project.css";
import CheckListItem from "./CheckListItem";
import { first } from "lodash";

function PhaseCheckList({
  open,
  teamId,
  phaseInfo,
  checkListOfPhase,
  handleOnClose,
  saveData,
}) {
  
  const handleChangeParagraph = (item, targetChecked) => {
    let data = {
      teamId: teamId,
      parentParagraphId: 0,
      paragraphId: item?.id,
      paragraphItemId: 0,
      paragraphSubItemId: 0,
      checkListId: item?.checkListId
    };
    
    saveData(phaseInfo?.id, data, targetChecked);
  };

  const handleChangeSubParagraph = (item, targetChecked) => {
    let data = {
      teamId: teamId,
      parentParagraphId: 0,
      paragraphId: 0,
      paragraphItemId: item?.id,
      paragraphSubItemId: 0,
      checkListId: item?.checkListId
    };

    saveData(phaseInfo?.id, data, targetChecked);
  };

  const renderCheckList = (items) => {
    return <>
      {(items ?? []).map((item) => {
        let countItems = (item?.paragraphs ?? []).filter((p) => p?.isChecked);
        let divTitle = `${(countItems ?? []).length} / ${(item?.paragraphs ?? []).length}`;
        return (
          <MDBAccordion initialActive={1}>
            <MDBAccordionItem collapseId={item?.id} headerTitle={`${item?.name} (${divTitle})`}>
              <MDBRow>
                <MDBCol sm="10" style={{ textAlign: "left", margin: 15 }}>
                  {item?.name}
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol size="3" sm="1"></MDBCol>
                <MDBCol size="3" sm="10">
                  {(item?.paragraphs).map((p) => {
                    return (
                      <>
                        <MDBRow>
                          <MDBCol size="3" sm="10">
                            <MDBRow>
                              <CheckListItem
                                isChecked={p?.isChecked}
                                displayName={p?.name}
                                handleChange={(targetChecked) =>
                                  handleChangeParagraph(p, targetChecked)
                                }
                                useCheckBox
                              />
                            </MDBRow>
                            <MDBRow>
                              <MDBCol size="3" sm="1"></MDBCol>
                              <MDBCol size="3" sm="10">
                                {(p?.headings).map((h) => {
                                  return (
                                    <CheckListItem
                                      isChecked={h?.isChecked}
                                      displayName={h?.name}
                                      useCheckBox
                                      handleChange={(targetChecked) =>
                                        handleChangeSubParagraph(
                                          h,
                                          targetChecked
                                        )
                                      }
                                    />
                                  );
                                })}
                              </MDBCol>
                            </MDBRow>
                          </MDBCol>
                        </MDBRow>
                      </>
                    );
                  })}
                </MDBCol>
              </MDBRow>
            </MDBAccordionItem>
          </MDBAccordion>
        );
      })}</>
  }

  const renderBody = () => {
    let teamIds = [...new Set((checkListOfPhase ?? []).map(item => item?.teamId ?? 0))];
    if(teamIds?.length === 1) return renderCheckList(checkListOfPhase ?? []);

    let firstTeam = first(teamIds ?? []);
    return (
      <>
        <MDBAccordion alwaysOpen initialActive={firstTeam ?? 1}>
          {(teamIds ?? []).map((teamId) => {
            let data = (checkListOfPhase ?? []).filter((item) => item?.teamId === teamId) ?? [];
            let firstItem = first(data);
            return <MDBAccordionItem collapseId={teamId} headerTitle={<>
              <MDBIcon fas icon="users" style={{ marginRight: 15 }} /> {` ${firstItem?.teamName} (${(data ?? []).length})`}
            </>}
            >
              {renderCheckList(data ?? [])}
            </MDBAccordionItem>
          })}
        </MDBAccordion >
      </>
    );
  };

  return (
    <>
      <MDBModal
        open={open}
        onClose={handleOnClose}
        tabIndex="-1"
        className="checkListDialog"
      >
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>{phaseInfo?.name}</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                style={{ cursor: "pointer" }}
                onClick={handleOnClose}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <div className="divScroll">{renderBody()}</div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color="secondary"
                style={{ cursor: "pointer" }}
                onClick={handleOnClose}
              >
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}

export default PhaseCheckList;
