import { useEffect, useState } from "react";
import Menu from "../App/Menu";
import { MDBBtn, MDBIcon } from "mdb-react-ui-kit";
import CreatePhase from "../../modals/CreatePhase";
import CreateNewTask from "../../modals/CreateNewTask";
import CreateNewSubTask from "../../modals/CreateNewSubTask";
import axios from "axios";
import { apiEndpoint } from "../../utils/apiEndpoint";
import { configHeader } from "../../utils/tokenHelper";
import { userRoles } from "../../utils/utilities";

function ProjectHeader({
  project,
  phaseName,
  phaseId,
  phase,
  statuses,
  statusOfPhase,
  teams,
  taskInfo,
  staffsOfPhase,
  userRole,
  onSavePhase,
  onSaveTask,
  handleAddSubtask,
  reloadTaskInfo,
  handleCreatePhaseClick,
  handleCreateTaskClick,
  handleCreateSubTaskClick
}) {
  const [openTaskModal, setOpenTaskModal] = useState(false);
  const [openPhaseModal, setOpenPhaseModal] = useState(false);
  const [openSubTaskModal, setOpenSubTaskModal] = useState(false);
  const [titleBoard, setTitleBoard] = useState([]);
  const [teamId, setTeamId] = useState();
  const [curTeams, setCurTeams] = useState(teams ?? []);
  const [pm, setPM] = useState();

  useEffect(() => {
    let items = [];
    taskInfo?.taskName && items.push(taskInfo?.taskName);
    phaseName?.length > 0 && items.push(phaseName);
    project?.name && items.push(project?.name)
    setPM(project?.pm ?? "");

    setTitleBoard(items ?? []);
  }, [taskInfo, project, phase]);

  useEffect(() => {
    var info = window.localStorage.getItem("userInfo") ?? "";
    let infoObj = JSON.parse(info);
    setTeamId(infoObj.teamId);
    reloadAllTeams();
  }, []);

  const renderUserCtrl = (taskInfo) => {
    if (taskInfo?.taskId > 0) {
      return (
        <MDBBtn
          style={{ cursor: "pointer", borderRadius: 16 }}
          color={"success"}
          onClick={() => handleCreateSubTaskClick()}
        >
          Add Sub Task
        </MDBBtn>
      );
    }

    if (phaseName?.length > 0 && (phaseId ?? 0) > 0) {
      return (
        <MDBBtn
          style={{ cursor: "pointer", borderRadius: 16 }}
          color={"secondary"}
          onClick={() => handleCreateTaskClick()}
        >
          Add Task
        </MDBBtn>
      );
    }

    return <></>
  }

  const renderButtons = () => {
    let isPM = taskInfo?.isPM || project?.isPM;
    if (userRole !== userRoles.Admin && !isPM) {
      return renderUserCtrl(taskInfo);
    }

    if (taskInfo?.taskId > 0) {
      return <MDBBtn
        className="btn-add-new-item"
        color={"success"}
        onClick={() => handleCreateSubTaskClick()}
      >
        Add Sub Task
      </MDBBtn>
    }

    if (phaseName?.length > 0 && (phaseId ?? 0) > 0) {
      return <MDBBtn
        className="btn-add-new-item"
        color={"secondary"}
        onClick={() => handleCreateTaskClick()}
      >
        Add Task
      </MDBBtn>
    }

    if ((project?.name ?? "").length > 0 && project?.id > 0) {
      return <MDBBtn
        className="btn-add-new-item"
        color={"info"}
        onClick={() => handleCreatePhaseClick()}
      >
        Add Phase
      </MDBBtn>
    }

    return <></>
  };

  const onSaveSubTask = (data) => {
    handleAddSubtask(taskInfo?.taskId, data);
    setOpenSubTaskModal(false);
    reloadTaskInfo(taskInfo?.taskId);
  };

  const reloadAllTeams = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/Teams`,
        configHeader()
      )
      .then((result) => {
        let dataResults = result?.data?.dataResults ?? []
        setCurTeams(dataResults);
      });
  }

  const renderProjectManager = () => {
    if (pm?.length > 0) {
      return <div title="Project manager" style={{ marginLeft: 20 }}>
        <div className=" truncate max-w-[250px] ">
          <MDBIcon fas icon={"user-alt"} style={{ marginRight: 15 }} />{pm}
        </div>
      </div>
    }

    return <></>
  }

  const renderProjectSideBar = () => {
    return <>
      <div
        className=" flex items-center space-x-2  md:space-x-4"
        style={{ marginLeft: "6vw", maxWidth: 456 }}
      >
        <div className=" flex items-center ">
          <>
            <h3 className=" truncate max-w-[500px] md:text-2xl text-xl font-bold md:ml-20 font-sans  ">
              {titleBoard?.[0] ?? ""}
            </h3>
            <h5 className=" truncate max-w-[400px] md:text-2xl text-xl  md:ml-10 font-sans  ">
              {titleBoard?.[1] ?? ""}
            </h5>
            <h6 className=" truncate max-w-[300px] md:text-2xl text-xl  md:ml-10 font-sans  ">
              {titleBoard?.[2] ?? ""}
            </h6>
            {renderProjectManager()}
          </>
        </div>
      </div>
      <div className=" flex items-right space-x-1  md:space-x-2" style={{ marginRight: "16vw" }}>
        {renderButtons()}
      </div>
    </>
  }

  return (
    <div className=" p-1 fixed left-0 bg-white dark:bg-[#2b2c37] right-0 " style={{ zIndex: 100 }}>
      <Menu hasAvatar />
      <header
        className=" flex justify-between dark:text-white items-center  "
        style={{
          color: "white",
          backgroundImage: "conic-gradient(yellow, #9e9ed1, black)",
          fontSize: 16,
          height: 25
        }}
      >
        {renderProjectSideBar()}
      </header>

      {openTaskModal && (phaseId ?? 0) > 0 && (
        <CreateNewTask
          phaseId={phaseId}
          phaseName={phaseName}
          statuses={statusOfPhase ?? []}
          teams={(curTeams ?? []).filter((item) => item.isActive || +item.status === 1)}
          userRole={userRole}
          isPM={phase?.isPM}
          onCloseModal={() => setOpenTaskModal(false)}
          onSave={(data) => onSaveTask(data)}
        />
      )}
      {openPhaseModal && (project?.id ?? 0) > 0 && (
        <CreatePhase
          projectId={project?.id}
          projectName={project?.name}
          statuses={statuses ?? []}
          onCloseModal={() => setOpenPhaseModal(false)}
          onSave={(data) => onSavePhase(data)}
        />
      )}
      {openSubTaskModal && (
        <CreateNewSubTask
          taskId={taskInfo?.taskId}
          teamId={teamId}
          taskName={taskInfo?.taskName}
          staffs={staffsOfPhase ?? []}
          isPM={taskInfo?.isPM}
          onCloseModal={() => setOpenSubTaskModal(false)}
          onSave={(data) => onSaveSubTask(data)}
        />
      )}
    </div>
  );
}

export default ProjectHeader;
