import moment from "moment";
import React from "react";
import Chart from "react-apexcharts";

function ProjectByDates({ projects }) {
  const dataSeries = () => {
    let rs = (projects ?? []).map((project) => {
      let rangeDates = (project.rangeDates ?? []).map((item) => {
        return {
          x: item.name,
          y: [
            new Date(moment(item.fromDate)).getTime(),
            new Date(moment(item.toDate)).getTime(),
          ],
        };
      });
      return { name: project.name, data: rangeDates };
    });
    return rs;
  };

  const options = {
    chart: {
      height: 450,
      type: "rangeBar",
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "80%",
      },
    },
    xaxis: {
      type: "datetime",
    },
    stroke: {
      width: 1,
    },
    fill: {
      type: "solid",
      opacity: 0.6,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
    toolbar: {
      show: false,
      tools: {
        download: true,
        selection: true,
        zoom: true,
        zoomin: true,
        zoomout: true,
        pan: true,
        reset: true,
      },
    },
  };
  return (projects ?? []).length < 1 ? (
    <></>
  ) : (
    <>
      <Chart
        options={options}
        series={dataSeries()}
        type="rangeBar"
        height={350}
      />
    </>
  );
}

export default ProjectByDates;
