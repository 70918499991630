import React, { } from "react";

import {
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBIcon,
  MDBNavbarItem,
  MDBNavbarLink,
} from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";

function LeaderMenu() {
  const navigate = useNavigate();
  return (
    <>
      <MDBNavbarItem>
        <MDBNavbarLink onClick={() => navigate("/projects")}>
          <MDBIcon fas icon="city" style={{ marginRight: 15 }} />
          Project Board
        </MDBNavbarLink>
      </MDBNavbarItem>
      <MDBNavbarItem>
        <MDBNavbarLink onClick={() => navigate("/my-timeSheets")}>
          <MDBIcon fas icon="briefcase" style={{ marginRight: 15 }} />
          My TimeSheets
        </MDBNavbarLink>
      </MDBNavbarItem>
      <MDBNavbarItem>
        <MDBDropdown style={{ backgroundColor: "white" }}>
          <MDBDropdownToggle tag="a" className="nav-link" role="button">
            <MDBIcon fas icon="th" style={{ marginRight: 15 }} />
            Settings
          </MDBDropdownToggle>
          <MDBDropdownMenu light>
            <MDBDropdownItem divider />
            <MDBDropdownItem onClick={() => navigate("/members")}>
              <MDBNavbarLink onClick={() => navigate("/members")}>
                <MDBIcon
                  fas
                  icon="user-friends"
                  style={{ marginRight: 15 }}
                />
                List of Users
              </MDBNavbarLink>
            </MDBDropdownItem>
            <MDBDropdownItem divider />
            <MDBDropdownItem onClick={() => navigate("/check-list")}>
              <MDBNavbarLink onClick={() => navigate("/check-list")}>
                <MDBIcon fas icon="th-list" style={{ marginRight: 15 }} />
                Checklist
              </MDBNavbarLink>
            </MDBDropdownItem>
            <MDBDropdownItem divider />
            <MDBDropdownItem onClick={() => navigate("/resources")}>
              <MDBNavbarLink onClick={() => navigate("/resources")}>
                <MDBIcon fas icon="users" style={{ marginRight: 15 }} />
                Resources
              </MDBNavbarLink>
            </MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>
      </MDBNavbarItem>
      <MDBNavbarItem light>
        <MDBDropdown style={{ backgroundColor: "white" }}>
          <MDBDropdownToggle tag="a" className="nav-link" role="button">
            <MDBIcon fas icon="desktop" style={{ marginRight: 15 }} />
            Project monitoring
          </MDBDropdownToggle>
          <MDBDropdownMenu light>
            <MDBDropdownItem divider />
            <MDBDropdownItem onClick={() => navigate("/my-tasks")}>
              <MDBNavbarLink onClick={() => navigate("/my-tasks")}>
                <MDBIcon
                  fas
                  icon="tasks"
                  style={{ marginRight: 15 }}
                />
                List of Tasks
              </MDBNavbarLink>
            </MDBDropdownItem>
            <MDBDropdownItem divider />
            <MDBDropdownItem onClick={() => navigate("/my-projects")}>
              <MDBNavbarLink onClick={() => navigate("/my-projects")}>
                <MDBIcon
                  fas
                  icon="grip-horizontal"
                  style={{ marginRight: 15 }}
                />
                List of Projects
              </MDBNavbarLink>
            </MDBDropdownItem>
            <MDBDropdownItem divider />
            <MDBDropdownItem onClick={() => navigate("/time-sheets")}>
              <MDBNavbarLink onClick={() => navigate("/time-sheets")}>
                <MDBIcon
                  fas
                  icon="calendar-times"
                  style={{ marginRight: 15 }}
                />
                View Time sheet
              </MDBNavbarLink>
            </MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>
      </MDBNavbarItem>
    </>
  );
}

export default LeaderMenu;
