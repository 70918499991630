import { MDBAccordion, MDBAccordionItem, MDBBtn, MDBCol, MDBIcon, MDBInput, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBRow, MDBTextArea } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { configHeader, isTokenExpired } from "../../utils/tokenHelper";
import { useNavigate } from "react-router-dom";
import { apiEndpoint } from "../../utils/apiEndpoint";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Bill.css";
import { first, orderBy, toNumber } from "lodash";
import Multiselect from "multiselect-react-dropdown";
import { dateFormatDMY, toCommas } from "../../utils/utilities";
import ViewAmount from "./ViewAmount";

function CreateNewBill({ onCloseModal, onSave }) {
  const navigate = useNavigate();
  const [adress, setAdress] = useState();
  const [contactName, setContactName] = useState();
  const [cashier, setCashier] = useState();
  const [chiefAccountant, setChiefAccountant] = useState();
  const [director, setDirector] = useState();
  const [bankAccount, setBankAccount] = useState();
  const [receiptDate, setReceiptDate] = useState(new Date());
  const [userId, setUserId] = useState();
  const [receiptNo, setReceiptNo] = useState();
  const [debit, setDebit] = useState();
  const [credit, setCredit] = useState();
  const [payer, setPayer] = useState();
  const [receiver, setReceiver] = useState();
  const [companyId, setCompanyId] = useState();
  const [companies, setCompanies] = useState([]);
  const [preparedBy, setPreparedBy] = useState();
  const [description, setDescription] = useState();
  const [projects, setProjects] = useState();
  const [phases, setPhases] = useState();
  const [phaseId, setPhaseId] = useState();
  const [phaseAmount, setPhaseAmount] = useState();

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
    document.title = "Customer";
    var info = window.localStorage.getItem("userInfo") ?? "";
    let infoObj = JSON.parse(info);
    setUserId(infoObj?.id);
    setPreparedBy(infoObj?.displayName);
    reloadMyCompany();
    reloadCompanies();
    reloadProjects();
  }, []);

  const reloadMyCompany = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.companyUrl}/my-company`,
        configHeader()
      )
      .then((result) => {
        let company = result?.data ?? {}
        setCashier(company?.cashier);
        setChiefAccountant(company?.chiefAccountant);
        setDirector(company?.director);
        setBankAccount(company?.bankAccount);
      });
  };

  const reloadCompanies = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.companyUrl}`,
        configHeader()
      )
      .then((result) => {
        setCompanies(result?.data ?? []);
      });
  };

  const reloadProjects = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.projectUrl}`,
        configHeader()
      )
      .then((result) => {
        var items = result?.data ?? [];
        setProjects(orderBy(items, "priorityNumber"));
      });
  };

  const reloadPhaseAmount = () => {
    phaseId && axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.phaseUrl}/${phaseId}/PlanReceiving`,
        configHeader()
      ).then((result) => {
        setPhaseAmount(result?.data);
      });
  };

  useEffect(() => {
    reloadPhaseAmount();
  }, [phaseId]);

  const processingClientSelection = (ids) => {
    let rs = (companies ?? []).filter((p) => ids.some((id) => id === p.id));
    let client = first(rs) ?? {};
    setCompanyId(client?.id);
    setAdress(client?.adress);
    setContactName(client?.contactName);
    setPayer(client?.contactName);
    setBankAccountReceipt(client?.bankAccount)
  }

  const optionProjects = () => {
    return (projects ?? []).map((p) => {
      return { name: p.name, id: p.id };
    });
  };

  const optionPhases = () => {
    return (phases ?? []).map((p) => {
      return { name: p.name, id: p.id };
    });
  };

  const onSelectedProjectValues = (items) => {
    let ids = (items ?? []).map((it) => it.id);
    let rs = (projects ?? []).filter((p) => ids.some((id) => id === p.id));
    let pro = first(rs) ?? {};
    setPhases(pro?.phaseInfo);
    processingClientSelection([pro?.companyId]);
  };

  const onSelectedPhaseValues = (items) => {
    let ids = (items ?? []).map((it) => it.id);
    let rs = (phases ?? []).filter((p) => ids.some((id) => id === p.id));
    let phase = first(rs) ?? {};
    setPhaseId(phase?.id);
  };

  const renderGeneralInfomation = () => {
    return <>
      <MDBRow>
        <MDBCol size="12" sm="12">
          <Multiselect
            options={optionProjects()}
            singleSelect
            placeholder="Select a Project"
            onSelect={(items) => onSelectedProjectValues(items)}
            onRemove={(items) => onSelectedProjectValues(items)}
            displayValue="name"
          />
        </MDBCol>
      </MDBRow>
      <MDBRow style={{ marginTop: 15 }}>
        <MDBCol size="12" sm="12">
          <Multiselect
            options={optionPhases()}
            singleSelect
            placeholder="Select a Phase"
            onSelect={(items) => onSelectedPhaseValues(items)}
            onRemove={(items) => onSelectedPhaseValues(items)}
            displayValue="name"
          />
        </MDBCol>
      </MDBRow>
      {phaseId && renderPhaseAmount()}
      <MDBRow style={{ marginTop: 15 }}>
        <MDBCol sm="6">
          <MDBInput
            className="mb-3"
            label="Receipt Number"
            title="Receipt Number"
            type="text"
            value={receiptNo}
            onChange={(evt) => setReceiptNo(evt.target.value)}
          />
        </MDBCol>
        <MDBCol sm="6">
          <DatePicker
            selected={receiptDate}
            onChange={(date) => setReceiptDate(date)}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size="3" sm="6">
          <MDBInput
            className="mb-3"
            label="Receiver"
            title="Receiver"
            type="text"
            value={receiver}
            onChange={(evt) => setReceiver(evt.target.value)}
          />
        </MDBCol>
        <MDBCol size="3" sm="3">
          <MDBInput
            className="mb-3"
            label="Debit"
            title="Debit"
            type="text"
            value={debit}
            onChange={(evt) => setDebit(evt.target.value)}
          />
        </MDBCol>
        <MDBCol size="3" sm="3">
          <MDBInput
            className="mb-3"
            label="Credit"
            title="Credit"
            type="text"
            value={credit}
            onChange={(evt) => setCredit(evt.target.value)}
          />
        </MDBCol>
      </MDBRow>

    </>
  }

  const renderPhaseAmount = () => {
    if ((phaseId ?? 0) < 1 || toNumber(phaseAmount?.totalAmount ?? 0) === 0) return <></>
    return <MDBRow style={{ marginTop: 15 }}>
      <MDBCol size="3" sm="6">
        {dateFormatDMY(phaseAmount?.planReceivingDate)}
      </MDBCol>
      <MDBCol size="3" sm="6">
        {<ViewAmount totalAmout={phaseAmount?.totalAmout} />}
      </MDBCol>
    </MDBRow>
  }

  const renderCustomerInfomation = () => {
    return <>
      <MDBRow style={{ marginTop: 15 }}>
        <MDBCol size="3" sm="6">
          <MDBInput
            className="mb-3"
            label="Payer"
            title="Payer"
            type="text"
            value={payer}
            onChange={(evt) => setPayer(evt.target.value)}
          />
        </MDBCol>
        <MDBCol size="3" sm="6">
          <MDBInput
            className="mb-3"
            label="Bank Account"
            title="Bank Account"
            type="text"
            value={bankAccountReceipt}
            onChange={(evt) => setBankAccountReceipt(evt.target.value)}
          />
        </MDBCol>
      </MDBRow>
      {adress && renderClientInfo()}
    </>
  }

  const renderClientInfo = () => {
    return <>
      <MDBRow style={{ marginTop: 15 }}>
        <MDBCol size="3" sm="12">
          {contactName}
        </MDBCol>
        <MDBCol size="3" sm="12">
          {adress}
        </MDBCol>
      </MDBRow>
    </>
  }

  const renderDocumentInfomation = () => {
    return <>
      <MDBRow>
        <MDBCol size="3" sm="12">
          <MDBInput
            className="mb-3"
            label="Including"
            title="Including"
            type="text"
            value={including}
            onChange={(evt) => setIncluding(evt.target.value)}
          />
        </MDBCol>
        <MDBCol size="3" sm="12">
          <MDBTextArea
            className="mb-3"
            label="Documents"
            id="documents"
            rows="{4}"
            onChange={(evt) => setDocuments(evt.target.value)}
          />
        </MDBCol>
      </MDBRow>

      <MDBRow >
        <MDBCol size="3" sm="4">
          <MDBInput
            className="mb-3"
            label="Director"
            title="Director"
            type="text"
            value={director}
            onChange={(evt) => setDirector(evt.target.value)}
          />
        </MDBCol>
        <MDBCol size="3" sm="4">
          <MDBInput
            className="mb-3"
            label="Chief Accountant"
            title="Chief Accountant"
            type="text"
            value={chiefAccountant}
            onChange={(evt) => setChiefAccountant(evt.target.value)}
          />
        </MDBCol>
        <MDBCol size="3" sm="4">
          <MDBInput
            className="mb-3"
            label="Cashier"
            title="Cashier"
            type="text"
            value={cashier}
            onChange={(evt) => setCashier(evt.target.value)}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size="3" sm="6">
          <MDBInput
            className="mb-3"
            label="Bank Account"
            title="Bank Account"
            type="text"
            value={bankAccount}
            onChange={(evt) => setBankAccount(evt.target.value)}
          />
        </MDBCol>
        <MDBCol size="3" sm="6">
          <MDBInput
            className="mb-3"
            label="Prepared By"
            title="Prepared By"
            type="text"
            value={preparedBy}
            onChange={(evt) => setPreparedBy(evt.target.value)}
          />
        </MDBCol>
      </MDBRow>
    </>
  }

  const handleVATChange = (val) => {
    setVatPercent(val);
    calculateVATAmount(val);
  }

  const handleInputBankReceipt = (val) => {
    setBankReceipt(val);
    if ((vatPercent ?? 0) > 0) {
      calculateVATAmount(vatPercent)
    }
  }

  const handleInputCashReceipt = (val) => {
    setCashReceipt(val);
    if ((vatPercent ?? 0) > 0) {
      calculateVATAmount(vatPercent)
    }
  }

  const calculateVATAmount = (val) => {
    let totalAmount = toNumber(cashReceipt ?? 0) + toNumber(bankReceipt ?? 0)
    let vatAmount = totalAmount * val / 100;
    setVat(vatAmount)
  }

  const renderPaymentInfomation = () => {
    return <>
      <MDBRow>
        <MDBCol size="3" sm="6">
          <MDBInput
            className="mb-3"
            type="number"
            id="cashReceipt"
            label="Cash Receipt"
            value={cashReceipt}
            style={{ padding: 5, textAlign: "right" }}
            onChange={(evt) => handleInputCashReceipt(evt.target.value)}
          />
        </MDBCol>
        <MDBCol size="3" sm="4">
          {toCommas(cashReceipt)}
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size="3" sm="6">
          <MDBInput
            className="mb-3"
            type="number"
            id="bankReceipt"
            label="Bank Receipt"
            value={bankReceipt}
            style={{ padding: 5, textAlign: "right" }}
            onChange={(evt) => handleInputBankReceipt(evt.target.value)}
          />
        </MDBCol>
        <MDBCol size="3" sm="4">
          {toCommas(bankReceipt)}
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size="3" sm="2">
          <MDBInput
            className="mb-3"
            type="number"
            id="vatPercent"
            label="VAT %"
            value={vatPercent}
            style={{ padding: 5, textAlign: "right" }}
            onChange={(evt) => handleVATChange(evt.target.value)}
          />
        </MDBCol>
        <MDBCol size="3" sm="4">
          <MDBInput
            className="mb-3"
            type="number"
            id="vat"
            label="VAT Amount"
            value={vat}
            style={{ padding: 5, textAlign: "right" }}
            onChange={(evt) => setVat(evt.target.value)}
          />
        </MDBCol>
        <MDBCol size="3" sm="4">
          {toCommas(vat)}
        </MDBCol>
      </MDBRow>
      <MDBRow style={{ marginTop: 15 }}>
        <MDBCol size="6" sm="12">
          <MDBTextArea
            className="mb-3"
            label="Description"
            id="description"
            rows="{4}"
            onChange={(evt) => setDescription(evt.target.value)}
          />
        </MDBCol>
      </MDBRow>
    </>
  }

  const [bankAccountReceipt, setBankAccountReceipt] = useState()
  const [bankReceipt, setBankReceipt] = useState()
  const [cashReceipt, setCashReceipt] = useState()
  const [vat, setVat] = useState()
  const [vatPercent, setVatPercent] = useState()
  const [including, setIncluding] = useState()
  const [documents, setDocuments] = useState()

  const saveData = () => {
    let data = {
      "receiptNo": receiptNo,
      "debit": debit,
      "credit": credit,
      "payer": payer,
      "receiver": receiver,
      "preparedBy": preparedBy,
      "description": description,
      "phaseId": phaseId,
      "userId": userId,
      "companyId": companyId,
      "bankReceipt": bankReceipt,
      "receiptDate": receiptDate,
      "invoiceId": 0,
      "cashReceipt": cashReceipt,
      "cashExchangeRate": 1,
      "totalCash": cashReceipt,
      "bankAccountReceipt": bankAccountReceipt,
      "bankExchangeRate": 1,
      "totalBank": bankReceipt,
      "vat": vat,
      "vatPercent": vatPercent,
      "inWords": "",
      "including": including,
      "documents": documents,
      "receivingFullAmout": true,
      "bankAccount": bankAccount,
      "director": director,
      "cashier": cashier,
      "chiefAccountant": chiefAccountant
    }
    onSave(data);
  }

  const renderBody = () => {
    return <div className="divScroll">
      <MDBAccordion alwaysOpen initialActive={1}>
        <MDBAccordionItem collapseId={1} headerTitle={"General information"}>
          {renderGeneralInfomation()}
        </MDBAccordionItem>
        <MDBAccordionItem collapseId={2} headerTitle={"Customer information"}>
          {renderCustomerInfomation()}
        </MDBAccordionItem>
        <MDBAccordionItem collapseId={3} headerTitle={"Payment information"}>
          {renderPaymentInfomation()}
        </MDBAccordionItem>
        <MDBAccordionItem collapseId={4} headerTitle={"Document information"}>
          {renderDocumentInfomation()}
        </MDBAccordionItem>

      </MDBAccordion>
    </div>
  }

  return <>
    <MDBModal
      open={true}
      onClose={() => onCloseModal()}
      tabIndex="-1"
      className="billDialog"
    >
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>Bill information</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={() => onCloseModal()}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>{renderBody()}</MDBModalBody>

          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={() => onCloseModal()}>
              Close
            </MDBBtn>
            <MDBBtn onClick={() => saveData()}> <MDBIcon fas icon="save" /> Save</MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  </>;
}

export default CreateNewBill;
