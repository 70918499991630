import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

function ColumnBarChart({ timeSheets }) {
  const [reportOptions, setReportOptions] = useState();
  const [dataSeries, setDataSeries] = useState([]);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    loadChart();
  }, [timeSheets]);

  const loadChart = () => {
    let projectNames = (timeSheets ?? []).map((item) => item.projectName);
    let categories = [];
    let dataEstimate = [];
    let dataNormal = [];
    let dataOT = [];

    for (const projectName of projectNames) {
      let isExisting =
        categories.filter((item) => item === projectName)?.length > 0;
      if (!isExisting) {
        categories.push(projectName);
        let estimateHours = (timeSheets ?? [])
          .filter((item) => item.projectName === projectName)
          .reduce(function (prev, cur) {
            return prev + cur.estimateHours;
          }, 0);

        let normalHours = (timeSheets ?? [])
          .filter((item) => item.projectName === projectName)
          .reduce(function (prev, cur) {
            return prev + cur.normalHours;
          }, 0);

        let overTimeHours = (timeSheets ?? [])
          .filter((item) => item.projectName === projectName)
          .reduce(function (prev, cur) {
            return prev + cur.overTimeHours;
          }, 0);

        dataEstimate.push(estimateHours);
        dataNormal.push(normalHours);
        dataOT.push(overTimeHours);
      }
    }

    let opts = {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: categories ?? [],
      },
      yaxis: {
        title: {
          text: " (hours)",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return " " + val + " hours";
          },
        },
      },
    };

    let series = [
      {
        name: "Estimate",
        data: dataEstimate,
      },
      {
        name: "Actual time",
        data: dataNormal,
      },
      {
        name: "OT time",
        data: dataOT,
      },
    ];

    setDataSeries(series);
    setProjects(projectNames);
    setReportOptions(opts);
  };

  return projects?.length < 1 ? (
    <></>
  ) : (
    <>
      <Chart
        options={reportOptions}
        series={dataSeries}
        type="bar"
        height={350}
      />
    </>
  );
}

export default ColumnBarChart;
