import { MDBIcon } from "mdb-react-ui-kit";
import { userRoles } from "../../utils/utilities";

function ViewUserInfo({ roleName, isActive, displayName }) {

  const renderUserInfo = () => {
    let avatar = roleName === userRoles.Admin ? "user-cog" : "user-circle";
    if (roleName === userRoles.Teamleader) avatar = "user-alt";
    if (roleName === "Manager") avatar = "user-tie";
    let icon = !isActive ? (
      ""
    ) : (
      <MDBIcon
        fas
        icon={avatar}
        style={{ marginRight: 15 }}
        title="An individual who regularly engages with and utilizes your platform"
      />
    );
    return (
      <>
        {icon} {displayName}
      </>
    );
  }

  return renderUserInfo();
}

export default ViewUserInfo;
