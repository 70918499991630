import { orderBy } from "lodash";
import {
  MDBAccordionItem,
  MDBCardBody,
  MDBIcon,
  MDBListGroup,
  MDBListGroupItem,
} from "mdb-react-ui-kit";
import { userRoles } from "../../utils/utilities";

function PhaseMenuItem({
  phase,
  currentId,
  taskInfoId,
  userRole,
  onSelectPhase,
  onSelectTask,
  onShowCheckListOfPhase,
  onShowAllCheckListOfPhase
}) {

  const renderTaskMenus = (taskMenus) => {
    if (taskMenus?.length < 1) return <></>;

    let items = orderBy(taskMenus ?? [], "priorityNumber");
    return (
      <MDBListGroup light>
        {items.map((item, idx) => {
          return (
            // <MDBListGroupItem activer={taskInfoId === item?.id}
            //   className='px-1' noBorders
            //   //style={{ height: 10 }}
            //   onClick={(phase) => onSelectTask(phase, item)}>
            //   <MDBIcon
            //     fas
            //     icon="tasks"
            //     style={{ marginRight: 5 }}
            //   /> {item?.name}
            // </MDBListGroupItem>
            <div
              id={`${idx}-${item?.id}`}
              title={`Task : ${item?.name}, Description ${item?.description}`}
              color={taskInfoId === item?.id ? "success" : "transparent"}
              className=" truncate max-w-[175px]"
              onClick={(phase) => onSelectTask(phase, item)}
            >
              <MDBIcon
                fas
                icon="tasks"
                style={{ margin: 8 }}
              /> {item?.name}
            </div>
          );
        })}
      </MDBListGroup>
    );
  };

  const renderControlShowCheckList = () => {
    if (userRole === userRoles.Admin || phase?.isPM) {
      return (
        <MDBIcon
          fas
          icon="chalkboard-teacher"
          onClick={() => onShowAllCheckListOfPhase(phase)}
          style={{ marginLeft: 10, marginTop: -5 }}
        />
      );
    }

    return (
      <MDBIcon
        fas
        icon="list-ol"
        style={{ cursor: "pointer", marginLeft: 10, marginTop: -5 }}
        title="Show check list of team"
        onClick={() => onShowCheckListOfPhase(phase)}
      />
    );
  };

  const renderBody = () => {
    let phaseIcon = currentId === phase.id ? "" : <MDBIcon fas icon="calendar-alt" style={{ marginRight: 15 }} />

    if ((phase?.taskMenus ?? []).length < 1) {
      return <div onClick={() => onSelectPhase(phase)}
        style={{ cursor: "pointer", padding: 15 }}
        className=" truncate max-w-[125px]">
        <MDBCardBody>
          {phaseIcon}
          {phase?.name} {currentId === phase.id && renderControlShowCheckList()}
        </MDBCardBody>

      </div>
    }

    return <MDBAccordionItem collapseId={phase?.id} headerTitle={<>
      {phaseIcon}
      <div className=" truncate max-w-[275px]">{phase?.name}</div>
      <MDBIcon fas icon="eye"
        onClick={() => onSelectPhase(phase)}
        style={{ cursor: "pointer", borderRadius: 16, marginLeft: 10, marginRight: 10 }} />
      {currentId === phase.id && renderControlShowCheckList()}
    </>} title={phase?.name}>
      {renderTaskMenus(phase?.taskMenus ?? [])}
    </MDBAccordionItem>
  }

  return renderBody();

}
export default PhaseMenuItem;
