import {
  MDBAccordion,
  MDBAccordionItem,
  MDBBadge,
  MDBBtn,
  MDBCol,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
} from "mdb-react-ui-kit";
import { useEffect } from "react";
import { isTokenExpired } from "../utils/tokenHelper";
import { useNavigate } from "react-router-dom";
import { first, sumBy } from "lodash";
import "./modal.css";
import { dateFormatDMY } from "../utils/utilities";

function ViewConfirmTimesheet({
  date,
  timesheets,
  onSave,
  onCloseModal,
}) {
  const navigate = useNavigate();

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
  }, []);

  const renderBody = () => {
    let items = (timesheets ?? []).filter((ts) => (+ts?.overTime ?? 0) + (+ts?.normalTime ?? 0) > 0);
    if (items?.length < 1) return <h2>Don't have any input timesheets</h2>;

    let projectIds = [...new Set((items ?? []).map(item => item?.projectId ?? 0))];
    let firstTeam = first(projectIds ?? []);

    return <>
      <MDBAccordion alwaysOpen initialActive={firstTeam ?? 1} key={`TsPro-cfdata`}>
        {(projectIds ?? []).map((projectId, idx) => {
          let data = (items ?? []).filter((item) => item?.projectId === projectId) ?? [];
          let firstItem = first(data);
          return <MDBAccordionItem collapseId={projectId} headerTitle={<>
            <MDBIcon fab icon="product-hunt" style={{ marginRight: 10 }} /> {` ${firstItem?.projectName} (${(data ?? []).length})`}
          </>}
          >
            {renderPhaseList(projectId, data ?? [], idx)}
          </MDBAccordionItem>
        })}
      </MDBAccordion >
    </>
  };

  const renderPhaseList = (projectId, phaseItems, idx) => {
    let phaseIds = [...new Set((phaseItems ?? []).map(item => item?.phaseId ?? 0))];
    let firstPhase = first(phaseIds ?? []);
    return <MDBAccordion alwaysOpen initialActive={firstPhase ?? 1} key={`Ts${projectId}Phase-cfdata-${idx}`}>
      {(phaseIds ?? []).map((phaseId) => {
        let data = (phaseItems ?? []).filter((item) => item?.phaseId === phaseId) ?? [];
        let firstItem = first(data);

        let totalHours = sumBy(data ?? [], "normalTime");
        let otHours = sumBy(data ?? [], "overTime");
        return <MDBAccordionItem collapseId={phaseId} headerTitle={
          <>
            <div className=" truncate max-w-[275px]" style={{ marginRight: 25 }}>{`${firstItem?.phaseName} `} </div>
            {`Normal (${totalHours}) - OT (${otHours})`}
          </>
        }>
          {(data ?? []).map((task) => {
            return <MDBRow>
              <MDBCol size="3" sm="3" title={`Task name: ${task?.taskName}`} className=" truncate max-w-[175px]">
                {task?.taskName}
              </MDBCol>
              <MDBCol size="3" sm="3" title={`SubTask: ${task?.subTaskName}`} className=" truncate max-w-[175px]">
                {task?.subTaskName}
              </MDBCol>
              <MDBCol size="3" sm="3" title={`SubTask: ${task?.subTaskName}`}>
                <MDBBadge pill color="info" title={"Normal Hours"}>
                  {task?.normalTime ?? 0}
                </MDBBadge>
                <MDBBadge pill color="warning" title={"OT Hours"}>
                  {task?.overTime ?? 0}
                </MDBBadge>
              </MDBCol>
              <MDBCol size="3" sm="3" title={`Note: ${task?.tsNote}`} className=" truncate max-w-[175px]">
                {task?.tsNote}
              </MDBCol>
            </MDBRow>
          })}
        </MDBAccordionItem>
      })}
    </MDBAccordion>
  }

  return (
    <MDBModal open={true} onClose={onCloseModal} tabIndex="-1">
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle className=" truncate max-w-[300px]">
              Timesheet by date {dateFormatDMY(date)}
            </MDBModalTitle>
            <MDBBadge
              pill
              className="mx-2"
              color="info"
              light
              style={{ alignItems: "flex-end", marginLeft: 25 }}
            >
              {timesheets?.length}
            </MDBBadge>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={onCloseModal}
            ></MDBBtn>
          </MDBModalHeader>

          <MDBModalBody>
            <div className="divScroll">{renderBody()}</div>
          </MDBModalBody>

          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={onCloseModal}>
              Close
            </MDBBtn>
            <MDBBtn onClick={() => onSave()}>Save</MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}

export default ViewConfirmTimesheet;
