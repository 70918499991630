import React, { useState } from "react";
import {
  MDBAccordion,
  MDBBtn,
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBRow,
} from "mdb-react-ui-kit";
import "./Project.css";
import ProjectInfo from "./ProjectInfo";
import CreateProject from "../../modals/CreateProject";
import { orderBy } from "lodash";
import { userRoles } from "../../utils/utilities";

function PhaseInfo({
  isSideBarOpen,
  setIsSideBarOpen,
  userRole,
  projects,
  statuses,
  projectId,
  currentPhaseId,
  taskInfoId,
  dataToday,
  onSelectProject,
  onSelectPhase,
  onSaveProject,
  onSelectTask,
  onShowCheckListOfPhase,
  onShowAllCheckListOfPhase,
  onSelectTodayTask
}) {
  const [isBoardModalOpen, setIsBoardModalOpen] = useState(false);
  const [searchProject, setSearchProject] = useState("");
  // const [colorTheme, setTheme] = useDarkMode();

  // const [darkSide, setDarkSide] = useState(
  //   colorTheme === "light" ? true : false
  // );

  const renderAddNewProject = () => {
    if (userRole !== userRoles.Admin && userRole !== "Manager") {
      return <></>;
    }
    return (
      <h5 className=" dark:text-gray-300 text-gray-600 font-semibold mx-2 mb-2 ">
        <MDBBtn
          style={{ cursor: "pointer" }}
          onClick={() => setIsBoardModalOpen(true)}
          title="Add new a project"
        >
          <MDBIcon fas icon="plus" /> New project
        </MDBBtn>
      </h5>
    );
  };

  const renderIconNewProject = () => {
    if (userRole !== userRoles.Admin && userRole !== "Manager") {
      return <></>;
    }

    return (
      <MDBIcon
        fas
        icon="plus"
        color="primary"
        style={{ cursor: "pointer", alignItems: "flex-end" }}
        onClick={() => setIsBoardModalOpen(true)}
        title="Add new a project"
      />
    );
  };

  const renderMyTasks = () => {
    let items = (dataToday ?? []).filter((it) => it.taskId > 0 && it.staffId > 0 && it.statusName !== "Done") ?? [];
    if (items.length < 1) return <></>

    return <h5 className=" dark:text-gray-300 text-gray-600 font-semibold mx-2 mb-2 ">
      <MDBRow
        style={{ marginTop: 5, marginBottom: 20 }}
        title={`My tasks (${items?.length}) `}
      >
        <MDBCol size="3" sm="8" className=" truncate max-w-[200px]"
          onClick={() => onSelectTodayTask()}>
          {`Today (${items?.length} tasks)`}
        </MDBCol>
      </MDBRow>
    </h5>
  }

  const renderTitleBoard = () => {
    if (projects?.length < 1) return renderAddNewProject();

    return (
      <h5 className=" dark:text-gray-300 text-gray-600 font-semibold mx-2 mb-2 ">
        <MDBRow
          style={{ marginTop: 5 }}
          title={`Projects (${projects?.length})`}
        >
          <MDBCol size="3" sm="8" className=" truncate max-w-[200px]">
            Projects ({projects?.length})
          </MDBCol>
          <MDBCol size="3" sm="2">
            {renderIconNewProject()}
          </MDBCol>
          <MDBCol size="3" sm="2">
            <MDBIcon
              fas
              icon="angle-double-left"
              style={{ cursor: "pointer", alignItems: "flex-end" }}
              title={"Hide menu"}
              onClick={() => setIsSideBarOpen(false)}
            />
          </MDBCol>
        </MDBRow>
      </h5>
    );
  };

  const renderMainBody = () => {
    let items = (projects ?? [])
      .filter((item) => (item.name ?? "")
        .toLowerCase()
        .includes(searchProject ?? ""));

    let dataHighlight = (items ?? []).filter((it) => it.highlight)
    let dataNotHighlight = (items ?? []).filter((it) => !it.highlight)

    let rs = [];
    orderBy(dataHighlight ?? [], "priorityNumber").map((it) => rs.push(it));
    orderBy(dataNotHighlight ?? [], "priorityNumber").map((it) => rs.push(it));

    return (
      <div className="divScrollMenu">
        <div className="  dropdown-board flex flex-col h-[10vh]  justify-between ">
          <div>
            <MDBInput
              className="mb-3"
              type="text"
              label="Search project"
              value={searchProject}
              onChange={(evt) => setSearchProject((evt.target.value ?? "").toLowerCase())}
            />
            <MDBAccordion alwaysOpen initialActive={1}>
              {rs.map((project, index) => (
                <div
                  className={` flex items-baseline space-x-2 px-1 mr-2 rounded-r-full duration-500 ease-in-out py-1 cursor-pointer hover:bg-[#635fc71a] hover:text-[#635fc7] dark:hover:bg-white dark:hover:text-[#635fc7] dark:text-white  ${project.isActive &&
                    " bg-[#635fc7] rounded-r-full text-white mr-1 "
                    } `}
                  key={index}
                >
                  <ProjectInfo
                    project={project}
                    index={index + 1}
                    projectId={projectId}
                    currentPhaseId={currentPhaseId}
                    taskInfoId={taskInfoId}
                    userRole={userRole}
                    onSelectPhase={(item) => onSelectPhase(project, item)}
                    onSelectTask={(phaseInfo, item) => onSelectTask(project, phaseInfo, item)}
                    onSelectProject={onSelectProject}
                    onShowCheckListOfPhase={onShowCheckListOfPhase}
                    onShowAllCheckListOfPhase={onShowAllCheckListOfPhase}
                  />
                </div>
              )
              )}
            </MDBAccordion>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div
        className={
          isSideBarOpen
            ? `min-w-[261px] bg-white dark:bg-[#2b2c37]  fixed top-[68px] h-screen  items-center left-0 z-20`
            : ` bg-[#635FC7] dark:bg-[#2b2c37] dark:hover:bg-[#635FC7] top-auto bottom-10 justify-center items-center hover:opacity-80 cursor-pointer  p-0 transition duration-300 transform fixed flex w-[56px] h-[48px] rounded-r-full  `
        }
      >
        <div
          className=" scrollbar-hide overflow-y-scroll h-[85vh]"
          onClick={() => !isSideBarOpen && setIsSideBarOpen(true)}
          title={isSideBarOpen ? "Hide menu" : "Show menu"}
        >
          {isSideBarOpen && (
            <div className=" bg-white  dark:bg-[#2b2c37] w-full py-1 rounded-xl">
              {renderMyTasks()}
              {renderTitleBoard()}
              {renderMainBody()}
            </div>
          )}
        </div>

        {!isSideBarOpen && (
          <MDBIcon
            fas
            icon="angle-double-right"
            title={"Show menu"}
            onClick={() => setIsSideBarOpen(true)}
          />
        )}
      </div>

      {isBoardModalOpen && (
        <CreateProject
          statuses={statuses ?? []}
          onCloseModal={() => setIsBoardModalOpen(false)}
          onSave={(data) => onSaveProject(data)}
        />
      )}
    </div>
  );
}

export default PhaseInfo;
