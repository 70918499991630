import React from "react";
import Chart from "react-apexcharts";

function ApexPie({ labels, series }) {
  const dataOptions = () => {
    return {
      chart: {
        width: 380,
        type: "pie",
      },
      labels: labels ?? [],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    };
  };

  return (
    <>
      <Chart
        options={dataOptions()}
        series={series ?? []}
        type="pie"
        height={350}
      />
    </>
  );
}

export default ApexPie;
