import React, { useState } from "react";
import { MDBBtn, MDBCol, MDBIcon, MDBInput, MDBRow } from "mdb-react-ui-kit";

function InputCheckList({
  myTeamId,
  displayName,
  parentId,
  listType,
  onlyModify,
  isLeader,
  handleSaveData,
  handleModifyData,
  handleDeleteData,
}) {
  const [showInput, setShowInput] = useState(false);
  const [name, setName] = useState();
  const [isModify, setIsModify] = useState(false);

  const handleAdd = () => {
    setShowInput(true);
    setIsModify(false);
  };

  const handleEdit = () => {
    setName(displayName);
    setShowInput(true);
    setIsModify(true);
  };

  const handleDelete = () => {
    setShowInput(false);
    handleDeleteData();
  };

  const saveData = () => {
    let data = {
      teamId: myTeamId,
      parentId: parentId,
      name: name,
      listType: isModify ? listType - 1 : listType,
    };

    isModify ? handleModifyData(data) : handleSaveData(data);
    setShowInput(false);
  };

  const renderControlInput = () => {
    return (
      <MDBRow style={{ margin: 15 }}>
        <MDBCol size="3" sm="1">
          {!isModify && (
            <MDBIcon fas icon="file-alt" style={{ alignItems: "end" }} />
          )}
        </MDBCol>
        <MDBCol size="3" sm="8">
          <MDBInput
            type="text"
            label="Name"
            value={name}
            onChange={(evt) => setName(evt.target.value)}
          />
        </MDBCol>
        <MDBCol sm="3">
          <MDBBtn onClick={() => saveData()}>
            <MDBIcon fas icon="save" />
          </MDBBtn>
          <MDBBtn
            onClick={() => setShowInput(false)}
            style={{ marginLeft: 10 }}
            color="warning"
          >
            <MDBIcon fas icon="ban" />
          </MDBBtn>
        </MDBCol>
      </MDBRow>
    );
  };

  return (
    <>
      <MDBRow>
        <MDBCol
          sm="8"
          style={{ textAlign: "left" }}
          className=" truncate max-w-[450px]"
        >
          {displayName}
        </MDBCol>
        <MDBCol sm="3">
          {isLeader && <MDBIcon
            fas
            icon="edit"
            color="info"
            onClick={() => handleEdit()}
            style={{ marginRight: 15 }}
            title={`Update checklist ${displayName}`}
          />}

          {isLeader && !onlyModify && (
            <MDBIcon
              fas
              icon="plus"
              color="primary"
              onClick={() => handleAdd()}
              title={`Add new sub checklist ${displayName}`}
            />
          )}

          {isLeader && <MDBIcon
            fas
            icon="trash"
            style={{ marginLeft: 15 }}
            onClick={() => handleDelete()}
            color="warning"
            title={`Delete checklist ${displayName}`}
          />}
        </MDBCol>
      </MDBRow>
      {showInput && renderControlInput()}
    </>
  );
}

export default InputCheckList;
