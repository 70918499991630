import { MDBIcon } from "mdb-react-ui-kit";
import { defaultBoxMinWidth } from "../utils/utilities";

function CtrlDisplayExtentDetail({ tabWidth, handleViewTaskDetail, handleMinTaskDetail }) {
  const renderExtentDetail = () => {
    if ((tabWidth ?? defaultBoxMinWidth) <= defaultBoxMinWidth) {
      return <MDBIcon
        fas
        icon="external-link-alt"
        title="External view"
        onClick={handleViewTaskDetail}
        style={{ cursor: "pointer" }}
      />
    }

    return <MDBIcon fas icon="minus-square"
      onClick={handleMinTaskDetail}
      title="Minimize view"
      style={{ cursor: "pointer" }} />
  }

  return renderExtentDetail();
}

export default CtrlDisplayExtentDetail;
