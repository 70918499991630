import "react-toastify/dist/ReactToastify.css";

function ViewBoardItem({ ctrlAccordion, id, handleOnDrag, colWidth }) {
  return <div
    draggable
    id={id}
    onDragStart={handleOnDrag}
    style={{ width: colWidth ?? 275, marginBottom: 2, marginRight: 5 }}
  >
    {ctrlAccordion}
  </div>
}

export default ViewBoardItem;
