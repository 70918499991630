import React, { } from "react";

import {
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBIcon,
  MDBNavbarItem,
  MDBNavbarLink,
} from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";

function AdminMenu({ pcIcon }) {
  const navigate = useNavigate();

  const renderMenuPC = () => {
    return <>
      <MDBNavbarItem>
        <MDBNavbarLink onClick={() => navigate("/projects")}>
          <MDBIcon fas icon="city" style={{ marginRight: 15 }} />
          {pcIcon && "Project Board"}
        </MDBNavbarLink>
      </MDBNavbarItem>
      <MDBNavbarItem>
        <MDBDropdown style={{ backgroundColor: "white" }}>
          <MDBDropdownToggle tag="a" className="nav-link" role="button">
            <MDBIcon fas icon="th" style={{ marginRight: 15 }} />
            {pcIcon && "Settings"}
          </MDBDropdownToggle>
          <MDBDropdownMenu light>
            <MDBDropdownItem divider />
            <MDBDropdownItem onClick={() => navigate("/members")}>
              <MDBNavbarLink onClick={() => navigate("/members")}>
                <MDBIcon
                  fas
                  icon="user-friends"
                  style={{ marginRight: 15 }}
                />
                {pcIcon && "List of Users"}
              </MDBNavbarLink>
            </MDBDropdownItem>
            <MDBDropdownItem divider />
            <MDBDropdownItem onClick={() => navigate("/teams")}>
              <MDBNavbarLink onClick={() => navigate("/teams")}>
                <MDBIcon fas icon="users" style={{ marginRight: 15 }} />
                {pcIcon && "Team members"}
              </MDBNavbarLink>
            </MDBDropdownItem>
            <MDBDropdownItem divider />
            <MDBDropdownItem onClick={() => navigate("/my-projects")}>
              <MDBNavbarLink onClick={() => navigate("/my-projects")}>
                <MDBIcon
                  fas
                  icon="grip-horizontal"
                  style={{ marginRight: 15 }}
                />
                {pcIcon && "List of Projects"}
              </MDBNavbarLink>
            </MDBDropdownItem>
            <MDBDropdownItem divider />
            <MDBDropdownItem onClick={() => navigate("/check-list")}>
              <MDBNavbarLink onClick={() => navigate("/check-list")}>
                <MDBIcon fas icon="th-list" style={{ marginRight: 15 }} />
                {pcIcon && "Checklist"}
              </MDBNavbarLink>
            </MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>
      </MDBNavbarItem>
      <MDBNavbarItem light>
        <MDBDropdown style={{ backgroundColor: "white" }}>
          <MDBDropdownToggle tag="a" className="nav-link" role="button">
            <MDBIcon fas icon="desktop" style={{ marginRight: 15 }} />
            {pcIcon && "Project Planning"}
          </MDBDropdownToggle>
          <MDBDropdownMenu light>
            <MDBDropdownItem divider />
            <MDBDropdownItem onClick={() => navigate("/resources")}>
              <MDBNavbarLink onClick={() => navigate("/resources")}>
                <MDBIcon fas icon="users" style={{ marginRight: 15 }} />
                {pcIcon && "Resources"}
              </MDBNavbarLink>
            </MDBDropdownItem>
            <MDBDropdownItem divider />
            <MDBDropdownItem onClick={() => navigate("/assign-projects")}>
              <MDBNavbarLink onClick={() => navigate("/assign-projects")}>
                <MDBIcon
                  fas
                  icon="user-ninja"
                  style={{ marginRight: 15 }}
                />
                {pcIcon && "Assign to PM"}
              </MDBNavbarLink>
            </MDBDropdownItem>
            <MDBDropdownItem divider />
            <MDBDropdownItem onClick={() => navigate("/time-sheets")}>
              <MDBNavbarLink onClick={() => navigate("/time-sheets")}>
                <MDBIcon
                  fas
                  icon="calendar-times"
                  style={{ marginRight: 15 }}
                />
                {pcIcon && "View Time sheet"}
              </MDBNavbarLink>
            </MDBDropdownItem>
            <MDBDropdownItem divider />
            <MDBDropdownItem onClick={() => navigate("/contracts")}>
              <MDBNavbarLink onClick={() => navigate("/contracts")}>
                <MDBIcon
                  fas
                  icon="file-signature"
                  style={{ marginRight: 15 }}
                />
                {pcIcon && "List of Contracts"}
              </MDBNavbarLink>
            </MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>
      </MDBNavbarItem>
      <MDBNavbarItem>
        <MDBDropdown style={{ backgroundColor: "white" }}>
          <MDBDropdownToggle tag="a" className="nav-link" role="button">
            <MDBIcon fas icon="money-bill-alt" style={{ marginRight: 15 }} />
            {pcIcon && "Bills"}
          </MDBDropdownToggle>
          <MDBDropdownMenu light>
            <MDBDropdownItem divider />
            <MDBDropdownItem onClick={() => navigate("/companies")}>
              <MDBNavbarLink onClick={() => navigate("/companies")}>
                <MDBIcon
                  fas
                  icon="building"
                  style={{ marginRight: 15 }}
                />
                {pcIcon && "List of Customers"}
              </MDBNavbarLink>
            </MDBDropdownItem>
            <MDBDropdownItem divider />
            <MDBDropdownItem onClick={() => navigate("/budgets")}>
              <MDBNavbarLink onClick={() => navigate("/budgets")}>
                <MDBIcon
                  fas
                  icon="money-check-alt"
                  style={{ marginRight: 15 }}
                />
                {pcIcon && "List of Budgets"}
              </MDBNavbarLink>
            </MDBDropdownItem>
            <MDBDropdownItem divider />
            <MDBDropdownItem onClick={() => navigate("/planning")}>
              <MDBNavbarLink onClick={() => navigate("/planning")}>
                <MDBIcon
                  fas
                  icon="file-invoice-dollar"
                  style={{ marginRight: 15 }}
                />
                {pcIcon && "Calculate Budgets"}
              </MDBNavbarLink>
            </MDBDropdownItem>
            <MDBDropdownItem divider />
            <MDBDropdownItem onClick={() => navigate("/bills")}>
              <MDBNavbarLink onClick={() => navigate("/bills")}>
                <MDBIcon
                  fas
                  icon="file-invoice"
                  style={{ marginRight: 15 }}
                />
                {pcIcon && "List of Bills"}
              </MDBNavbarLink>
            </MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>
      </MDBNavbarItem>
    </>
  }

  const renderIconOnly = () => {
    return <>
      <MDBNavbarItem>
        <MDBNavbarLink onClick={() => navigate("/projects")}>
          <MDBIcon fas icon="city" style={{ marginRight: 15 }} />
          {pcIcon && "Project Board"}
        </MDBNavbarLink>
      </MDBNavbarItem>
      <MDBNavbarItem>
        <MDBDropdown style={{ backgroundColor: "white" }}>
          <MDBDropdownToggle tag="a" className="nav-link" role="button">
            <MDBIcon fas icon="th" style={{ marginRight: 15 }} />
            {pcIcon && "Settings"}
          </MDBDropdownToggle>
          <MDBDropdownMenu light>
            <MDBDropdownItem divider />
            <MDBDropdownItem onClick={() => navigate("/members")}>
              <MDBNavbarLink onClick={() => navigate("/members")}>
                <MDBIcon
                  fas
                  icon="user-friends"
                  style={{ marginRight: 15 }}
                />
                {pcIcon && "List of Users"}
              </MDBNavbarLink>
            </MDBDropdownItem>
            <MDBDropdownItem divider />
            <MDBDropdownItem onClick={() => navigate("/teams")}>
              <MDBNavbarLink onClick={() => navigate("/teams")}>
                <MDBIcon fas icon="users" style={{ marginRight: 15 }} />
                {pcIcon && "Team members"}
              </MDBNavbarLink>
            </MDBDropdownItem>
            <MDBDropdownItem divider />
            <MDBDropdownItem onClick={() => navigate("/my-projects")}>
              <MDBNavbarLink onClick={() => navigate("/my-projects")}>
                <MDBIcon
                  fas
                  icon="grip-horizontal"
                  style={{ marginRight: 15 }}
                />
                {pcIcon && "List of Projects"}
              </MDBNavbarLink>
            </MDBDropdownItem>
            <MDBDropdownItem divider />
            <MDBDropdownItem onClick={() => navigate("/check-list")}>
              <MDBNavbarLink onClick={() => navigate("/check-list")}>
                <MDBIcon fas icon="th-list" style={{ marginRight: 15 }} />
                {pcIcon && "Checklist"}
              </MDBNavbarLink>
            </MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>
      </MDBNavbarItem>
      <MDBNavbarItem light>
        <MDBDropdown style={{ backgroundColor: "white" }}>
          <MDBDropdownToggle tag="a" className="nav-link" role="button">
            <MDBIcon fas icon="desktop" style={{ marginRight: 15 }} />
            {pcIcon && "Project Planning"}
          </MDBDropdownToggle>
          <MDBDropdownMenu light>
            <MDBDropdownItem divider />
            <MDBDropdownItem onClick={() => navigate("/resources")}>
              <MDBNavbarLink onClick={() => navigate("/resources")}>
                <MDBIcon fas icon="users" style={{ marginRight: 15 }} />
                {pcIcon && "Resources"}
              </MDBNavbarLink>
            </MDBDropdownItem>
            <MDBDropdownItem divider />
            <MDBDropdownItem onClick={() => navigate("/assign-projects")}>
              <MDBNavbarLink onClick={() => navigate("/assign-projects")}>
                <MDBIcon
                  fas
                  icon="user-ninja"
                  style={{ marginRight: 15 }}
                />
                {pcIcon && "Assign to PM"}
              </MDBNavbarLink>
            </MDBDropdownItem>
            <MDBDropdownItem divider />
            <MDBDropdownItem onClick={() => navigate("/time-sheets")}>
              <MDBNavbarLink onClick={() => navigate("/time-sheets")}>
                <MDBIcon
                  fas
                  icon="calendar-times"
                  style={{ marginRight: 15 }}
                />
                {pcIcon && "View Time sheet"}
              </MDBNavbarLink>
            </MDBDropdownItem>
            <MDBDropdownItem divider />
            <MDBDropdownItem onClick={() => navigate("/contracts")}>
              <MDBNavbarLink onClick={() => navigate("/contracts")}>
                <MDBIcon
                  fas
                  icon="file-signature"
                  style={{ marginRight: 15 }}
                />
                {pcIcon && "List of Contracts"}
              </MDBNavbarLink>
            </MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>
      </MDBNavbarItem>
      <MDBNavbarItem>
        <MDBDropdown style={{ backgroundColor: "white" }}>
          <MDBDropdownToggle tag="a" className="nav-link" role="button">
            <MDBIcon fas icon="money-bill-alt" style={{ marginRight: 15 }} />
            {pcIcon && "Bills"}
          </MDBDropdownToggle>
          <MDBDropdownMenu light>
            <MDBDropdownItem divider />
            <MDBDropdownItem onClick={() => navigate("/companies")}>
              <MDBNavbarLink onClick={() => navigate("/companies")}>
                <MDBIcon
                  fas
                  icon="building"
                  style={{ marginRight: 15 }}
                />
                {pcIcon && "List of Customers"}
              </MDBNavbarLink>
            </MDBDropdownItem>
            <MDBDropdownItem divider />
            <MDBDropdownItem onClick={() => navigate("/budgets")}>
              <MDBNavbarLink onClick={() => navigate("/budgets")}>
                <MDBIcon
                  fas
                  icon="money-check-alt"
                  style={{ marginRight: 15 }}
                />
                {pcIcon && "List of Budgets"}
              </MDBNavbarLink>
            </MDBDropdownItem>
            <MDBDropdownItem divider />
            <MDBDropdownItem onClick={() => navigate("/planning")}>
              <MDBNavbarLink onClick={() => navigate("/planning")}>
                <MDBIcon
                  fas
                  icon="file-invoice-dollar"
                  style={{ marginRight: 15 }}
                />
                {pcIcon && "Calculate Budgets"}
              </MDBNavbarLink>
            </MDBDropdownItem>
            <MDBDropdownItem divider />
            <MDBDropdownItem onClick={() => navigate("/bills")}>
              <MDBNavbarLink onClick={() => navigate("/bills")}>
                <MDBIcon
                  fas
                  icon="file-invoice"
                  style={{ marginRight: 15 }}
                />
                {pcIcon && "List of Bills"}
              </MDBNavbarLink>
            </MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>
      </MDBNavbarItem>
    </>
  }

  return renderMenuPC();// pcIcon ? renderMenuPC() : renderIconOnly();
}

export default AdminMenu;
