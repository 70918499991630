import React, { useState } from "react";
import {
  MDBCheckbox,
  MDBCol,
  MDBRow,
  MDBSwitch,
} from "mdb-react-ui-kit";
import "./Project.css";
import { newGuid } from "../../utils/utilities";

function CheckListItem({ isChecked, useCheckBox, displayName, handleChange }) {
  const [showCheckList, setShowCheckList] = useState(isChecked);

  const handleSwitchCheckDefault = (checked) => {
    setShowCheckList(checked);
    handleChange(checked);
  };

  const renderBody = () => {
    if (useCheckBox) return <>
      <MDBRow title={displayName} style={{ textAlign: "left" }}>
        <MDBCol size="3" sm="9" className=" truncate max-w-[650px]">
          {displayName}
        </MDBCol>
        <MDBCol size="3" sm="3">
          <MDBCheckbox
            id='controlledCheckbox'
            checked={showCheckList}
            style={{ cursor: "pointer" }}
            onChange={(e) => handleSwitchCheckDefault(e.target.checked)}
          />
        </MDBCol>

      </MDBRow>
    </>

    return <MDBRow title={displayName} style={{ textAlign: "left" }}>
      <MDBCol size="3" sm="1">
        <MDBSwitch
          id={newGuid()}
          checked={showCheckList}
          style={{ cursor: "pointer" }}
          onChange={(e) => handleSwitchCheckDefault(e.target.checked)}
        />
      </MDBCol>
      <MDBCol size="3" sm="10" className=" truncate max-w-[650px]">
        {displayName}
      </MDBCol>
    </MDBRow>
  }

  return renderBody();
}

export default CheckListItem;
