import {
  MDBAccordion,
  MDBAccordionItem,
  MDBBtn,
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
  MDBTextArea,
} from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { isTokenExpired } from "../utils/tokenHelper";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import CtrStatusInfo from "../pages/Projects/CtrStatusInfo";
import CtrPlanCompleted from "../pages/Projects/CtrPlanCompleted";

function EditProject({ projectInfo, statuses, onCloseModal, onSave }) {

  const navigate = useNavigate();
  const [projectName, setProjectName] = useState(projectInfo?.name ?? "");
  const [statusName, setStatusName] = useState(projectInfo?.statusName ?? "");
  const [statusFinish, setStatusFinish] = useState(projectInfo?.statusFinish);
  const [statusIds, setStatusIds] = useState();
  const [startDate, setStartDate] = useState(projectInfo?.startDate ?? new Date());
  const [endDate, setEndDate] = useState(projectInfo?.endDate ?? new Date());
  const [priorityNumber, setPriorityNumber] = useState(projectInfo?.priorityNumber);
  const [description, setDescription] = useState(projectInfo?.description ?? "");
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
  }, []);

  useEffect(() => {
    setStatusName(projectInfo?.statusName ?? "");
    setStatusFinish(projectInfo?.statusFinish);
    setProjectName(projectInfo?.name ?? "");
    setPriorityNumber(projectInfo?.priorityNumber);
    setDescription(projectInfo?.description ?? "");
    setStartDate(projectInfo?.startDate ?? new Date());
    setEndDate(projectInfo?.endDate ?? new Date());
  }, [projectInfo]);

  const saveData = () => {
    if (projectName?.length < 1) return;

    if (statusName?.length < 1) return;

    let data = checked ? {
      name: projectName,
      startDate: startDate,
      endDate: endDate,
      priorityNumber: priorityNumber,
      description: description,
      statusFinish: statusFinish,
      statusName: statusName,
      statusIds: statusIds,
    } : {
      name: projectName,
      startDate: startDate,
      priorityNumber: priorityNumber,
      description: description,
      statusFinish: statusFinish,
      statusName: statusName,
      statusIds: statusIds,
    }

    onSave(data);
    onCloseModal();
  };

  const renderBody = () => {
    return (
      <>
        <MDBInput
          className="mb-3"
          type="text"
          label="Name"
          value={projectName}
          onChange={(evt) => setProjectName(evt.target.value)}
        />

        <MDBAccordion alwaysOpen initialActive={0} title={`Project: ${projectName} : ${description ?? ""}`}>
          <MDBAccordionItem collapseId={1} headerTitle="Project Information">
            <MDBRow style={{ textAlign: "left" }}>
              <MDBCol size="2" sm="3">
                <MDBInput
                  className="mb-3"
                  type="number"
                  label="Priority"
                  value={priorityNumber}
                  style={{ padding: 5 }}
                  onChange={(evt) => setPriorityNumber(evt.target.value)}
                />
              </MDBCol>
              <MDBCol size="2" sm="9">
                <MDBIcon fas icon={"user-alt"} style={{ marginRight: 10 }} />
                {projectInfo?.assigned}
              </MDBCol>
            </MDBRow>

            <CtrPlanCompleted
              startDate={startDate}
              endDate={endDate}
              onSaveStartDate={(date) => setStartDate(date)}
              onSaveEndDate={(date) => setEndDate(date)}
              onSaveChecked={(isSelected) => setChecked(isSelected)}
            />

            <CtrStatusInfo
              statuses={statuses ?? []}
              curStatusName={statusName}
              curStatusFinish={projectInfo?.statusFinish}
              curStatusNames={projectInfo?.statusNames}
              onSaveStatusName={(name) => setStatusName(name)}
              onSaveStatusIdFinish={(id) => setStatusFinish(id)}
              onSaveStatusIds={(ids) => setStatusIds(ids)}
            />

          </MDBAccordionItem>
          <MDBAccordionItem collapseId={2} headerTitle="Project Description">
            <MDBTextArea
              className="mb-3"
              style={{ marginTop: 20 }}
              label="Description"
              id="textAreaExample"
              rows="{4}"
              value={description ?? ""}
              onChange={(evt) => setDescription(evt.target.value)}
            />
          </MDBAccordionItem>
        </MDBAccordion>


      </>
    );
  };

  return (
    <MDBModal open={true} onClose={onCloseModal} tabIndex="-1" className="projectDialog">
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>Update Project</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={onCloseModal}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>{renderBody()}</MDBModalBody>

          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={onCloseModal}>
              Close
            </MDBBtn>
            <MDBBtn onClick={() => saveData()}>Update Project</MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}

export default EditProject;
