import { MDBBadge } from "mdb-react-ui-kit";
import moment from "moment";
import { dateFormatDMY } from "../utils/utilities";

function DisplayOverdue({ statusName, endDate }) {
  const renderOverdue = () => {
    let st = statusName !== "Done";
    var time1 = moment(endDate ?? new Date()).format('YYYY-MM-DD');
    var time2 = moment(new Date()).format('YYYY-MM-DD');
    let overdue = time1 < time2;
    if (st && overdue) {
      return <MDBBadge pill color="danger" title={`End date ${dateFormatDMY(endDate ?? new Date())}`}>
        Overdue
      </MDBBadge>
    }

    return <></>
  }
  return renderOverdue();
}

export default DisplayOverdue;
