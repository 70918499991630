import { MDBBadge, MDBCol, MDBIcon, MDBRow, MDBTextArea } from "mdb-react-ui-kit";
import moment from "moment";

function CurrentLogTimeStatus({
  statusName,
  estimateHours,
  normalHours,
  overTime,
  startDate,
  endDate,
  assigned,
  hideDate,
  iconStatus,
  hideStatus,
  description
}) {
  const renderPlanCompletedDate = () => {
    let fromDate = !startDate ? "TBD" : moment(startDate).format("DD-MMM");
    let toDate = !endDate ? "TBD" : moment(endDate).format("DD-MMM");

    return (
      <>
        <MDBRow
          style={{ marginTop: 5 }}
          title={`From ${fromDate} => ${toDate}`}
        >
          <MDBCol size="3" sm="2">
            <MDBIcon fas icon="calendar-alt" />
          </MDBCol>
          <MDBCol size="3" sm="5" className=" truncate max-w-[145px]">
            <p className="text-primary">{fromDate}</p>
          </MDBCol>
          <MDBCol size="3" sm="5" className=" truncate max-w-[145px]">
            <p className="text-warning">{toDate}</p>
          </MDBCol>
        </MDBRow>
      </>
    );
  };

  const renderAssigner = () => {
    if ((assigned ?? "").length < 1) return <></>;
    return (
      <MDBRow style={{ marginTop: 15 }}>
        <MDBCol
          size="3"
          sm="12"
          className=" truncate max-w-[250px]"
          title={`${assigned}`}
        >
          <MDBIcon fas icon={"user-alt"} style={{ marginRight: 10 }} />
          {assigned}
        </MDBCol>
      </MDBRow>
    );
  };

  const renderWorkingTime = () => {
    return (
      <MDBRow >
        <MDBCol size="3" sm="4">
          <MDBBadge pill light title={"Estimate Hours"}>
            {estimateHours ?? "N/A"}
          </MDBBadge>
        </MDBCol>
        <MDBCol size="3" sm="4">
          <MDBBadge pill color="info" title={"Normal Hours"}>
            {normalHours ?? 0}
          </MDBBadge>
        </MDBCol>
        <MDBCol size="3" sm="4">
          <MDBBadge pill color="warning" title={"OT Hours"}>
            {overTime ?? 0}
          </MDBBadge>
        </MDBCol>
      </MDBRow>
    );
  };

  const renderStatusAndWorkingTime = () => {
    if (hideStatus || (statusName ?? "").length < 1) {
      return <MDBRow>
        <MDBCol
          size="3"
          sm="2"
          className=" truncate max-w-[150px]"
          title={`Current status ${statusName}`}
        >
          <MDBIcon
            fas
            icon={iconStatus ?? "tasks"}
          />
        </MDBCol>
        <MDBCol size="3" sm="10">
          {renderWorkingTime()}
        </MDBCol>
      </MDBRow>
    }

    return <>
      <MDBRow>
        <MDBCol size="3" sm="2">
        </MDBCol>
        <MDBCol
          size="3"
          sm="10"
          className=" truncate max-w-[250px]"
          title={`Current status ${statusName}`}
        >
          <MDBIcon
            fas
            icon={iconStatus ?? "tasks"}
            style={{ marginRight: 5 }}
          />
          {statusName}
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol
          size="3"
          sm="2"
          className=" truncate max-w-[150px]"
          title={`Current status ${statusName}`}
        >
        </MDBCol>
        <MDBCol size="3" sm="10">
          {renderWorkingTime()}
        </MDBCol>
      </MDBRow>
    </>
  }

  const renderDescription = () => {

    if ((description ?? "").length < 1) return "";

    return <MDBTextArea
      className="mb-3"
      id="textAreaExample"
      rows="{4}"
      style={{ marginTop: 15 }}
      value={description ?? ""}
      readOnly
      disabled
    />
  }

  return (
    <>
      {!hideDate && renderPlanCompletedDate()}
      {renderStatusAndWorkingTime()}
      {renderAssigner()}
      {renderDescription()}

    </>
  );
}

export default CurrentLogTimeStatus;
