import { toNumber } from "lodash";
import { MDBCol, MDBProgress, MDBProgressBar, MDBRow } from "mdb-react-ui-kit";

function DisplayTaskProcessing({ statusName, planHours, workingHours, otHours, displayOverHours }) {

  const renderProcessingInfo = () => {
    let maxVal = toNumber(planHours ?? 0);
    let totalVal = toNumber(workingHours) + toNumber(otHours);
    if ((totalVal ?? 0) < 1) return "";
    let over = 0
    if (totalVal > maxVal) {
      over = (totalVal - maxVal)
    }
    if (maxVal < 1) maxVal = 1;
    let widthOt = (otHours / maxVal) * 100;
    let widthNormal = (workingHours / maxVal) * 100;
    let widthOver = (over / maxVal) * 100;

    let strTitle = `Status is ${statusName} (plan ${planHours} has working ${totalVal} hours)`;
    if (over > 0) {
      strTitle += ` over ${over} (hours)`
    }

    if (statusName === "Done") {
      return <MDBProgress height='20' style={{ marginTop: 9 }} title={strTitle}>
        <MDBProgressBar striped bgColor='info' width={widthOt + widthNormal} valuemin={0} valuemax={100}>
          Working {totalVal} / {planHours} hours
        </MDBProgressBar>
      </MDBProgress>
    }

    return (
      <MDBRow>
        <MDBCol size="9">
          <MDBProgress height='20' style={{ marginTop: 9 }} title={strTitle}>
            <MDBProgressBar bgColor='success' width={widthNormal} valuemin={0} valuemax={100} title={`${workingHours} Hours`}>{workingHours}</MDBProgressBar>
            <MDBProgressBar bgColor='warning' width={widthOt} valuemin={0} valuemax={100} title={`OT: ${otHours} Hours`} >{otHours}</MDBProgressBar>
          </MDBProgress>
        </MDBCol>
        <MDBCol size="3">
          {renderOver(widthOver, over, planHours)}
        </MDBCol>
      </MDBRow>
    );
  }

  const renderOver = (val, over, planHours) => {

    if (val > 0) {
      if (displayOverHours) {
        return <>
          <MDBProgress height='20' style={{ marginTop: 9 }} title={`Over ${over} (hours)`}>
            <MDBProgressBar bgColor='danger' width={100} valuemin={0} valuemax={100}>
              {over}
            </MDBProgressBar>
          </MDBProgress>
        </>
      }

      return <>
        <MDBProgress height='20' style={{ marginTop: 9 }} title={`Over ${over} (hours)`}>
          <MDBProgressBar bgColor='danger' width={100} valuemin={0} valuemax={100}>
            {(val ?? 0).toFixed(0)} %
          </MDBProgressBar>
        </MDBProgress>
      </>
    }

    return <>
      <MDBProgress height='20' style={{ marginTop: 9 }} title={`Estimate ${planHours} (hours)`}>
        <MDBProgressBar width={100} valuemin={0} valuemax={100}>{planHours}</MDBProgressBar>
      </MDBProgress>
    </>
  }
  return renderProcessingInfo();
}

export default DisplayTaskProcessing;
