import { useEffect, useState } from "react";
import Menu from "./Menu";
import NotificationToUser from "./NotificationToUser";

const Layout = ({ children }) => {
  const [myUserId, setMyUserId] = useState(0);
  const [myTeamId, setMyTeamId] = useState(0);
  useEffect(() => {
    var info = window.localStorage.getItem("userInfo") ?? "";
    let infoObj = JSON.parse(info);
    setMyTeamId(+infoObj.teamId);
    setMyUserId(+infoObj.id);
  }, []);


  return (
    <header>
      <Menu hasAvatar />
      <div className="p-3 text-center bg-light">{children}</div>
      <NotificationToUser myUserId={myUserId} myTeamId={myTeamId} />
    </header>
  );
};

export default Layout;
