import { MDBAccordion, MDBAccordionItem, MDBBtn, MDBCol, MDBIcon, MDBInput, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBRow } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { configHeader, isTokenExpired } from "../../utils/tokenHelper";
import { useNavigate } from "react-router-dom";
import { apiEndpoint } from "../../utils/apiEndpoint";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "./Bill.css";
import { first, orderBy, toNumber } from "lodash";
import Multiselect from "multiselect-react-dropdown";
import PhaseAmount from "./PhaseAmount";
import { dateFormatShortDMY, toCommas } from "../../utils/utilities";
import { Label } from "reactstrap";

function CreateNewContract({ onCloseModal, onSave }) {
  const navigate = useNavigate();
  const [projectId, setProjectId] = useState();
  const [companyId, setCompanyId] = useState();
  const [companies, setCompanies] = useState([]);
  const [projects, setProjects] = useState();
  const [phases, setPhases] = useState();
  const [phaseAmounts, setPhaseAmounts] = useState([]);
  const [cashReceipt, setCashReceipt] = useState();
  const [receiptNo, setReceiptNo] = useState();

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
    document.title = "Create an Invoice";
    reloadCompanies();
    reloadProjects();
    setPhaseAmounts([])
  }, []);

  const reloadCompanies = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.companyUrl}`,
        configHeader()
      )
      .then((result) => {
        setCompanies(result?.data ?? []);
      });
  };

  const reloadProjects = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.projectUrl}`,
        configHeader()
      )
      .then((result) => {
        var items = result?.data ?? [];
        setProjects(orderBy(items, "priorityNumber"));
      });
  };

  const options = () => {
    return (companies ?? []).map((p) => {
      return { name: p.name, id: p.id };
    });
  };

  const onSelectedValues = (items) => {
    let ids = (items ?? []).map((it) => it.id);
    let rs = (companies ?? []).filter((p) => ids.some((id) => id === p.id));
    let client = first(rs) ?? {};
    setCompanyId(client?.id);
  };

  const optionProjects = () => {
    return (projects ?? []).map((p) => {
      return { name: p.name, id: p.id };
    });
  };

  const onSelectedProjectValues = (items) => {
    let ids = (items ?? []).map((it) => it.id);
    let rs = (projects ?? []).filter((p) => ids.some((id) => id === p.id));
    let pro = first(rs) ?? {};
    setProjectId(pro?.id);
    setPhases(pro?.phaseInfo);
    setCompanyId(pro?.companyId);
    setPhaseAmounts([])
  };

  const renderGeneralInfomation = () => {
    return <>
      <MDBRow style={{ marginBottom: 15 }}>
        <MDBCol size="3" sm="6">
          <Multiselect
            options={optionProjects()}
            singleSelect
            placeholder="Select a Project"
            onSelect={(items) => onSelectedProjectValues(items)}
            onRemove={(items) => onSelectedProjectValues(items)}
            displayValue="name"
          />
        </MDBCol>
        <MDBCol size="3" sm="6">
          <Multiselect
            options={options()}
            onSelect={(items) => onSelectedValues(items)}
            onRemove={(items) => onSelectedValues(items)}
            displayValue="name"
            placeholder="Select a Client"
            singleSelect
          />
        </MDBCol>
      </MDBRow>
      <MDBRow style={{ marginBottom: 15 }}>
        <MDBCol size="3" sm="6">
          <MDBInput
            className="mb-3"
            type="text"
            label="Contract Number"
            title="Contract Number"
            value={receiptNo}
            onChange={(evt) => setReceiptNo(evt.target.value)}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size="3" sm="6">
          <MDBInput
            className="mb-3"
            type="number"
            id="cashReceipt"
            label="Total Amount"
            value={cashReceipt}
            style={{ padding: 5, textAlign: "right" }}
            onChange={(evt) => setCashReceipt(evt.target.value)}
          />
        </MDBCol>
        <MDBCol size="3" sm="6">
          {toCommas(cashReceipt)}
        </MDBCol>
      </MDBRow>
    </>
  }

  const calTotalPercent = () => {
    if ((phaseAmounts ?? []).length < 1) return "";
    let total = 0;
    (phaseAmounts ?? []).map((it) => total += (+it.contractPercent ?? 0))
    return `${total} %`;
  }

  const saveData = () => {
    let data = {
      "projectId": projectId,
      "companyId": companyId,
      "receiptNo": receiptNo,
      "plannings": phaseAmounts
    }
     
    onSave(data);
  }

  const handleAmountPercent = (phaseId, val, amount, vat, planReceivingDate) => {
    let items = (phaseAmounts ?? []).filter((phase) => phase?.phaseId !== phaseId);
    items.push({
      phaseId: phaseId,
      contractPercent: val,
      contractAmount: amount,
      vat: vat,
      planReceivingDate: planReceivingDate
    });

    setPhaseAmounts(items);
  }

  const renderBody = () => {
    return <div className="divScroll">
      <MDBAccordion alwaysOpen initialActive={1}>
        <MDBAccordionItem collapseId={1} headerTitle={"General information"}>
          {renderGeneralInfomation()}
        </MDBAccordionItem>
        <MDBAccordionItem collapseId={2} headerTitle={<>
          <Label style={{ marginRight: 75 }}>
            Phase details
          </Label>
          {`${calTotalPercent()}`}
        </>}>
          <MDBAccordion alwaysOpen initialActive={1}>
            {(phases ?? []).map((item) => {
              let phaseAmount = (phaseAmounts ?? []).filter((phase) => phase?.phaseId === item?.id);
              let fisrtItem = first(phaseAmount ?? [])
              return <MDBAccordionItem collapseId={item?.id} headerTitle={<>
                <Label style={{ marginRight: 25, width: 365 }} className=" truncate max-w-[350px]">
                  {item?.name}
                </Label>
                {fisrtItem?.planReceivingDate && dateFormatShortDMY(fisrtItem?.planReceivingDate)}
                <Label style={{ marginLeft: 15 }}>
                  {toCommas(toNumber(fisrtItem?.contractAmount ?? 0) + toNumber(fisrtItem?.vat ?? 0))}
                </Label>
              </>}
              >
                <PhaseAmount
                  phaseInfo={item}
                  cashReceipt={cashReceipt}
                  onAmountPercent={(val, amount, vat, planReceivingDate) =>
                    handleAmountPercent(item?.id, val, amount, vat, planReceivingDate)}
                />
              </MDBAccordionItem>
            })}
          </MDBAccordion>
        </MDBAccordionItem>
      </MDBAccordion >
    </div >
  }

  return <MDBModal
    open={true}
    onClose={() => onCloseModal()}
    tabIndex="-1"
    className="billDialog"
  >
    <MDBModalDialog>
      <MDBModalContent>
        <MDBModalHeader>
          <MDBModalTitle>Contract information</MDBModalTitle>
          <MDBBtn
            className="btn-close"
            color="none"
            onClick={() => onCloseModal()}
          ></MDBBtn>
        </MDBModalHeader>
        <MDBModalBody>{renderBody()}</MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="secondary" onClick={() => onCloseModal()}>
            Close
          </MDBBtn>
          <MDBBtn onClick={() => saveData()}> <MDBIcon fas icon="save" /> Save</MDBBtn>
        </MDBModalFooter>
      </MDBModalContent>
    </MDBModalDialog>
  </MDBModal>
}

export default CreateNewContract;
