import { MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import moment from "moment";

function PlanCompletedDate({ startDate, endDate, formatDMY }) {
    const renderPlanCompletedDate = () => {
        let fromDate = !startDate ? "TBD" : moment(startDate).format(formatDMY ?? "DD-MMM");
        let toDate = !endDate ? "TBD" : moment(endDate).format(formatDMY ?? "DD-MMM");
        return (
            <>
                <MDBRow
                    title={`From ${fromDate} => ${toDate}`}
                >
                    <MDBCol size="3" sm="2">
                        <MDBIcon fas icon="calendar-alt" />
                    </MDBCol>
                    <MDBCol size="3" sm="5" className=" truncate max-w-[145px]">
                        <p className="text-primary">{fromDate}</p>
                    </MDBCol>
                    <MDBCol size="3" sm="5" className=" truncate max-w-[145px]">
                        <p className="text-warning">{toDate}</p>
                    </MDBCol>
                </MDBRow>
            </>
        );
    };

    return renderPlanCompletedDate();
}

export default PlanCompletedDate;