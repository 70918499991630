import { MDBCheckbox, MDBCol, MDBRow } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { newGuid } from "../../utils/utilities";

function CtrPlanCompleted({ startDate, endDate, onSaveStartDate, onSaveEndDate, onSaveChecked, hasEndDate }) {

  const [fromDate, setFromDate] = useState(startDate ?? new Date());
  const [toDate, setToDate] = useState(endDate ?? new Date());
  const [checked, setChecked] = useState(hasEndDate || endDate !== null);

  useEffect(() => {
    let isChecked = hasEndDate || endDate !== null
    setChecked(isChecked);
    onSaveChecked(isChecked);
  }, [hasEndDate, endDate]);

  const handleChangeStartDate = (date) => {
    setFromDate(date);
    onSaveStartDate(date);
  }

  const handleChangeEndDate = (date) => {
    setToDate(date);
    onSaveEndDate(date);
  }

  const handleChangeChecked = (isChecked) => {
    if (!isChecked) {
      onSaveEndDate(null);
    } else {
      onSaveEndDate(endDate);
    }
    setChecked(isChecked);
    onSaveChecked(isChecked);
  }

  return (
    <>
      <MDBRow style={{ textAlign: "left", marginBottom: 15 }}>
        <MDBCol sm="6">
          <label className="  text-sm dark:text-white text-gray-500">
            Start Date
          </label>
        </MDBCol>
        <MDBCol size="3" sm="6">
          {!hasEndDate && <MDBCheckbox
            id={`chb${newGuid()}`}
            label='End date'
            checked={checked}
            onChange={() => handleChangeChecked(!checked)}
          />}
        </MDBCol>
      </MDBRow>
      <MDBRow style={{ textAlign: "left", marginBottom: 15 }}>
        <MDBCol size="3" sm="6">
          <DatePicker
            selected={fromDate}
            onChange={(date) => handleChangeStartDate(date)}
          />
        </MDBCol>
        <MDBCol size="3" sm="6">
          {(hasEndDate || checked) && <DatePicker
            selected={toDate}
            onChange={(date) => handleChangeEndDate(date)}
          />}

        </MDBCol>
      </MDBRow>
    </>
  );
}

export default CtrPlanCompleted;
