import { MDBBtn, MDBCol, MDBIcon, MDBInput, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBRow, MDBTextArea } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { configHeader, isTokenExpired } from "../../utils/tokenHelper";
import { useNavigate } from "react-router-dom";
import { apiEndpoint } from "../../utils/apiEndpoint";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";

function CreateNewCompany({ onCloseModal }) {
  const navigate = useNavigate();
  const [name, setName] = useState();
  const [contactName, setContactName] = useState();
  const [cashier, setCashier] = useState();
  const [chiefAccountant, setChiefAccountant] = useState();
  const [director, setDirector] = useState();
  const [bankAccount, setBankAccount] = useState();
  const [address, setAddress] = useState();

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
    document.title = "Customer";
  }, []);

  const saveData = () => {
    axios
      .post(
        `${apiEndpoint.hosting}/${apiEndpoint.companyUrl}`,
        {
          "id": 0,
          "name": name,
          "contactName": contactName,
          "bankAccount": bankAccount,
          "director": director,
          "cashier": cashier,
          "chiefAccountant": chiefAccountant,
          "adress": address
        },
        configHeader()
      )
      .then(() => {
        onCloseModal();
      });
  }

  const renderBody = () => {
    return <>
      <MDBRow>
        <MDBCol size="3" sm="12">
          <MDBInput
            className="mb-3"
            label="Name"
            type="text"
            title="Customer Name"
            value={name}
            onChange={(evt) => setName(evt.target.value)}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size="3" sm="12">
          <MDBInput
            className="mb-3"
            label="Contact Name"
            type="text"
            title="Customer contact Name"
            value={contactName}
            onChange={(evt) => setContactName(evt.target.value)}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow >
        <MDBCol size="3" sm="12">
          <MDBInput
            className="mb-3"
            label="Director"
            type="text"
            title="Customer director"
            value={director}
            onChange={(evt) => setDirector(evt.target.value)}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow >
        <MDBCol size="3" sm="12">
          <MDBInput
            className="mb-3"
            type="text"
            label="Bank Account"
            title="Customer Bank Account"
            value={bankAccount}
            onChange={(evt) => setBankAccount(evt.target.value)}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow style={{ marginTop: 15 }}>
        <MDBCol size="6" sm="12">
          <MDBTextArea
            className="mb-3"
            label="Address"
            id="address"
            rows="{4}"
            onChange={(evt) => setAddress(evt.target.value)}
          />
        </MDBCol>
      </MDBRow>
    </>
  }

  return <>
    <MDBModal
      open={true}
      onClose={() => onCloseModal()}
      tabIndex="-1"
    >
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>Customer information</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={() => onCloseModal()}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>{renderBody()}</MDBModalBody>

          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={() => onCloseModal()}>
              Close
            </MDBBtn>
            <MDBBtn onClick={() => saveData()}> <MDBIcon fas icon="save" /> Save</MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  </>;
}

export default CreateNewCompany;
