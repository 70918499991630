import { orderBy } from "lodash";
import { MDBCol, MDBRow, MDBSwitch } from "mdb-react-ui-kit";
import { dateFormatDMY, newGuid } from "../../utils/utilities";
function ViewProjectInfo({ data, handleCheckPhase }) {

  const renderPhaseInfo = (phaseData, isChecked) => {
    return <MDBRow title={phaseData?.name}>
      <MDBCol size="3" sm="1"></MDBCol>
      <MDBCol size="3" sm="1" style={{ textAlign: "right" }}>
        <MDBSwitch
          id={newGuid()}
          checked={isChecked}
          title="Monitoring"
          onChange={(e) => handleCheckPhase(phaseData?.id, e.target.checked)}
        />
      </MDBCol>
      <MDBCol size="3" sm="9">
        {phaseData?.name}
      </MDBCol>
    </MDBRow>
  }

  const renderProjectInfo = (data) => {
    let estHours = (data?.estimateHours ?? 0) > 0 ? ` Plan: ${data?.estimateHours} (hours)` : ""
    return <MDBRow>
      <MDBCol size="3" sm="3">
        {estHours}
      </MDBCol>
      <MDBCol size="3" sm="3">
        {`${data?.statusName}`}
      </MDBCol>
      <MDBCol size="3" sm="6">
        {`${dateFormatDMY(data?.startDate)} => ${data?.endDate ? dateFormatDMY(data?.endDate) : "TBD"}`}
      </MDBCol>
    </MDBRow>
  }

  return <>
    {renderProjectInfo(data)}
    <hr class="hr" />
    {orderBy(data?.phaseInfo ?? [], "priorityNumber").map((phase) => renderPhaseInfo(phase, phase.isMonitoring))}
  </>
}

export default ViewProjectInfo;
