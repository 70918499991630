import {
  MDBBtn,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
} from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isTokenExpired } from "../../utils/tokenHelper";

function CreateUser({ teamId, onCloseModal, onSave }) {
  const navigate = useNavigate();
  const [displayName, setDisplayName] = useState();
  const [userName, setUserName] = useState();

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
  }, []);

  const saveData = () => {
    onSave({
      name: displayName,
      userName: userName,
      teamId: teamId,
    });
  };

  const renderBody = () => {
    return (
      <>
        <MDBInput
          className="mb-3"
          type="text"
          label="Display Name"
          value={displayName}
          onChange={(evt) => setDisplayName(evt.target.value)}
        />

        <MDBInput
          className="mb-3"
          type="text"
          label="User Name"
          value={userName}
          onChange={(evt) => setUserName(evt.target.value)}
        />
      </>
    );
  };

  return (
    <MDBModal open={true} onClose={onCloseModal} tabIndex="-1">
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>Create New User</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={onCloseModal}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>{renderBody()}</MDBModalBody>

          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={onCloseModal}>
              Close
            </MDBBtn>
            <MDBBtn onClick={() => saveData()}>Create a New User</MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}

export default CreateUser;
