import { useEffect, useState } from "react";
import { configHeader, isTokenExpired } from "../../utils/tokenHelper";
import { isNullOrUndefine, onGridReady, toCommas, userRoles } from "../../utils/utilities";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { apiEndpoint } from "../../utils/apiEndpoint";
import { MDBBtn, MDBCol, MDBIcon, MDBInput, MDBRow } from "mdb-react-ui-kit";
import { AgGridReact } from "ag-grid-react";
import { difference, first, orderBy, sum } from "lodash";
import Multiselect from "multiselect-react-dropdown";
import UpdatePrice from "./UpdatePrice";
import UpdateDisplayName from "./UpdateDisplayName";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ViewUserInfo from "../Users/ViewUserInfo";

function Planning() {
  const navigate = useNavigate();
  const [staffs, setStaffs] = useState();
  const [curStaffs, setCurStaffs] = useState();
  const [employees, setEmployees] = useState();
  const [users, setUsers] = useState();
  const [avgPrice, setAvgPrice] = useState();
  const [totalMoney, setTotalMoney] = useState();
  const [estHours, setEstHours] = useState();
  const [rate, setRate] = useState(1);
  const [projects, setProjects] = useState();
  const [phases, setPhases] = useState();
  const [phaseId, setPhaseId] = useState();
  const [userId, setUserId] = useState();
  const [displayName, setDisplayName] = useState();
  const [userPrice, setUserPrice] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [openChangeNameModal, setOpenChangeNameModal] = useState(false);
  const [uRole, setURole] = useState();
  const [myId, setMyId] = useState();

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
    document.title = "Calculate Budgets";
  }, []);

  useEffect(() => {
    var info = window.localStorage.getItem("userInfo") ?? "";
    let infoObj = JSON.parse(info);
    setURole(infoObj.role);
    setMyId(infoObj.id);
  }, []);

  useEffect(() => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.projectUrl}`,
        configHeader()
      )
      .then((result) => {
        var items = result?.data ?? [];
        setProjects(orderBy(items, "priorityNumber"));
      });
  }, []);

  useEffect(() => {
    loadDataStaffs();
  }, []);

  const loadDataStaffs = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.userUrl}/All-Users`,
        configHeader()
      )
      .then((result) => {
        let items = filterStaffs(result?.data, []);
        setStaffs(items);
        setUsers(items)
      });
  };

  const colDefs = [
    {
      headerName: "Row",
      valueGetter: "node.rowIndex + 1",
      width: 65,
    },
    {
      field: "displayName",
      filter: true,
      pinned: 'left',
      lockPinned: true,
      cellStyle: {
        textAlign: "left",
      },
      cellRenderer: (row) => <ViewUserInfo
        roleName={row.data.roleName}
        isActive={row.data.isActive}
        displayName={row.data.displayName} />
    },
    {
      field: "team",
      filter: true,
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "actions",
      headerName: "",
      width: 50,
      pinned: 'right',
      lockPinned: true,
      cellRenderer: (row) => {
        return (
          <MDBIcon
            fas
            icon="user-edit"
            onClick={() => handleEditName(row.data.id, row.data.displayName)}
          />
        );
      },
    },
    {
      field: "actions",
      headerName: "",
      width: 50,
      pinned: 'right',
      lockPinned: true,
      cellRenderer: (row) => {
        let curAdmin = row.data.roleName === userRoles.Admin && myId !== row.data.id;
        if (uRole !== userRoles.Admin || curAdmin) return <></>

        return (
          <MDBIcon
            fas
            icon="key"
            onClick={() => handleResetPass(row.data.id, row.data.displayName)}
          />
        );
      },
    },
    {
      field: "actions",
      headerName: "",
      width: 50,
      pinned: 'right',
      lockPinned: true,
      cellRenderer: (row) => {
        return (
          <MDBIcon
            fas
            icon="money-check-alt"
            onClick={() => handleEditPrice(row.data.id, row.data.pricing)}
          />
        );
      },
    },
    {
      field: "pricing",
      width: 95,
      cellStyle: {
        textAlign: "right",
      },
      cellRenderer: (row) => toCommas(row.data.pricing),
    }
  ];

  const handleEditPrice = (id, pricing) => {
    setUserId(id);
    setUserPrice(pricing);
    setIsOpenModal(true);
  };

  const handleEditName = (id, name) => {
    setUserId(id);
    setDisplayName(name);
    setOpenChangeNameModal(true);
  };

  const handleResetPass = (id, name) => {
    axios
      .put(
        `${apiEndpoint.hosting}/${apiEndpoint.userUrl}/Staffs/${id}/Reset-password`,
        {},
        configHeader()
      )
      .then(() => {
        toast.success(
          `The user ${name} has been reset password to default password.`
        );
      });
  };

  const columDefs = [
    {
      headerName: "Row",
      valueGetter: "node.rowIndex + 1",
      width: 65,
    },
    {
      field: "displayName",
      pinned: 'left',
      lockPinned: true,
      cellStyle: {
        textAlign: "left",
      },
      cellRenderer: (row) => <ViewUserInfo
        roleName={row.data.roleName}
        isActive={row.data.isActive}
        displayName={row.data.displayName} />
    },
    {
      field: "capacity",
      editable: true,
      cellStyle: {
        textAlign: "right",
      },
    },
    {
      field: "actions",
      headerName: "",
      width: 50,
      pinned: 'right',
      lockPinned: true,
      cellRenderer: (row) => {
        return (
          <MDBIcon
            fas
            icon="trash-alt"
            color="warning"
            onClick={() => handleRemove(row.data.id)}
          />
        );
      },
    },
    {
      field: "pricing",
      cellStyle: {
        textAlign: "right",
      },
      width: 95,
      cellRenderer: (row) => toCommas(row.data.pricing),
    }
  ];

  const handleRemove = (id) => {
    let rs = (employees ?? []).filter((s) => s.id !== id);
    let items = filterStaffs(staffs, rs);
    setStaffs(items);
    setEmployees(rs);

    if (rs?.length < 1) {
      setAvgPrice("");
      setEstHours("");
    } else {
      calculateAvgPrice(rs);
    }

    calculateEstHours();
  };

  const calculateAvgPrice = (emps) => {
    let total = sum((emps ?? []).map((p) => p.pricing * p.capacity));
    let totalCapacity = sum((emps ?? []).map((p) => p.capacity));

    var avg =
      totalCapacity === 0 ? 0 : parseFloat(total / totalCapacity).toFixed(0);
    setAvgPrice(avg);
  };

  const calculateEstHours = () => {
    calculateAvgPrice(employees);
    if ((totalMoney ?? 0) === 0) {
      setEstHours("");
    } else if (rate * avgPrice !== 0) {
      let estHours = (totalMoney ?? 0) / (rate * avgPrice);
      let avgHours = parseFloat(estHours).toFixed(0);
      setEstHours(avgHours);
    } else {
      setEstHours("");
    }
  };

  const onSelectionChanged = (event) => {
    const selectedData = event.api.getSelectedRows();
    setCurStaffs(selectedData);
  };

  const handleSelect = () => {
    let rs = (employees ?? []).concat(curStaffs ?? []);
    let items = filterStaffs(staffs, rs);
    setStaffs(items);
    setEmployees(rs);
    calculateAvgPrice(rs);
    calculateEstHours();
  };

  const filterStaffs = (items, others) => {
    let rs = (items ?? []).filter((item) => item.isActive);
    return difference(rs, others);
  };

  const handleSetRate = (newRate) => {
    setRate(newRate);
    calculateEstHours();
  };

  const handleSetMoney = (totalAmount) => {
    setTotalMoney(totalAmount);
    calculateEstHours();
  };

  const autoSizeStrategy = {
    type: "fitGridWidth",
    defaultMinWidth: 100,
    columnLimits: [
      {
        colId: "country",
        minWidth: 900,
      },
    ],
  };

  const options = () => {
    return (projects ?? []).map((p) => {
      return { name: p.name, id: p.id };
    });
  };

  const optionPhases = () => {
    return (phases ?? []).map((p) => {
      return { name: p.name, id: p.id };
    });
  };

  const onSelectedValues = (items) => {
    let ids = (items ?? []).map((it) => it.id);
    let rs = (projects ?? []).filter((p) => ids.some((id) => id === p.id));
    let pro = first(rs) ?? {};
    setPhases(pro?.phaseInfo);
  };

  const onSelectedPhaseValues = (items) => {
    let ids = (items ?? []).map((it) => it.id);
    let rs = (phases ?? []).filter((p) => ids.some((id) => id === p.id));
    let phase = first(rs) ?? {};
    setPhaseId(phase?.id);
  };

  useEffect(() => {
    phaseId && reloadPhaseInfo(phaseId);
  }, [phaseId]);

  const reloadPhaseInfo = (phaseId) => {
    phaseId &&
      axios
        .get(
          `${apiEndpoint.hosting}/${apiEndpoint.phaseUrl}/${phaseId}`,
          configHeader()
        )
        .then((result) => {
          let items = result?.data?.staffs ?? [];
          loadDataInitByPhase(items);
        });
  };

  const loadDataInitByPhase = (listStaffs) => {
    let ids = (listStaffs ?? []).map((it) => it.id);
    let rs = (staffs ?? []).filter((p) => ids.some((id) => id === p.id));
    let items = filterStaffs(users, rs);
    setStaffs(items);
    setEmployees(rs);
    calculateAvgPrice(rs);
  };

  const changeEstimateHours = () => {
    phaseId && (estHours ?? 0) > 0 &&
      axios.put(
        `${apiEndpoint.hosting}/${apiEndpoint.phaseUrl}/${phaseId}/EstimateHours`,
        { hours: estHours ?? 0, amount: totalMoney ?? 0 },
        configHeader()
      );
  };

  const updatePrice = () => {
    userId &&
      axios
        .put(
          `${apiEndpoint.hosting}/${apiEndpoint.userUrl}/Staffs/${userId}/UpdatePrice`,
          { price: userPrice ?? 0 },
          configHeader()
        )
        .then(() => {
          loadDataStaffs();
          if ((phaseId ?? 0) > 0) reloadPhaseInfo(phaseId);
          setIsOpenModal(false);
        });
  };

  const updateDisplayName = () => {
    axios
      .put(
        `${apiEndpoint.hosting}/${apiEndpoint.userUrl}/Staffs/${userId}/UpdateName`,
        { name: displayName },
        configHeader()
      )
      .then(() => {
        loadDataStaffs();
        if ((phaseId ?? 0) > 0) reloadPhaseInfo(phaseId);
        setOpenChangeNameModal(false);
      });
  };

  const handleCellValueChanged = () => {
    calculateAvgPrice(employees);
  };

  return (
    <>
      {isOpenModal && (
        <UpdatePrice
          setIsOpenModal={setIsOpenModal}
          userPrice={userPrice}
          setUserPrice={setUserPrice}
          updatePrice={updatePrice}
        />
      )}
      {openChangeNameModal && (
        <UpdateDisplayName
          setIsOpenModal={setOpenChangeNameModal}
          displayName={displayName}
          setDisplayName={setDisplayName}
          updateDisplayName={updateDisplayName}
        />
      )}

      <MDBRow>
        <MDBCol size="3" sm="6">
          <Multiselect
            options={options()}
            singleSelect
            onSelect={(items) => onSelectedValues(items)}
            onRemove={(items) => onSelectedValues(items)}
            displayValue="name"
          />
        </MDBCol>
        <MDBCol size="3" sm="6">
          <Multiselect
            options={optionPhases()}
            singleSelect
            onSelect={(items) => onSelectedPhaseValues(items)}
            onRemove={(items) => onSelectedPhaseValues(items)}
            displayValue="name"
          />
        </MDBCol>
      </MDBRow>
      <MDBRow style={{ marginTop: 10 }}>
        <MDBCol size="3" sm="6">
          <div className="ag-theme-quartz" style={{ height: "65vh" }}>
            <AgGridReact
              rowData={staffs}
              columnDefs={colDefs}
              rowSelection={"multiple"}
              onSelectionChanged={onSelectionChanged}
              onGridReady={onGridReady}
            />
          </div>
          Selecting multiple rows can be achieved by holding down ^ Ctrl and
          mouse clicking the rows. A range of rows can be selected by using ⇧
          Shift.
        </MDBCol>
        <MDBCol size="3" sm="1">
          <MDBBtn
            type="submit"
            block
            className="mb-4"
            style={{ marginTop: 75 }}
            onClick={() => handleSelect()}
          >
            <MDBIcon fas icon="chevron-circle-right" />
          </MDBBtn>
        </MDBCol>

        <MDBCol size="12" sm="5">
          <MDBRow style={{ marginBottom: 10 }}>
            <MDBCol size="12" sm="8">
              <MDBInput
                label="Money"
                id="form1"
                type="text"
                value={totalMoney}
                onChange={(evt) => handleSetMoney(evt.target.value)}
              />
            </MDBCol>
            <MDBCol size="12" sm="4">
              {toCommas(totalMoney)}
            </MDBCol>
          </MDBRow>

          <div className="ag-theme-quartz" style={{ height: "40vh" }}>
            <AgGridReact
              rowData={employees}
              columnDefs={columDefs}
              autoSizeStrategy={autoSizeStrategy}
              onCellValueChanged={(evt) => handleCellValueChanged(evt)}
              onGridReady={onGridReady}
            />
          </div>
          <MDBRow>
            <MDBCol size="3" sm="8">
              <MDBInput
                label="Average price"
                id="form1"
                type="text"
                style={{ marginTop: 15 }}
                value={avgPrice}
              />
            </MDBCol>
            <MDBCol size="3" sm="4">
              <MDBInput
                label="Utilization rate"
                id="form1"
                type="text"
                style={{ marginTop: 15 }}
                onChange={(evt) => handleSetRate(evt.target.value)}
                value={rate}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol size="3" sm="8">
              <MDBInput
                label="Estimation Hours"
                id="form1"
                type="text"
                style={{ marginTop: 15, fontWeight: "bold" }}
                value={isNullOrUndefine(estHours) ? "" : estHours}
              />
            </MDBCol>
            <MDBCol size="3" sm="4">
              <MDBBtn
                type="submit"
                block
                className="btn btn-info"
                style={{ marginTop: 15 }}
                onClick={() => calculateEstHours()}
              >
                <MDBIcon fas icon="sync" />
              </MDBBtn>
            </MDBCol>
          </MDBRow>
          <MDBBtn
            type="submit"
            block
            className="btn btn-outline-primary"
            style={{ marginTop: 15 }}
            disabled={
              isNullOrUndefine(estHours) || estHours === 0 || (phaseId ?? 0) < 1
            }
            onClick={() => changeEstimateHours()}
          >
            <MDBIcon fas icon="save" /> Save Estimate Hours to phase
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <ToastContainer />
    </>
  );
}

export default Planning;
