import { sumBy, uniqBy } from "lodash";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

function TimeSheetsBasic({ timeSheets }) {
  const [phaseNames, setPhaseNames] = useState([]);
  const [planning, setPlanning] = useState([]);
  const [actual, setActual] = useState([]);
  const [overTimes, setOverTimes] = useState([]);

  useEffect(() => {
    let items = (timeSheets ?? []).map((item) => item.phaseName);
    let unique = uniqBy(items ?? []);
    let actualItems = [];
    let planningItems = [];
    let otItems = [];

    (unique ?? []).map((item) => {
      let data = (timeSheets ?? []).filter((it) => it.phaseName === item);
      let planningHours = sumBy(data ?? [], "estimateHours");
      let totalHours = sumBy(data ?? [], "totalHours");
      let overTimeHours = sumBy(data ?? [], "overTimeHours");

      planningItems.push(planningHours);
      actualItems.push(totalHours);
      otItems.push(overTimeHours);

      return item;
    });

    setActual(actualItems);
    setPlanning(planningItems);
    setOverTimes(otItems);
    setPhaseNames(unique);
  }, [timeSheets]);
  const dataSeries = () => {
    return [
      {
        name: "Planning time",
        type: "column",
        data: planning ?? [],
      },
      {
        name: "Actual time",
        type: "line",
        data: actual ?? [],
      },
      {
        name: "Over time",
        type: "line",
        data: overTimes ?? [],
      },
    ];
  };

  const dataOptions = () => {
    return {
      chart: {
        height: 350,
        type: "line",
      },
      stroke: {
        width: [0, 4],
      },
      title: {
        text: "Timesheets",
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      labels: phaseNames ?? [],
      yaxis: [
        {
          title: {
            text: "Planning time",
          },
        },
        {
          opposite: true,
          title: {
            text: "Actual time",
          },
        },
      ],
    };
  };

  return (
    <>
      <Chart
        options={dataOptions()}
        series={dataSeries()}
        type="line"
        height={350}
      />
    </>
  );
}

export default TimeSheetsBasic;
