import {
  MDBAccordion, MDBAccordionItem, MDBBtn, MDBRow, MDBCol,
  MDBIcon, MDBInput, MDBModal, MDBModalBody, MDBModalContent,
  MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle,
  MDBSwitch,
  MDBTextArea
} from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { dateFormatDMY, newGuid, toCommas } from "../../utils/utilities";
import "react-datepicker/dist/react-datepicker.css";
import { toNumber } from "lodash";

function InputContractAmount({ phaseInfo, onCloseModal, onSave }) {

  const [receiptDate, setReceiptDate] = useState(new Date());
  const [amount, setAmount] = useState(phaseInfo?.totalAmout ?? 0);
  const [totalAmout, setTotalAmout] = useState(phaseInfo?.totalAmout ?? 0);
  const [advanceAmount, setAdvanceAmount] = useState(0);
  const [isUsed, setIsUsed] = useState(false);
  const [payer, setPayer] = useState();
  const [including, setIncluding] = useState("");
  const [documents, setDocuments] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    let totalAmout = (phaseInfo?.totalAmout ?? 0) - (phaseInfo?.advancePayment ?? 0)
    if (totalAmout < 0) totalAmout = 0;
    setTotalAmout(totalAmout);
    setAmount(totalAmout);
  }, [phaseInfo]);

  const handleInputAmount = (inputAmount) => {
    setAmount(inputAmount);
    let rs = totalAmout - inputAmount;
    if (rs < 0) rs = 0;
    setAdvanceAmount(rs)
  }

  const saveData = () => {
    let data = {
      "payer": payer,
      "id": phaseInfo?.id,
      "phaseId": phaseInfo?.phaseId,
      "totalAmout": totalAmout,
      "receiptDate": receiptDate,
      "including": including,
      "documents": documents,
      "cashExchangeRate": 1,
      "totalCash": amount,
      "cashReceipt": amount,
      "description": description
    }

    onSave(data);
  }

  const renderInfomation = () => {
    return <>
      <MDBRow style={{ marginBottom: 15, textAlign: "left" }}>
        <MDBCol size="3" sm="3">
          Client
        </MDBCol>
        <MDBCol size="3" sm="9">
          {phaseInfo?.companyName}
        </MDBCol>
      </MDBRow>
      <MDBRow style={{ marginBottom: 15, textAlign: "left" }}>
        <MDBCol size="3" sm="3">
          Payment plan
        </MDBCol>
        <MDBCol size="3" sm="3">
          {dateFormatDMY(phaseInfo?.planReceivingDate)}
        </MDBCol>
        <MDBCol size="3" sm="3" style={{ textAlign: "right" }}>
          {toCommas(toNumber(phaseInfo?.totalAmout ?? 0))}
        </MDBCol>

      </MDBRow>
      <MDBRow style={{ marginBottom: 15, textAlign: "left" }}>
        <MDBCol size="3" sm="3">
          Project Name
        </MDBCol>
        <MDBCol size="3" sm="9">
          {phaseInfo?.projectName}
        </MDBCol>
      </MDBRow>
      <MDBRow style={{ marginBottom: 15, textAlign: "left" }}>
        <MDBCol size="3" sm="3">
          Phase Name
        </MDBCol>
        <MDBCol size="3" sm="9">
          {phaseInfo?.phaseName}
        </MDBCol>
      </MDBRow>
      {/* <MDBRow style={{ marginBottom: 15, textAlign: "left" }}>
        <MDBCol size="3" sm="3">
          Receipt No
        </MDBCol>
        <MDBCol size="3" sm="3">
          {phaseInfo?.receiptNo}
        </MDBCol>
        <MDBCol size="3" sm="3">
          Advance Payment
        </MDBCol>
        <MDBCol size="3" sm="3" style={{ textAlign: "right" }}>
          {toCommas(toNumber(phaseInfo?.advancePayment ?? 0))}
        </MDBCol>
      </MDBRow> */}
    </>
  }

  const renderPaymentInfomation = () => {
    return <>
      <MDBRow style={{ marginBottom: 15, textAlign: "left" }}>
        <MDBCol size="3" sm="6">
          <MDBInput
            label="Payer"
            title="Payer"
            type="text"
            value={payer}
            onChange={(evt) => setPayer(evt.target.value)}
          />
        </MDBCol>
        <MDBCol size="3" sm="6">
          <MDBSwitch
            id={newGuid()}
            label="Receive the full amount"
            checked={isUsed}
            onChange={(e) => setIsUsed(e.target.checked)}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow style={{ marginBottom: 15, textAlign: "left" }}>
        <MDBCol size="3" sm="4" >
          <DatePicker
            selected={receiptDate}
            onChange={(date) => setReceiptDate(date)}
          />
        </MDBCol>
        <MDBCol size="3" sm="4">
          <MDBInput
            className="mb-3"
            type="number"
            id="amount"
            label="Amount"
            value={amount}
            style={{ padding: 5, textAlign: "right" }}
            onChange={(evt) => handleInputAmount(evt.target.value)}
          />
        </MDBCol>
        <MDBCol size="3" sm="4">
          {toCommas(amount)}
        </MDBCol>
      </MDBRow>
      <MDBRow style={{ marginBottom: 15, textAlign: "left" }}>
        <MDBCol size="3" sm="3">
          Remaining amount
        </MDBCol>
        <MDBCol size="3" sm="3" style={{ textAlign: "right" }}>
          {toCommas(advanceAmount)}
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size="3" sm="12">
          <MDBInput
            className="mb-3"
            label="Including"
            title="Including"
            type="text"
            value={including}
            onChange={(evt) => setIncluding(evt.target.value)}
          />
        </MDBCol>
        <MDBCol size="3" sm="12">
          <MDBTextArea
            className="mb-3"
            label="Documents"
            id="documents"
            rows="{4}"
            onChange={(evt) => setDocuments(evt.target.value)}
          />
        </MDBCol>
        
        <MDBCol size="3" sm="12">
          <MDBTextArea
            className="mb-3"
            label="Description"
            id="description"
            rows="{4}"
            onChange={(evt) => setDescription(evt.target.value)}
          />
        </MDBCol>
        
      </MDBRow>
    </>
  }

  const renderTitlePhase = () => {
    if ((phaseInfo?.advancePayment ?? 0) > 0) {
      return <>
        <div style={{ marginRight: 50 }}>
          Phase information
        </div>
        {toCommas(toNumber(phaseInfo?.advancePayment ?? 0))}
      </>
    }

    return "Phase information"
  }

  const renderBody = () => {
    return <>
      <MDBAccordion alwaysOpen initialActive={1}>
        <MDBAccordionItem collapseId={1} headerTitle={renderTitlePhase()}>
          {renderInfomation()}
        </MDBAccordionItem>
        <MDBAccordionItem collapseId={2} headerTitle={"Payment information"}>
          {renderPaymentInfomation()}
        </MDBAccordionItem>
      </MDBAccordion>
    </>
  }

  return (
    <>
      <MDBModal
        open={true}
        onClose={() => onCloseModal()}
        tabIndex="-1"
        className="billDialog"
      >
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Bill information</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={() => onCloseModal()}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>{renderBody()}</MDBModalBody>

            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={() => onCloseModal()}>
                Close
              </MDBBtn>
              <MDBBtn onClick={() => saveData()}> <MDBIcon fas icon="save" /> Save</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}

export default InputContractAmount;
