import {
  MDBBtn,
  MDBCol,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
} from "mdb-react-ui-kit";
import ViewUserInfo from "../Users/ViewUserInfo";

function UpdateDisplayName({ setIsOpenModal, displayName, roleName, setDisplayName, updateDisplayName }) {
  return (
    <MDBModal open={true} onClose={() => setIsOpenModal(false)} tabIndex="-1">
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>Update Display Name for {displayName}</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={() => setIsOpenModal(false)}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <MDBRow style={{ textAlign: "left", marginBottom: 20  }}>
              <MDBCol size="3" sm="12">
                <ViewUserInfo
                  roleName={roleName}
                  isActive={true}
                  displayName={displayName} />
              </MDBCol>
            </MDBRow>
            <div style={{ border: "1px solid gray", marginBottom: 20 }} />
            <MDBInput
              className="mb-3"
              type="text"
              id="displayName"
              label="Display Name"
              value={displayName}
              style={{ padding: 5 }}
              onChange={(evt) => setDisplayName(evt.target.value)}
            />
          </MDBModalBody>

          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={() => setIsOpenModal(false)}>
              Close
            </MDBBtn>
            <MDBBtn onClick={() => updateDisplayName()}>Save </MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}

export default UpdateDisplayName;
