import { useEffect, useState } from "react";
import { MDBAccordion, MDBAccordionItem, MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import CurrentLogTimeStatus from "./CurrentLogTimeStatus";
import CreateNewTask from "../../modals/CreateNewTask";
import ConfirmDeleteDialog from "../../components/ConfirmDialog";
import UpdatePhase from "../../modals/UpdatePhase";
import ClonePhase from "../../modals/ClonePhase";
import { toNumber } from "lodash";
import "./Project.css";
import { apiEndpoint } from "../../utils/apiEndpoint";
import axios from "axios";
import { configHeader } from "../../utils/tokenHelper";
import DisplayTaskProcessing from "../Planning/DisplayTaskProcessing";
import ViewBoardItem from "../TaskInfo/ViewBoardItem";
import { defaultBoxMinWidth, defaultBoxWidth, userRoles } from "../../utils/utilities";
import CtrlDisplayExtentDetail from "../../components/CtrlDisplayExtentDetail";
import DisplayOverdue from "../../components/DisplayOverdue";

function CellPhaseSection({
  colIndex,
  taskIndex,
  cellInfo,
  userRole,
  statusOfPhase,
  teams,
  onSave,
  onSaveTask,
  handleDelete,
  onClonePhase,
  colWidth,
  handleViewWidth
}) {
  const phaseInfo = cellInfo ?? {};

  const [openTaskModal, setOpenTaskModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCloneOpen, setIsCloneOpen] = useState(false);
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);
  const [phaseId, setPhaseId] = useState(phaseInfo?.id);
  const [userId, setUserId] = useState();
  const [statuses, setStatuses] = useState(statusOfPhase ?? []);
  const [modalWidth, setModalWidth] = useState(colWidth);

  useEffect(() => {
    setModalWidth(colWidth);
  }, [colWidth]);

  useEffect(() => {
    var info = window.localStorage.getItem("userInfo") ?? "";
    let infoObj = JSON.parse(info);
    setUserId(toNumber(infoObj?.id ?? 0));
  }, []);

  useEffect(() => {
    loadPhaseStatuses()
  }, [phaseInfo?.id]);

  const loadPhaseStatuses = () => {
    phaseInfo?.id &&
      axios
        .get(
          `${apiEndpoint.hosting}/${apiEndpoint.phaseUrl}/${phaseInfo?.id}/AllStatus`,
          configHeader()
        )
        .then((result) => {
          setStatuses(result?.data);
        });
  }

  const handleOnDrag = (e) => {
    e.dataTransfer.setData(
      "text",
      JSON.stringify({ taskIndex, prevColIndex: colIndex })
    );
  };

  const renderAddTask = () => {
    if (userRole !== userRoles.Admin && !phaseInfo?.isPM) {
      return (
        <MDBRow style={{ marginTop: 5 }}>
          <MDBCol
            size="3"
            sm="8"
            className=" truncate max-w-[250px]"
            title={`${phaseInfo?.assigned ?? "TBD"}`}
          >
            <MDBIcon fas icon={"user-alt"} style={{ marginRight: 10 }} />
            {phaseInfo?.assigned ?? "TBD"}
          </MDBCol>
        </MDBRow>
      );
    }

    return (
      <MDBRow style={{ marginTop: 5 }}>
        <MDBCol
          size="3"
          sm="8"
          className=" truncate max-w-[250px]"
          title={`${phaseInfo?.assigned ?? "TBD"}`}
        >
          <MDBIcon fas icon={"user-alt"} style={{ marginRight: 10 }} />
          {phaseInfo?.assigned ?? "TBD"}
        </MDBCol>

        <MDBCol size="3" sm="2" title={"Add new task"}>
          <div
            style={{ cursor: "pointer", alignItems: "flex-end" }}
            onClick={() => setOpenTaskModal(true)}
          >
            <MDBIcon fas icon="plus" />
          </div>
        </MDBCol>
        <MDBCol size="3" sm="2" title="Delete this phase">
          <div
            style={{ cursor: "pointer", alignItems: "flex-end" }}
            onClick={() => {
              setIsConfirmDelete(true);
              setPhaseId(phaseInfo?.id);
            }}
          >
            <MDBIcon fas icon="trash-alt" color="warning" />
          </div>
        </MDBCol>
      </MDBRow>
    );
  };

  const renderCloneCtrl = () => {
    if (userRole !== userRoles.Admin && !phaseInfo?.isPM)
      return (
        <div
          style={{ cursor: "pointer", alignItems: "flex-end" }}
          onClick={() => setIsModalOpen(true)}
          title={`Update information of ${phaseInfo?.name}`}
        >
          <MDBIcon fas icon="eye" />
        </div>
      );

    return <MDBRow>
      <MDBCol sm="6">
        <div
          style={{ cursor: "pointer", alignItems: "flex-end" }}
          onClick={() => setIsModalOpen(true)}
          title={`Update information of ${phaseInfo?.name}`}
        >
          <MDBIcon fas icon="user-edit" />
        </div>
      </MDBCol>
      <MDBCol sm="6">
        <div
          style={{ cursor: "pointer", alignItems: "flex-end" }}
          onClick={() => setIsCloneOpen(true)}
          title={`Clone ${phaseInfo?.name}`}
        >
          <MDBIcon fas icon="clone" />
        </div>
      </MDBCol>
    </MDBRow>
  };

  const [initialItem, setInitialItem] = useState(0);

  const handleMinTaskDetail = () => {
    setModalWidth(defaultBoxMinWidth);
  }

  const handleViewTaskDetail = () => {
    setInitialItem(1);
    handleViewWidth();
    setModalWidth(defaultBoxWidth);
  }

  const renderTitlePhaseDisplayInfo = () => {
    if ((modalWidth ?? defaultBoxMinWidth) <= defaultBoxMinWidth) {
      return <MDBRow>
        <MDBCol sm="6" className={` truncate max-w-[${modalWidth ?? 175}px] `} title={phaseInfo?.description}>
          <div>
            <DisplayTaskProcessing
              statusName={phaseInfo?.statusName}
              planHours={phaseInfo?.estimateHours ?? 0}
              workingHours={phaseInfo?.normalHours ?? 0}
              otHours={phaseInfo?.overTimeHours ?? 0}
              displayOverHours
            />
          </div>
        </MDBCol>
        <MDBCol sm="4">
          {renderCloneCtrl()}
        </MDBCol>
        <MDBCol sm="2">
          <CtrlDisplayExtentDetail
            tabWidth={modalWidth}
            handleViewTaskDetail={() => handleViewTaskDetail()}
            handleMinTaskDetail={() => handleMinTaskDetail()}
          />
        </MDBCol>
      </MDBRow>
    }

    return <MDBRow>
      <MDBCol sm="9" className={` truncate max-w-[${modalWidth ?? 175}px] `} title={phaseInfo?.description}>
        <div>
          <DisplayTaskProcessing
            statusName={phaseInfo?.statusName}
            planHours={phaseInfo?.estimateHours ?? 0}
            workingHours={phaseInfo?.normalHours ?? 0}
            otHours={phaseInfo?.overTimeHours ?? 0}
            displayOverHours
          />
        </div>
      </MDBCol>
      <MDBCol sm="2">
        {renderCloneCtrl()}
      </MDBCol>
      <MDBCol sm="1">
        <CtrlDisplayExtentDetail
          tabWidth={modalWidth}
          handleViewTaskDetail={() => handleViewTaskDetail()}
          handleMinTaskDetail={() => handleMinTaskDetail()}
        />
      </MDBCol>
    </MDBRow>
  }

  const renderControlPhaseAccordion = () => {
    let initId = (modalWidth ?? defaultBoxMinWidth) <= defaultBoxMinWidth ? 0 : initialItem;
    return <MDBAccordion alwaysOpen initialActive={initId}>
      <MDBAccordionItem collapseId={1} headerTitle={<>
        <DisplayOverdue
          statusName={phaseInfo?.statusName ?? ""}
          endDate={phaseInfo?.endDate}
        />
        <div style={{ marginLeft: 20 }} className={` truncate max-w-[${modalWidth ?? 275}px] `}>{phaseInfo?.name}</div>
      </>}
        className={` truncate max-w-[${modalWidth ?? 275}px] `} title={phaseInfo?.name}>
        <p className=" font-bold tracking-wide " title={phaseInfo?.description}>
          {renderTitlePhaseDisplayInfo()}
        </p>

        <MDBAccordion borderless alwaysOpen initialActive={1}>
          <MDBAccordionItem collapseId={1} headerTitle="Phase info">
            <CurrentLogTimeStatus
              statusName={phaseInfo?.statusName}
              estimateHours={phaseInfo?.estimateHours}
              normalHours={phaseInfo?.normalHours ?? 0}
              overTime={phaseInfo?.overTimeHours ?? 0}
              startDate={phaseInfo?.startDate}
              endDate={phaseInfo?.endDate}
              description={phaseInfo?.description}
              hideStatus
            />
          </MDBAccordionItem>
          <MDBAccordionItem collapseId={3} headerTitle={`Members (${phaseInfo?.staffs?.length})`}>
            <MDBRow>
              {renderStaffOfPhase(phaseInfo?.staffs ?? [])}
            </MDBRow>
          </MDBAccordionItem>
          {renderAddTask()}
        </MDBAccordion>
      </MDBAccordionItem>
    </MDBAccordion >
  }

  const renderStaffOfPhase = (staffs) => {
    return (staffs ?? []).map((it) => {
      return <>
        <MDBRow style={{ marginTop: 5 }} >
          <MDBCol size="7" className=" truncate max-w-[300px]" title={it?.displayName}>
            {it?.displayName}
          </MDBCol>
          <MDBCol size="5" className=" truncate max-w-[300px]" title={it?.team}>
            {it?.team}
          </MDBCol>
        </MDBRow>
      </>
    })
  }

  return (
    <div>
      <ViewBoardItem
        ctrlAccordion={renderControlPhaseAccordion()}
        id={`phase-${colIndex}-${phaseInfo?.id}`}
        handleOnDrag={handleOnDrag}
        colWidth={colWidth}
      />

      {isConfirmDelete && phaseId && (
        <ConfirmDeleteDialog
          openModal={true}
          titleDelete={`Delete phase ${phaseInfo?.name}`}
          onCloseModal={() => setIsConfirmDelete(false)}
          onDelete={() => {
            handleDelete(phaseId);
            setIsConfirmDelete(false);
          }}
        />
      )}
      {isModalOpen && (
        <UpdatePhase
          isModalOpen={isModalOpen}
          userRole={userRole}
          phaseInfo={phaseInfo}
          onSave={onSave}
          setIsModalOpen={setIsModalOpen}
        />
      )}
      {isCloneOpen && (
        <ClonePhase
          isCloneOpen={isCloneOpen}
          setIsCloneOpen={setIsCloneOpen}
          phaseInfo={phaseInfo}
          statuses={statuses ?? []}
          teams={teams ?? []}
          userRole={userRole}
          onSave={onClonePhase}
        />
      )}
      {openTaskModal && (
        <CreateNewTask
          phaseId={phaseInfo?.id}
          phaseName={phaseInfo?.name}
          statuses={statuses ?? []}
          teams={(teams ?? []).filter((item) => item.isActive)}
          userRole={userRole}
          isPM={phaseInfo?.isPM}
          onCloseModal={() => setOpenTaskModal(false)}
          onSave={(data) => onSaveTask(data)}
        />
      )}
    </div>
  );
}

export default CellPhaseSection;
