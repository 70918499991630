import { Label } from "reactstrap";

function ViewBudgetInfo({ data }) {

  const renderTeamBudgets = () => {
    let divTeams = (data?.teamBudgets ?? []).map((item) => {
      return `${item?.teamName} `
    })

    return <Label title={divTeams}>
      {divTeams}
    </Label>
  }
  
  return renderTeamBudgets()
}

export default ViewBudgetInfo;
