import { MDBCol, MDBInput, MDBRow } from "mdb-react-ui-kit";
import { useState } from "react";
import DatePicker from "react-datepicker";
import { toCommas } from "../../utils/utilities";
import "react-datepicker/dist/react-datepicker.css";
import { toNumber } from "lodash";

function PhaseAmount({ phaseInfo, cashReceipt, onAmountPercent }) {
  const [receiptDate, setReceiptDate] = useState(new Date());
  const [amount, setAmount] = useState()
  const [amountPercent, setAmountPercent] = useState()
  const [vat, setVat] = useState()
  const [vatPercent, setVatPercent] = useState()

  const handleVATChange = (val) => {
    setVatPercent(val);
    calculateVATAmount(val);
  }

  const handleAmountPercentChange = (val) => {
    setAmountPercent(val);
    calculateAmount(val);
    if ((vatPercent ?? 0) > 0) {
      calculateVATAmount(vatPercent)
    }
    onAmountPercent(val, amount, vat, receiptDate);
  }

  const handleInputAmount = (val) => {
    setAmount(val);
    if ((vatPercent ?? 0) > 0) {
      calculateVATAmount(vatPercent)
    }
    onAmountPercent(amountPercent, val, vat, receiptDate);
  }

  const calculateAmount = (val) => {
    let totalAmount = toNumber(cashReceipt ?? 0)
    let amount = totalAmount * val / 100;
    setAmount(amount)
  }

  const calculateVATAmount = (val) => {
    let totalAmount = toNumber(amount ?? 0);
    let vatAmount = totalAmount * val / 100;
    setVat(vatAmount)
    onAmountPercent(amountPercent, amount, vat, receiptDate);
  }

  const handleChangeDate = (date) => {
    setReceiptDate(date)
    onAmountPercent(amountPercent, amount, vat, date);
  }

  const renderPaymentInfomation = () => {
    return <>
      <MDBRow>
        <MDBCol size="3" sm="3">
          <MDBInput
            className="mb-3"
            type="number"
            id="amountPercent"
            label="Amount %"
            value={amountPercent}
            style={{ padding: 5, textAlign: "right" }}
            onChange={(evt) => handleAmountPercentChange(evt.target.value)}
          />
        </MDBCol>
        <MDBCol size="3" sm="3">
          <MDBInput
            className="mb-3"
            type="number"
            id="vat"
            label="Amount"
            value={amount}
            style={{ padding: 5, textAlign: "right" }}
            onChange={(evt) => handleInputAmount(evt.target.value)}
          />
        </MDBCol>
        <MDBCol size="3" sm="3">
          <MDBInput
            className="mb-3"
            type="number"
            id="vatPercent"
            label="VAT %"
            value={vatPercent}
            style={{ padding: 5, textAlign: "right" }}
            onChange={(evt) => handleVATChange(evt.target.value)}
          />
        </MDBCol>
        <MDBCol size="3" sm="3">
          <MDBInput
            className="mb-3"
            type="number"
            id="vat"
            label="VAT Amount"
            value={vat}
            style={{ padding: 5, textAlign: "right" }}
            onChange={(evt) => setVat(evt.target.value)}
          />
        </MDBCol>
      </MDBRow>
    </>
  }

  return (
    <>
      <MDBRow style={{ marginBottom: 15 }}>
        <MDBCol size="3" sm="3" className=" truncate max-w-[240px]">
          Receiving Date
        </MDBCol>
        <MDBCol size="3" sm="5">
          <DatePicker
            selected={receiptDate}
            onChange={(date) => handleChangeDate(date)}
          />
        </MDBCol>
        <MDBCol size="3" sm="4" style={{ textAlign: "right" }}>
          {toCommas(toNumber(amount ?? 0) + toNumber(vat ?? 0))}
        </MDBCol>
      </MDBRow>
      {renderPaymentInfomation()}

    </>
  );
}

export default PhaseAmount;
