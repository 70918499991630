import React, { useEffect, useState } from "react";
import PhaseInfo from "./PhaseInfo";
import ColumnSection from "./ColumnSection";
import { defaultBoxMinWidth, defaultBoxWidth, minSizeWindowPC } from "../../utils/utilities";

function MainBoard({
  columns,
  projectId,
  projects,
  statuses,
  currentPhaseId,
  statusOfPhase,
  userRole,
  isProject,
  teams,
  staffsOfPhase,
  projectResources,
  onSelectProject,
  onSelectPhase,
  changeStatus,
  onSavePhase,
  handleAddSubtask,
  handleUpdateSubtask,
  onSaveProject,
  onSaveTask,
  handleDelete,
  onUpdateTask,
  handleDeleteTask,
  onClonePhase,
  onSelectTask,
  statusOfTask,
  taskInfoId,
  taskInfo,
  handleDeleteSubTask,
  reloadPhaseInfo,
  onShowCheckListOfPhase,
  onShowAllCheckListOfPhase,
  handleLogWork,
  reloadTaskInfo,
  onAddRevisionTask,
  dataToday,
  onSelectTodayTask,
  handleCreatePhaseClick,
  handleCreateTaskClick,
  handleCreateSubTaskClick
}) {

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const [isBoardModalOpen, setIsBoardModalOpen] = useState(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState(true);

  const handleDragTask = (colIndex, prevColIndex, taskIndex) => {
    let curStatus = columns[colIndex].status;
    let curTaskId = columns[prevColIndex].entities[taskIndex]?.id;
    changeStatus(curTaskId, curStatus);
  };

  const [colWidth, setColWidth] = useState(-1);

  const handleSetColWidth = (idx) => {
    setColWidth(idx)
  }

  const renderColumnSections = (items) => {

    return (items ?? []).map((col, index) => (
      <ColumnSection
        isSideBarOpen={isSideBarOpen}
        key={index}
        colIndex={index}
        statusOfPhase={statusOfPhase}
        isProject={isProject}
        teams={teams}
        userRole={userRole}
        staffsOfPhase={staffsOfPhase}
        projectResources={projectResources}
        columnInfo={col}
        dragTask={(colIndex, prevColIndex, taskIndex) =>
          handleDragTask(colIndex, prevColIndex, taskIndex)
        }
        onSavePhase={onSavePhase}
        handleAddSubtask={handleAddSubtask}
        handleUpdateSubtask={handleUpdateSubtask}
        onSaveTask={onSaveTask}
        handleDelete={handleDelete}
        onUpdateTask={onUpdateTask}
        handleDeleteTask={handleDeleteTask}
        onClonePhase={onClonePhase}
        taskInfoId={taskInfoId}
        taskInfo={taskInfo}
        statusOfTask={statusOfTask}
        handleDeleteSubTask={handleDeleteSubTask}
        reloadPhaseInfo={reloadPhaseInfo}
        currentPhaseId={currentPhaseId}
        projectId={projectId}
        handleLogWork={handleLogWork}
        reloadTaskInfo={reloadTaskInfo}
        onAddRevisionTask={onAddRevisionTask}
        colWidth={colWidth === index ? defaultBoxWidth : defaultBoxMinWidth}
        handleViewWidth={() => handleSetColWidth(index)}
        handleMinView={() => handleSetColWidth(-1)}
        handleCreatePhaseClick={handleCreatePhaseClick}
        handleCreateTaskClick={handleCreateTaskClick}
        handleCreateSubTaskClick={handleCreateSubTaskClick}
      />
    ))
  }

  return (
    <div
      style={{
        height: "98vh",
        backgroundImage: "linear-gradient(white, gray)",
      }}
      className={
        windowSize[0] >= minSizeWindowPC && isSideBarOpen
          ? " bg-[#f4f7fd] h-screen flex dark:bg-[#20212c] overflow-x-scroll g" +
          "ap-6 ml-[261px]"
          : "bg-[#f4f7fd] h-screen flex dark:bg-[#20212c] overflow-x-scroll ga" +
          "p-3 "
      }
    >
      {windowSize[0] >= minSizeWindowPC && (
        <>
          <PhaseInfo
            setIsBoardModalOpen={setIsBoardModalOpen}
            isBoardModalOpen={isBoardModalOpen}
            isSideBarOpen={isSideBarOpen}
            setIsSideBarOpen={setIsSideBarOpen}
            userRole={userRole}
            projects={projects}
            statuses={statuses}
            projectId={projectId}
            currentPhaseId={currentPhaseId}
            taskInfoId={taskInfoId}
            onSelectProject={onSelectProject}
            onSelectPhase={onSelectPhase}
            onSaveProject={onSaveProject}
            onSelectTask={onSelectTask}
            onShowCheckListOfPhase={onShowCheckListOfPhase}
            onShowAllCheckListOfPhase={onShowAllCheckListOfPhase}
            onSelectTodayTask={onSelectTodayTask}
            dataToday={dataToday}
          />
          <div className={isSideBarOpen ? `scrollbar-hide mx-8` : `scrollbar-hide mx-2`} />
        </>
      )}

      {renderColumnSections(columns)}
    </div>
  );
}

export default MainBoard;
