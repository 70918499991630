import { useEffect, useState } from "react";
import { configHeader, isTokenExpired } from "../../utils/tokenHelper";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { apiEndpoint } from "../../utils/apiEndpoint";
import { MDBAccordion, MDBAccordionItem, MDBBtn, MDBCheckbox, MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import { AgGridReact } from "ag-grid-react";
import { difference, first, orderBy } from "lodash";
import Multiselect from "multiselect-react-dropdown";
import UpdateDisplayName from "./UpdateDisplayName";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { onGridReady, userRoles } from "../../utils/utilities";
import ViewUserInfo from "../Users/ViewUserInfo";
import CtrPlanCompleted from "../Projects/CtrPlanCompleted";
import CheckListWithPhase from "../CheckList/CheckListWithPhase";

function ResourcePlan() {
  const navigate = useNavigate();
  const [users, setUsers] = useState();
  const [staffs, setStaffs] = useState();
  const [curStaffs, setCurStaffs] = useState();
  const [employees, setEmployees] = useState();
  const [projects, setProjects] = useState();
  const [phases, setPhases] = useState();
  const [phaseId, setPhaseId] = useState();
  const [projectId, setProjectId] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [userId, setUserId] = useState();
  const [displayName, setDisplayName] = useState();
  const [openChangeNameModal, setOpenChangeNameModal] = useState(false);
  const [userRole, setUserRole] = useState();
  const [userTeamId, setUserTeamId] = useState();
  const [checked, setChecked] = useState(false);
  const [checkedPhase, setCheckedPhase] = useState(true);
  const [allCheckList, setAllCheckList] = useState([]);

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
    document.title = "Manage resources";
    var info = window.localStorage.getItem("userInfo") ?? "";
    let infoObj = JSON.parse(info);
    setUserRole(infoObj.role);
    setUserTeamId(+infoObj.teamId ?? 0);
  }, []);

  useEffect(() => {
    loadDataStaffs();
    loadAdllProjects();
  }, []);

  const loadAdllProjects = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.projectUrl}`,
        configHeader()
      )
      .then((result) => {
        var items = (result?.data ?? []).filter((it) => it.isPM || userRole === userRoles.Admin);
        setProjects(orderBy(items ?? [], "priorityNumber"));
      });
  }

  useEffect(() => {
    reloadAllCheckList(phaseId)
  }, [phaseId]);

  const reloadAllCheckList = (phaseId) => {
    phaseId &&
      axios
        .get(
          `${apiEndpoint.hosting}/${apiEndpoint.phaseUrl}/${phaseId}/CheckLists`,
          configHeader()
        )
        .then((result) => {
          setAllCheckList(result?.data);
        });
  };

  const handleEditName = (id, name) => {
    setUserId(id);
    setDisplayName(name);
    setOpenChangeNameModal(true);
  };

  const updateDisplayName = () => {
    axios
      .put(
        `${apiEndpoint.hosting}/${apiEndpoint.userUrl}/Staffs/${userId}/UpdateName`,
        { name: displayName },
        configHeader()
      )
      .then(() => {
        loadDataStaffs();
        if ((phaseId ?? 0) > 0) reloadPhaseInfo(phaseId);
        setOpenChangeNameModal(false);
      });
  };

  const loadDataStaffs = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.userUrl}/All-Users`,
        configHeader()
      )
      .then((result) => {
        let items = filterStaffs(result?.data, []);
        setStaffs(items);
        setUsers(items)
      });
  };

  const colDefs = () => {
    if (userRole !== userRoles.Admin) {
      return [
        {
          headerName: "Row",
          valueGetter: "node.rowIndex + 1",
          width: 65,
        },
        {
          field: "displayName",
          pinned: 'left',
          lockPinned: true,
          width: 245,
          cellStyle: {
            textAlign: "left",
          },
          cellRenderer: (row) => <ViewUserInfo
            roleName={row.data.roleName}
            isActive={row.data.isActive}
            displayName={row.data.displayName} />
        },
        {
          field: "team",
          width: 235,
          cellStyle: {
            textAlign: "left",
          },
        }
      ];
    }

    return [
      {
        headerName: "Row",
        valueGetter: "node.rowIndex + 1",
        width: 65,
      },
      {
        field: "displayName",
        pinned: 'left',
        lockPinned: true,
        width: 195,
        cellStyle: {
          textAlign: "left",
        },
        cellRenderer: (row) => <ViewUserInfo
          roleName={row.data.roleName}
          isActive={row.data.isActive}
          displayName={row.data.displayName} />
      },
      {
        field: "team",
        width: 195,
        cellStyle: {
          textAlign: "left",
        },
      },
      {
        field: "roleName",
        headerName: "Role Name",
        filter: true,
        width: 195,
        cellStyle: {
          textAlign: "left",
        },
      },
      {
        field: "actions",
        headerName: "",
        width: 50,
        pinned: 'right',
        lockPinned: true,
        cellRenderer: (row) => {
          return (
            <MDBIcon
              fas
              icon="user-edit"
              onClick={() => handleEditName(row.data.id, row.data.displayName)}
            />
          );
        },
      },
    ];
  }

  const columDefs = [
    {
      headerName: "Row",
      valueGetter: "node.rowIndex + 1",
      width: 65,
    },
    {
      field: "displayName",
      pinned: 'left',
      lockPinned: true,
      filter: true,
      cellStyle: {
        textAlign: "left",
      },
      cellRenderer: (row) => <ViewUserInfo
        roleName={row.data.roleName}
        isActive={row.data.isActive}
        displayName={row.data.displayName} />
    },
    {
      field: "team",
      filter: true,
      width: 195,
      cellStyle: {
        textAlign: "left",
      },
    }, 
    {
      field: "actions",
      headerName: "",
      width: 50,
      pinned: 'right',
      lockPinned: true,
      cellRenderer: (row) => {
        return (
          <MDBIcon
            fas
            icon="times"
            color="warning"
            onClick={() => handleRemove(row.data.id)}
          />
        );
      },
    },
  ];

  const handleRemove = (id) => {
    let rs = (employees ?? []).filter((s) => s.id !== id);
    let items = filterStaffs(staffs, rs);
    setUsers(items);
    setEmployees(rs);
  };

  const onSelectionChanged = (event) => {
    const selectedData = event.api.getSelectedRows();
    setCurStaffs(selectedData);
  };

  const handleSelect = () => {
    let rs = (employees ?? []).concat(curStaffs ?? []);
    let items = filterStaffs(staffs, rs);
    setUsers(items);
    setEmployees(rs);
  };

  const filterStaffs = (items, others) => {
    let rs = (items ?? []).filter((item) => item.isActive);
    return difference(rs, others);
  };

  const options = (projects) => {
    return (projects ?? []).map((p) => {
      return { name: p.name, id: p.id };
    });
  };

  const optionPhases = (phases) => {
    return (phases ?? []).map((p) => {
      return { name: p.name, id: p.id };
    });
  };

  const onSelectedValues = (items) => {
    let ids = (items ?? []).map((it) => it.id);
    let rs = (projects ?? []).filter((p) => ids.some((id) => id === p.id));
    let pro = first(rs) ?? {};
    setPhases(pro?.phaseInfo);
    setPhaseId(0);
    setProjectId(pro?.id);
    if (!checkedPhase) {
      reloadProjectResources(pro?.id);
    }
  };

  useEffect(() => {
    !checkedPhase && reloadProjectResources(projectId);
  }, [checkedPhase, projectId]);

  const onSelectedPhaseValues = (items) => {
    let ids = (items ?? []).map((it) => it.id);
    let rs = (phases ?? []).filter((p) => ids.some((id) => id === p.id));
    let phase = first(rs ?? []) ?? {};
    setPhaseId(phase?.id);
  };

  useEffect(() => {
    reloadPhaseInfo(phaseId);
  }, [phaseId]);

  const reloadPhaseInfo = (phaseId) => {
    phaseId &&
      axios
        .get(
          `${apiEndpoint.hosting}/${apiEndpoint.phaseUrl}/${phaseId}`,
          configHeader()
        )
        .then((result) => {
          setEmployees([]);
          let items = result?.data?.staffs ?? [];
          loadDataInitByPhase(items);
        });
  };

  const reloadProjectResources = (projectId) => {
    projectId &&
      axios
        .get(
          `${apiEndpoint.hosting}/${apiEndpoint.projectUrl}/${projectId}/Project-Resources`,
          configHeader()
        )
        .then((result) => {
          setEmployees([]);
          let items = result?.data?.staffs ?? [];
          loadDataInitByPhase(items);
        });
  };

  const loadDataInitByPhase = (listStaffs) => {
    let ids = (listStaffs ?? []).map((it) => +it.id);
    let rs = (staffs ?? []).filter((p) => ids.some((id) => id === +p.id));
    let items = filterStaffs(staffs, rs);
    setUsers(items);
    setEmployees(rs);
  };

  const handleSavePhaseResource = (data) => {
    phaseId &&
      axios
        .put(
          `${apiEndpoint.hosting}/${apiEndpoint.phaseUrl}/${phaseId}/Resources`, data,
          configHeader()
        )
        .then(() => {
          reloadPhaseInfo(phaseId);
          toast.success("Assign resources to phase was successful");
        });
  }

  const handleSaveProjectResource = (data) => {
    projectId &&
      axios
        .put(
          `${apiEndpoint.hosting}/${apiEndpoint.projectUrl}/${projectId}/Resources`, data,
          configHeader()
        )
        .then(() => {
          toast.success("Assign resources to project was successful");
        });
  }

  const saveData = () => {
    let staffIds = (employees ?? []).map((item) => item.id);

    let data = checked ? {
      staffIds: staffIds,
      startDate: startDate,
      endDate: endDate,
    } : {
      staffIds: staffIds,
      startDate: startDate
    };

    if (checkedPhase) handleSavePhaseResource(data);
    else handleSaveProjectResource(data);
  };

  const renderGridByPhase = () => {
    if (checkedPhase && (phaseId ?? 0) < 1) return <></>

    if (!checkedPhase) return renderResource();

    return <>
      <MDBAccordion initialActive={1}>
        <MDBAccordionItem collapseId={1} headerTitle={<>
          <MDBIcon fas icon="users" style={{ marginRight: 15 }} />
          Resources</>}>
          {renderResource()}
        </MDBAccordionItem>
        <MDBAccordionItem collapseId={2} headerTitle={<>
          <MDBIcon fas icon="th-list" style={{ marginRight: 15 }} />
          Checklist
        </>}>
          <CheckListWithPhase
            phaseId={phaseId}
            allCheckList={allCheckList}
            myTeamId={userTeamId}
          />
        </MDBAccordionItem>
      </MDBAccordion>
    </>
  }

  const renderResource = () => {
    return <MDBRow>
      <MDBCol size="3" sm="6">
        <div className="ag-theme-quartz" style={{ height: "60vh" }}>
          <AgGridReact
            rowData={users ?? []}
            columnDefs={colDefs()}
            rowSelection={"multiple"}
            onSelectionChanged={onSelectionChanged}
            onGridReady={onGridReady}
          />
        </div>
        Selecting multiple rows can be achieved by holding down ^ Ctrl and
        mouse clicking the rows. A range of rows can be selected by using ⇧
        Shift.
      </MDBCol>
      <MDBCol size="3" sm="1">
        <MDBRow style={{ marginTop: 75 }}>
          {<MDBBtn
            type="submit"
            block
            className="mb-3"
            title="Select and assigned to phase"
            onClick={() => handleSelect()}
          >
            <MDBIcon fas icon="chevron-circle-right" />
          </MDBBtn>}
        </MDBRow>
      </MDBCol>

      <MDBCol size="3" sm="5">
        <div className="ag-theme-quartz" style={{ height: "45vh", marginBottom: 20 }}>
          {employees && (
            <AgGridReact rowData={employees} columnDefs={columDefs} />
          )}
        </div>

        <CtrPlanCompleted
          startDate={startDate}
          endDate={endDate}
          onSaveStartDate={(date) => setStartDate(date)}
          onSaveEndDate={(date) => setEndDate(date)}
          onSaveChecked={(isSelected) => setChecked(isSelected)}
        />

        <MDBBtn
          type="submit"
          block
          className="btn btn-outline-primary"
          style={{ marginTop: 15 }}
          disabled={checkedPhase && (phaseId ?? 0) < 1}
          onClick={() => saveData()}
        >
          <MDBIcon fas icon="save" /> Save resources to phase
        </MDBBtn>
      </MDBCol>
    </MDBRow>
  }

  const handleCheckedPhase = (val) => {
    setCheckedPhase(val);
  }

  const renderBody = () => {
    let titleHeader = !checkedPhase ? "Select project for setting project resources" :
      "Select project and choose your phase for setting resources and checklist";

    return <>
      <MDBRow style={{ marginBottom: 20 }}>
        <MDBCol size="3" sm="3">
          <MDBCheckbox
            id='controlledCheckbox'
            label='Setting default Project Resources'
            checked={!checkedPhase}
            onChange={() => handleCheckedPhase(!checkedPhase)}
          />
        </MDBCol>

        <MDBCol size="3" sm="9" style={{ textAlign: "center" }}>
          {titleHeader}
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size="3" sm="1">
          <MDBIcon fas icon="sync"
            color="info"
            style={{ margin: 16 }}
            onClick={() => loadAdllProjects()} />
        </MDBCol>
        <MDBCol size="3" sm="5">
          <Multiselect
            options={options(projects ?? [])}
            singleSelect
            onSelect={(items) => onSelectedValues(items)}
            onRemove={(items) => onSelectedValues(items)}
            displayValue="name"
          />
        </MDBCol>
        <MDBCol size="3" sm="6">
          {checkedPhase && <Multiselect
            options={optionPhases(phases ?? [])}
            singleSelect
            onSelect={(items) => onSelectedPhaseValues(items)}
            onRemove={(items) => onSelectedPhaseValues(items)}
            displayValue="name"
          />}

        </MDBCol>
      </MDBRow>

      <MDBRow style={{ marginTop: 20 }}>
        {(projects?.length < 1) && <h1>You don't have any projects for settings</h1>}
        {renderGridByPhase()}
      </MDBRow>
    </>
  }

  return (
    <>
      {openChangeNameModal && (
        <UpdateDisplayName
          setIsOpenModal={setOpenChangeNameModal}
          displayName={displayName}
          setDisplayName={setDisplayName}
          updateDisplayName={updateDisplayName}
        />
      )}

      {renderBody()}

      <ToastContainer />
    </>
  );
}

export default ResourcePlan;
