import { MDBIcon } from "mdb-react-ui-kit";

function DisplayUser({ userName }) {
  return (
    userName && (
      <>
        <MDBIcon fas icon="user-circle" style={{ marginRight: 15 }} />
        {userName}
      </>
    )
  );
}

export default DisplayUser;
