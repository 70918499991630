/* eslint-disable jsx-a11y/alt-text */
import { useEffect } from "react";
import logo from "./landing-page.jpg";
import { useNavigate } from "react-router-dom";
import { isTokenExpired } from "../../utils/tokenHelper";
import { MDBBtn, MDBCol, MDBRow } from "mdb-react-ui-kit";
function LandingPage() {
  const navigate = useNavigate();
  useEffect(() => {
    if (!isTokenExpired()) navigate(`/home`);
    document.title = "Company";
  }, []);
  return (
    <>
      <img src={logo} style={{ width: "100vw", height: "75vh" }} />
      <p style={{ textAlign: "justify" }}>
        Don't hesitate to immerse yourself in the vibrant atmosphere of Ho Chi
        Minh City on a full-day city tour. You will gain fascinating insight
        into rich culture and history with our knowledgeable guide when going to
        Reunification Palace, War Remnants Museum, Post Office, Notre-Dame
        Cathedral. Moreover, haggling for lovely gifts at Ben Thanh market and
        feasting your senses on a local lunch of traditional Vietnamese cuisine
        will remark your trip in Saigon.
      </p>
      <div className="text-muted text-center mt-2 mb-3">
        <p className=" text-lg font-bold ">
          <MDBRow className="justify-content-center">
            <MDBCol lg="6">
              <MDBBtn
                type="submit"
                block
                className="mb-2"
                onClick={() => navigate(`/login`)}
              >
                Sign in with your credentials
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </p>
      </div>
    </>
  );
}

export default LandingPage;
